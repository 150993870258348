




















































































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { CompanyEditDialogINputType } from "@/types";
import { Action, Getter } from "vuex-class";
import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";

@Component
export default class CompanyEditDialog extends Vue {
  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;
  @Getter("auth/dbUser") dbUser!: any;

  // props
  @Prop() companyInfo!: CompanyEditDialogINputType | null;
  @Prop() companies: any | null;
  @PropSync("isEdit", { type: Boolean }) isEditValue!: boolean;
  // computed
  get formTitle(): string {
    return !this.companyInfo ? "企業作成" : "企業編集";
  }
  get saveButtonText(): string {
    return !this.companyInfo ? "作成" : "保存";
  }
  get editedItem(): CompanyEditDialogINputType {
    return this.companyInfo ?? this.defaultItem;
  }
  //data
  taxCalcMathodList = [
    { value: 0, text: "四捨五入" },
    { value: 1, text: "切り上げ" },
    { value: 2, text: "切り捨て" },
  ];
  couponCalcMathodList = [
    { value: 0, text: "四捨五入" },
    { value: 1, text: "切り上げ" },
    { value: 2, text: "切り捨て" },
  ];
  defaultItem: CompanyEditDialogINputType = {
    Name: "",
    DisplayName: "",
    UseStatus: {
      TopUp: false,
      TicketKiosk: false,
    },
    GeneralConfigs: {
      TaxCalcMathod: 0,
    },
    SubSystemConfigs: {
      Common: {
        Shiagel: {
          Use: false,
          AuthCode: "",
        },
      },
      TicketKiosk: {
        Fingo: {
          Use: false,
        },
        Coupon: {
          CalcMethod: 0,
        },
      },
    },
  };
  rules = {
    NameRules: [
      (v: string) => !!v || "企業名は必須です",
      (v: string) => v.length <= 255 || "企業名は255文字以下で入力してください",
    ],
    DisplayNameRules: [
      (v: string) => !!v || "表示名は必須です",
      (v: string) => v.length <= 255 || "表示名は255文字以下で入力してください",
    ],
  };

  counter = {
    Name: 255,
    DisplayName: 255,
  };
  isValid = false;
  errorMessage: string | null = null;
  // method
  async save() {
    try {
      const checkDuplicateName = this.companies.filter(
        (e: any) =>
          e.Name === this.editedItem.Name && e.Id != this.editedItem.Id
      );
      if (checkDuplicateName.length > 0) {
        throw { code: "DUPLICATE_COMPANY_NAME" };
      }
      if ("Id" in this.editedItem) {
        // idを持っていれば更新
        await this.$store.dispatch("company/updateCompany", this.editedItem);
        await this.loggingOperationHistory({
          user: this.dbUser,
          viewName: "企業編集ダイアログ",
          viewId: "CompanyEditDialog",
          operationName: "企業編集",
          operationId: "companyEdit",
          detail: {
            companyName: this.editedItem.Name,
            companyId: this.editedItem.Id,
          },
        });
      } else {
        // 新規
        await this.$store.dispatch("company/createCompany", this.editedItem);
        await this.loggingOperationHistory({
          user: this.dbUser,
          viewName: "企業作成ダイアログ",
          viewId: "CompanyEditDialog",
          operationName: "企業作成",
          operationId: "companyCreate",
          detail: {
            companyName: this.editedItem.Name,
            companyId: this.editedItem.Id,
          },
        });
      }
      await this.$store.dispatch("company/fetchCompanies");
      this.close();
    } catch (e: any) {
      if (e.code == "DUPLICATE_COMPANY_NAME")
        this.errorMessage = "既に登録されています";
      else this.errorMessage = "登録時にエラーが発生しました";
    }
  }

  close() {
    this.isEditValue = false;
  }
}
