import { Acl } from '@/interface/rest/commAdmin/type';
import { getJwt } from '@/store/auth/auth';
import { CommAdmin } from '@/interface/rest/commAdmin/commAdmin'

export const acl = {
  namespaced: true,
  state: {
    acl: null as Acl | null
  },
  mutations: {
    setAcl(state: any, payload: Acl) {
      state.acl = payload
    }
  },
  actions: {
    async fetchAcl({commit, dispatch, rootGetters}: any){
      await dispatch("auth/authAction", null, { root: true });
      const user = rootGetters["auth/user"];
      try{
        const authKey = await getJwt();
        const api = new CommAdmin(authKey);
        const result = await api.getUserAcl(user.username);
        console.log(result);
        commit("setAcl", result)
      }catch(err){
        console.error(err);
      }
    }
  },
  getters: {
    acl: (state: any) => (!state.acl? null : state.acl)
  }
}