import { Acl } from './type';
import axios from 'axios';

const TAG = '[CommAdminApi]';

export class CommAdmin {
    private baseUrl: string;
    private authKey: string;

    constructor(authKey: string) {
        this.baseUrl = process.env.VUE_APP_API_ENDPOINT as string;
        this.authKey = authKey;
    }

    private createConfig() {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: this.authKey,
            },
        };
        return config;
    }

    private async getRequest(path: string) {
        const result = await axios.get(path, this.createConfig());
        if (result.data.message) {
            throw new Error(result.data.message)
        }
        return result.data;
    }

    public async getUserAcl(userId: string) {
      const path = `${this.baseUrl}/users/${userId}/acl`;
      const result: Acl = await this.getRequest(path)
      console.log(TAG , 'getUserAcl', result)
      return result;
    }
}
