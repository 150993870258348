/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:2b1d1388-aa17-4328-b204-852201159bcf",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_L8QqIAXY2",
    "aws_user_pools_web_client_id": "1bfu7lhtkeeo807k7kcjlkdr78",
    "oauth": {},
    "aws_content_delivery_bucket": "commadminweb-20210910133049-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d364sunlio41lx.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://hpdek7yqhvbxhphomvo3oqzqge.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
