




















import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

// TODO: どっかに移す
interface LinkIndexItem {
  title: string;
  description: string;
  url: string;
  acl: { // TODO: 後で制御入れる
    role: string;
    company: string;
  };
}
interface LinkIndex {
  index: LinkIndexItem[];
}

@Component
export default class Dashboard extends Vue{
  // data
  company = ''
  headers = [
    { text: '企業', value: 'company' },
    { text: 'チャージ機', value: 'chargeCount' },
    { text: '券売機', value: 'ticketCount' },
    { text: '総導入数', value: 'totalCount' },
  ]
  linkIndex: LinkIndexItem[] = [];
  // computed
  get customerData () {
    const companies = this.$store.getters['company/companies']
    const terminals = this.$store.getters['terminal/terminals']
    const data = companies.map((company: any) => {
      const topup = terminals.filter((e: any) => (e.CompanyId===company.Id && e.Kind==='TopUp'))
      const ticketKiosk = terminals.filter((e: any) => e.CompanyId===company.Id && e.Kind==='TicketKiosk')
      return {
        Id: company.Id,
        company: company.Name,
        chargeCount: topup.length,
        ticketCount: ticketKiosk.length,
        totalCount: topup.length + ticketKiosk.length
      }
    });
    return data
  }
  get topUpSum (): number {
    return this.customerData.reduce((acc: any, cur: any) => {
      return acc + cur.chargeCount
    }, 0)
  }
  get ticketKioskSum (): number {
    return this.customerData.reduce((acc: any, cur: any) => {
      return acc + cur.ticketCount
    }, 0)
  }
  get companyList (): any {
    return this.$store.getters['company/searchableCompanies'];
  }
  // method
  goToCompany (item: any) {
    this.$router.push({ path: '/company', query: { company: item.Id }})
  }
  companyFilter (value: any, search: string | null, item: any): boolean {
    return item.Id===search
  }
  async initialize () {
    this.$nextTick(async () => {
      // マニュアル等
      // TODO: どっかに移す
      const linkList = process.env.VUE_APP_DASHBOARD_LINK_LIST as string;
      const response = await axios.get(linkList);
      const list = response.data as LinkIndex;
      this.linkIndex = list.index;
      console.log(list);
    })
    await this.$store.dispatch('company/fetchCompanies');
    await this.$store.dispatch('terminal/fetchTerminals');
  }
  // mounted
  async mounted () {
    await this.initialize()
  }
}
