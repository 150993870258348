import { deleteUser } from "./deleteUser"
import { userService } from '../../user'


export const updateUserAndUserGroup = async(userId: string, newGroupId: string): Promise<void> => {
  console.log(`[updateUserAndUserGroup]Target userId: ${userId}, groupId: ${newGroupId}`)
  const targetGroupId = await userService.updateGroup(userId, newGroupId);
  console.log(`[updateUserAndUserGroup]oldGroupId: ${targetGroupId}`)
  if (!targetGroupId) return;
  await deleteUser(targetGroupId, userId);
}