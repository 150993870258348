



















































import { Component, Vue } from "vue-property-decorator";

@Component
export default class ForcePasswordChange extends Vue {
  isValid = false;
  errorMessage: string|null =null
  resetInput = {
    password: "",
    passwordConfirm: "",
    oldPassword: ""
  };
  rules = {
    oldPassword: (value: any) => value.length > 0 || "入力してください",
    password: (value: any) => {
      return value.length >= 8 || "8文字以上で入力してください";
    },
    differentPassword: (value: any) => value !== this.resetInput.oldPassword || "新しいパスワードを入力してください",
    passwordPolicy: {
      hasNumber: (value: any) =>
        /[0-9]+/.test(value) || "数字を1文字以上含めてください",
      hasUpper: (value: any) =>
        /[A-Z]+/.test(value) || "半角大文字を1文字以上含めてください",
      hasLower: (value: any) =>
        /[a-z]+/.test(value) || "半角小文字を1文字以上含めてください",
      hasSpecial: (value: any) =>
        /[=+\-^$*.\][}{)(?"!@#%&/\\,><':;|_~`]+/.test(value) ||
        "記号を1文字以上含めてください",
    },
    passwordConfirm: (value: any) =>
      (this.resetInput.password === value && value.length > 0) || "新しいパスワードと同じものを入力してください",
  };
  async submit() {
    try {
      const result = await this.$store.dispatch(
        "auth/forcePasswordChange",
        this.resetInput
      );
      this.$router.push("/");
    } catch (error) {
      this.errorMessage = 'パスワード変更ができませんでした'
    }
  }
}
