import {
  TerminalSummary
} from "@/API";
import {
  updateTerminalByIdAndKind,
  getTerminalByIdAndKind,
} from '@/interface/graphql/terminal/terminal'

// 該当TerminalSummaryと一致するTerminalのグループIDを更新し
export const updateGroup = async(terminal: TerminalSummary, newGroupId: string): Promise<string[] | null> => {
  const updateTarget = await getTerminalByIdAndKind({
    Id: terminal.Id,
    Kind: terminal.Kind,
  })
  if (!updateTarget) return null;
  console.log("getTerminal", updateTarget);
  // 更新前のグループIDを取得。
  if (newGroupId === updateTarget.GroupId)return null;
  const targetGroupIds = new Set([updateTarget.GroupId, updateTarget.AffiliatedGroups.Principal])
  if(updateTarget.AffiliatedGroups.Secondary) targetGroupIds.add(updateTarget.AffiliatedGroups.Secondary)
  // group kindごとに更新内容を設定。
  if (newGroupId.startsWith("Principal")){
    updateTarget.AffiliatedGroups.Principal = newGroupId;
    updateTarget.AffiliatedGroups.Secondary = null;
    const newShopId = newGroupId.split('&&')[2]
    if (updateTarget.ShopId !== newShopId){
      updateTarget.ShopId = newShopId
    }
  }else if(newGroupId.startsWith("Secondary")){
    updateTarget.AffiliatedGroups.Secondary = newGroupId;
  }else{
    throw Error(`[Invalid Group Id]: ${newGroupId}`)
  }
  // グループIDは&&で区切られており、２項目目がCompanyId、３項目目がShopIdになっている。
  updateTarget.GroupId = newGroupId;
  updateTarget.company = undefined;
  updateTarget.shop = undefined;
  updateTarget.relTerminalGroup = undefined;
  await updateTerminalByIdAndKind({
    input: updateTarget,
  });
  return [...targetGroupIds];
}