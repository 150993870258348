
import {
  getUserGroupById,
  updateUserGroupById,
} from "@/interface/graphql/group/userGroup"

export const addUser = async (groupId: string, userId: string): Promise<void> => {
  const targetGroup = await getUserGroupById({Id: groupId});
  if (!targetGroup) return;
  const users = targetGroup.Members?.Users ? [...targetGroup.Members.Users] : [];
  if(
    !targetGroup.Members?.Users ||
    !targetGroup.Members.Users.includes(userId)
  ){
    users.push(userId);
    await updateUserGroupById({
      input: {
        Id: targetGroup.Id,
        Members:{ 
          Users: users
        }
      }
    });
  }
}