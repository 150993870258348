











import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { ShopDialogInputType } from "@/types";
import { Action, Getter } from "vuex-class";
import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";

@Component
export default class ShopDeleteDialog extends Vue {
  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;
  @Getter("auth/dbUser") dbUser!: any;

  @Prop() shopInfo!: ShopDialogInputType;
  @PropSync("isDelete", { type: Boolean }) isDeleteValue!: boolean;
  // data
  errorMessage: string | null = null;
  // method
  async deleteShop() {
    try {
      await this.$store.dispatch("shop/deleteShop", this.shopInfo);
      await this.loggingOperationHistory({
        user: this.dbUser,
        viewName: "店舗削除ダイアログ",
        viewId: "ShopDeleteDialog",
        operationName: "店舗削除",
        operationId: "shopDelete",
        detail: {
          shopName: this.shopInfo.Name,
          shopId: this.shopInfo.Id,
        },
      });
      await this.$store.dispatch("shop/fetchShops");
      this.close();
    } catch (e) {
      this.errorMessage = "削除時にエラーが発生しました";
    }
  }

  close() {
    this.isDeleteValue = false;
  }
}
