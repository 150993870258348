import {
  TerminalSummary
} from "@/API";
import {
  getTerminalGroupById,
  updateTerminalGroupById
} from "@/interface/graphql/group/terminalGroup"

export const addTerminal = async (groupId: string, terminal: TerminalSummary): Promise<void> => {
  const targetGroup = await getTerminalGroupById({Id: groupId})
  if (!targetGroup) return;
  const terminals = targetGroup.Members?.Terminals ? [...targetGroup.Members.Terminals] : [];
  if(
    !targetGroup.Members?.Terminals || 
    targetGroup.Members.Terminals.filter(x =>x && (x.Id === terminal.Id && x.Kind === terminal.Kind)).length === 0
  ){
    terminals.push({
      Id: terminal.Id!,
      Kind: terminal.Kind!,
      __typename: terminal.__typename!,
    });
    await updateTerminalGroupById({
      input: {
        Id: targetGroup.Id,
        Members:{ 
          Terminals: terminals
        }
      }
    });
  }
}