import { API } from "aws-amplify";
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import { 
  getShopByDeleteStatus
} from '@/graphql/queries'
import {
  GetShopByDeleteStatusQuery,
  GetShopByDeleteStatusQueryVariables
} from '@/API'

type NullableShopByDeleteStatus = NonNullable<NonNullable<GetShopByDeleteStatusQuery["getShopByDeleteStatus"]>["items"]>
export type GetShopByDeleteStatus = NonNullable<NullableShopByDeleteStatus[number]>[]

export const getShopByIsDelete = async(variables: GetShopByDeleteStatusQueryVariables): Promise<GetShopByDeleteStatus> => {
  const queryOption = {
    query: getShopByDeleteStatus,
  } as GraphQLOptions
  if (variables) { queryOption.variables = variables }
  const graphqlFunc = async (option: GraphQLOptions) => {
    const response =  await API.graphql(option) as {data: GetShopByDeleteStatusQuery}
    return response.data.getShopByDeleteStatus
  }
  let queryResult = await graphqlFunc(queryOption)
  if (!queryResult) return []
  let result = queryResult.items? queryResult.items: []
  const nextVariables: GetShopByDeleteStatusQueryVariables = {...variables}
  while (queryResult.nextToken){
    nextVariables.nextToken = queryResult.nextToken
    queryResult = await graphqlFunc({query:getShopByDeleteStatus, variables: nextVariables})
    if (!queryResult) break
    result = result.concat(queryResult.items? queryResult.items: [])
  }
  return result.filter((v: NullableShopByDeleteStatus[number]) => v) as GetShopByDeleteStatus
}