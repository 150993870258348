var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "adopt" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "indigo darken-4", dark: "", flat: "" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "d-flex justify-space-between align-center"
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c(
                            "v-toolbar-title",
                            { staticClass: "text-h5 font-weight-bold" },
                            [_vm._v("導入実績")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-select", {
                            attrs: {
                              label: "企業",
                              items: _vm.companyList,
                              "item-text": "Name",
                              "item-value": "Id",
                              clearable: "",
                              "single-line": "",
                              outlined: "",
                              dense: "",
                              "solo-inverted": "",
                              flat: "",
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.company,
                              callback: function($$v) {
                                _vm.company = $$v
                              },
                              expression: "company"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.customerData,
                  search: _vm.company,
                  customFilter: _vm.companyFilter
                },
                on: { "click:row": _vm.goToCompany }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "mb-4",
                  attrs: { color: "deep-purple", dark: "", flat: "" }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "d-flex justify-space-between align-center"
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "9" } },
                        [
                          _c(
                            "v-toolbar-title",
                            { staticClass: "text-h5 font-weight-bold" },
                            [_vm._v("総導入数")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right text-h4 font-weight-bold",
                          attrs: { cols: "3" }
                        },
                        [_vm._v(_vm._s(_vm.topUpSum + _vm.ticketKioskSum))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-toolbar",
                {
                  staticClass: "mb-4",
                  attrs: { color: "indigo", dark: "", flat: "" }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "d-flex justify-space-between align-center"
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "9" } },
                        [
                          _c(
                            "v-toolbar-title",
                            { staticClass: "text-h5 font-weight-bold" },
                            [_vm._v("チャージ機導入数")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right text-h4 font-weight-bold",
                          attrs: { cols: "3" }
                        },
                        [_vm._v(_vm._s(_vm.topUpSum))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-toolbar",
                {
                  staticClass: "mb-4",
                  attrs: { color: "blue", dark: "", flat: "" }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "d-flex justify-space-between align-center"
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "9" } },
                        [
                          _c(
                            "v-toolbar-title",
                            { staticClass: "text-h5 font-weight-bold" },
                            [_vm._v("券売機導入数")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right text-h4 font-weight-bold",
                          attrs: { cols: "3" }
                        },
                        [_vm._v(_vm._s(_vm.ticketKioskSum))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }