
import {
  getUserGroupById,
  updateUserGroupById,
} from "@/interface/graphql/group/userGroup"

export const deleteTerminalGroup = async (groupId: string, terminalGroupId: string): Promise<void> => {
  const targetGroup = await getUserGroupById({Id: groupId});
  if (!targetGroup?.RelGroups) return;
  if(targetGroup.RelGroups.includes(terminalGroupId)){
    const terminalGroups = targetGroup.RelGroups.filter(
      (x) => {
        return x !== null && (x !== terminalGroupId);
      }
    );
    await updateUserGroupById({
      input: {
        Id: targetGroup.Id,
        RelGroups: terminalGroups,
        Members: targetGroup.Members,
      }
    });
  }
}