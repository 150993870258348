








































import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class Adopt extends Vue{
  // data
  company = ''
  headers = [
    { text: '企業', value: 'company' },
    { text: 'チャージ機', value: 'chargeCount' },
    { text: '券売機', value: 'ticketCount' },
    { text: '総導入数', value: 'totalCount' },
  ]
  // computed
  get customerData () {
    const companies = this.$store.getters['company/companies']
    const terminals = this.$store.getters['terminal/terminals']
    const data = companies.map((company: any) => {
      const topup = terminals.filter((e: any) => (e.CompanyId===company.Id && e.Kind==='TopUp'))
      const ticketKiosk = terminals.filter((e: any) => e.CompanyId===company.Id && e.Kind==='TicketKiosk')
      return {
        Id: company.Id,
        company: company.Name,
        chargeCount: topup.length,
        ticketCount: ticketKiosk.length,
        totalCount: topup.length + ticketKiosk.length
      }
    });
    return data
  }
  get topUpSum (): number {
    return this.customerData.reduce((acc: any, cur: any) => {
      return acc + cur.chargeCount
    }, 0)
  }
  get ticketKioskSum (): number {
    return this.customerData.reduce((acc: any, cur: any) => {
      return acc + cur.ticketCount
    }, 0)
  }
  get companyList (): any {
    return this.$store.getters['company/searchableCompanies'];
  }
  // method
  goToCompany (item: any) {
    this.$router.push({ path: '/company', query: { company: item.Id }})
  }
  companyFilter (value: any, search: string | null, item: any): boolean {
    return item.Id===search
  }
  async initialize () {
    await this.$store.dispatch('company/fetchCompanies');
    await this.$store.dispatch('terminal/fetchTerminals');
  }
  // mounted
  async mounted () {
    await this.initialize()
  }
}
