var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.isEditValue,
            callback: function($$v) {
              _vm.isEditValue = $$v
            },
            expression: "isEditValue"
          }
        },
        [
          _vm.isEditValue
            ? _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(_vm._s(_vm.formTitle))
                    ])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        {
                          model: {
                            value: _vm.isValid,
                            callback: function($$v) {
                              _vm.isValid = $$v
                            },
                            expression: "isValid"
                          }
                        },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }),
                                  _vm.errorMessage
                                    ? [
                                        _c(
                                          "p",
                                          { staticClass: "error--text" },
                                          [_vm._v(_vm._s(_vm.errorMessage))]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "企業名",
                                      required: "",
                                      counter: _vm.counter.Name,
                                      rules: _vm.rules.NameRules
                                    },
                                    model: {
                                      value: _vm.editedItem.Name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.editedItem, "Name", $$v)
                                      },
                                      expression: "editedItem.Name"
                                    }
                                  }),
                                  _c("v-col", { attrs: { cols: "12" } }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "表示名",
                                      required: "",
                                      counter: _vm.counter.DisplayName,
                                      rules: _vm.rules.DisplayNameRules
                                    },
                                    model: {
                                      value: _vm.editedItem.DisplayName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedItem,
                                          "DisplayName",
                                          $$v
                                        )
                                      },
                                      expression: "editedItem.DisplayName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("v-switch", {
                                        staticClass: "mt-0",
                                        attrs: { label: "チャージ機" },
                                        model: {
                                          value: _vm.editedItem.UseStatus.TopUp,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.editedItem.UseStatus,
                                              "TopUp",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editedItem.UseStatus.TopUp"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("v-switch", {
                                        staticClass: "mt-0",
                                        attrs: { label: "券売機" },
                                        model: {
                                          value:
                                            _vm.editedItem.UseStatus
                                              .TicketKiosk,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.editedItem.UseStatus,
                                              "TicketKiosk",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editedItem.UseStatus.TicketKiosk"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.taxCalcMathodList,
                                      "item-text": "text",
                                      "item-value": "value",
                                      label: "消費税計算方法"
                                    },
                                    model: {
                                      value:
                                        _vm.editedItem.GeneralConfigs
                                          .TaxCalcMathod,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedItem.GeneralConfigs,
                                          "TaxCalcMathod",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedItem.GeneralConfigs.TaxCalcMathod"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-0",
                                    attrs: { label: "SHIAGEL使用設定" },
                                    model: {
                                      value:
                                        _vm.editedItem.SubSystemConfigs.Common
                                          .Shiagel.Use,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedItem.SubSystemConfigs.Common
                                            .Shiagel,
                                          "Use",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedItem.SubSystemConfigs.Common.Shiagel.Use"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "SHIAGEL認証コード" },
                                    model: {
                                      value:
                                        _vm.editedItem.SubSystemConfigs.Common
                                          .Shiagel.AuthCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedItem.SubSystemConfigs.Common
                                            .Shiagel,
                                          "AuthCode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedItem.SubSystemConfigs.Common.Shiagel.AuthCode"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-0",
                                    attrs: { label: "Fingo使用設定" },
                                    model: {
                                      value:
                                        _vm.editedItem.SubSystemConfigs
                                          .TicketKiosk.Fingo.Use,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedItem.SubSystemConfigs
                                            .TicketKiosk.Fingo,
                                          "Use",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedItem.SubSystemConfigs.TicketKiosk.Fingo.Use"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.couponCalcMathodList,
                                      "item-text": "text",
                                      "item-value": "value",
                                      label: "クーポン計算方法"
                                    },
                                    model: {
                                      value:
                                        _vm.editedItem.SubSystemConfigs
                                          .TicketKiosk.Coupon.CalcMethod,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editedItem.SubSystemConfigs
                                            .TicketKiosk.Coupon,
                                          "CalcMethod",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editedItem.SubSystemConfigs.TicketKiosk.Coupon.CalcMethod"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "blue-grey lighten-5",
                            depressed: ""
                          },
                          on: { click: _vm.close }
                        },
                        [_vm._v("キャンセル")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            color: "light-blue darken-1",
                            depressed: "",
                            disabled: !_vm.isValid
                          },
                          on: { click: _vm.save }
                        },
                        [_vm._v(_vm._s(_vm.saveButtonText))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }