




































































































































import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";
import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component
export default class GroupUserCreateDialog extends Vue {
  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;
  @Getter("auth/dbUser") dbUser!: any;
  @Getter("user/usersForDialog") accountData!: any[];
  @Getter("shop/shopsForDialog") shopList!: any[];
  @Getter("group/terminalGroupsForDialog") terminalGroupData!: any[];

  @Prop() groupInfo!: any;
  @Prop() roleItems!: any;
  @Prop() companyList!: Record<string, unknown>[];
  @Prop() terminalData!: Record<string, unknown>[];
  @PropSync("isEdit", { type: Boolean }) isEditValue!: boolean;
  // data
  isValid = true;
  errorMessage: string | null = null;
  panel = null;
  defaultItem = {
    CompanyId: null,
    Name: "",
    Kind: "Secondary",
  };
  /** 編集していないかどうか */
  isInitial = {
    user: true,
    terminalGroup: true,
  };

  searchInput = {
    shop: null,
    role: "",
    mail: "",
    terminalGroupName: "",
  };

  /** 保存処理中 */
  isSaving = false;

  selectedAccount: any[] = [];
  selectedTerminalGroup: any[] = [];
  terminalGroupSearch = "";
  accountHeaders = [
    {
      text: "メールアドレス",
      value: "Email",
      sortable: false,
      filter: this.accountMailFilter,
    },
    {
      text: "ロール",
      value: "role",
      sortable: false,
      filter: this.accountRoleFilter,
    },
    {
      text: "店舗",
      value: "shop",
      sortable: false,
      filter: this.accountShopFilter,
    },
  ];
  terminalHeaders = [
    { text: "ターミナルグループ", value: "Name", sortable: false },
  ];
  rules = {
    NameRules: [
      (v: string) => !!v || "グループ名は必須です",
      (v: string) =>
        v.length <= 255 || "グループ名は255文字以下で入力してください",
    ],
    CompnayRules: [(v: string) => !!v || "企業は必須です"],
  };

  // computed
  get isNew(): boolean {
    return !this.groupInfo;
  }
  get formTitle(): string {
    return this.isNew ? "ユーザーグループ作成" : "ユーザーグループ編集";
  }
  get saveButtonText(): string {
    return this.isNew ? "作成" : "保存";
  }
  get editedItem(): any {
    return this.groupInfo ?? this.defaultItem;
  }

  // method
  changeCompany() {
    //   会社変更時にアカウントとターミナルグループの選択をリセット
    this.selectedAccount = [];
    this.selectedTerminalGroup = [];
    this.searchInput = {
      shop: null,
      role: "",
      mail: "",
      terminalGroupName: "",
    };
  }
  async changeTabs(number: number) {
    if (number === 1) {
      // ユーザータブ選択時
      await this.$store.dispatch("user/fetchUsersForDialog", {
        CompanyId: this.editedItem.CompanyId,
      });
      await this.$store.dispatch("shop/fetchShopsForDialog", {
        CompanyId: this.editedItem.CompanyId,
      });
      // 編集時にMembersのものをチェック済にする
      if (this.groupInfo && this.isInitial.user) {
        this.groupInfo.Members.Users.forEach((member: string) => {
          const selected = this.accountData.find((e: any) => e.Id === member);
          if (selected) this.selectedAccount.push(selected);
        });
        this.isInitial.user = false;
      }
    } else if (number === 2) {
      // ターミナルグループ選択時
      await this.$store.dispatch("group/fetchTerminalGroupsForDialog", {
        CompanyId: this.editedItem.CompanyId,
      });
      // 初回表示に設定済みをチェック済みにする
      if (this.groupInfo && this.isInitial.terminalGroup) {
        this.groupInfo.RelGroups.forEach((group: string) => {
          const selected = this.terminalGroupData.find(
            (e: any) => e.Id === group
          );
          if (selected) this.selectedTerminalGroup.push(selected);
        });
        this.isInitial.terminalGroup = false;
      }
    }
  }
  accountShopFilter(value: any, search: string | null, item: any): boolean {
    return !this.searchInput.shop
      ? true
      : item.ShopId === this.searchInput.shop; // return true
  }
  accountRoleFilter(value: any, search: string | null, item: any): boolean {
    return !this.searchInput.role
      ? true
      : item.CognitoGroup === this.searchInput.role; // return true
  }
  accountMailFilter(value: any, search: string | null, item: any): boolean {
    return value.match(this.searchInput.mail) !== null; // return true
  }
  async save(): Promise<void> {
    this.isSaving = true;
    try {
      if ("Id" in this.editedItem) {
        // アップデート
        // 追加分はそのまま更新
        // ユーザーの削除分: Userテーブルから削除
        await this.$store.dispatch("group/updateUserGroup", {
          groupInfo: this.editedItem,
          selectedAccount: this.selectedAccount.map((e: any) => e.Id),
          selectedTerminalGroup: this.selectedTerminalGroup.map((e) => e.Id),
          isInitial: this.isInitial,
        });
        // 操作履歴
        await this.loggingOperationHistory({
          user: this.dbUser,
          viewName: "ユーザーグループ編集ダイアログ",
          viewId: "GroupUserUpdate",
          operationName: "ユーザーグループ編集",
          operationId: "groupUserUpdate",
          detail: {
            groupName: this.editedItem.Name,
            groupId: this.editedItem.Id,
          },
        });
      } else {
        // 新規作成
        await this.$store.dispatch("group/createSecondaryUserGroup", {
          groupInfo: this.editedItem,
          selectedAccount: this.selectedAccount.map((e: any) => e.Id),
          selectedTerminalGroup: this.selectedTerminalGroup.map((e) => e.Id),
        });
        // 操作履歴
        await this.loggingOperationHistory({
          user: this.dbUser,
          viewName: "ユーザーグループ作成ダイアログ",
          viewId: "GroupUserCreate",
          operationName: "ユーザーグループ作成",
          operationId: "groupUserCreate",
          detail: {
            groupName: this.editedItem.Name,
            groupId: this.editedItem.Id,
          },
        });
      }
      await this.$store.dispatch("group/fetchUserGroups");
      this.isSaving = false;
      this.$emit("save");
      this.close();
    } catch (error) {
      console.log(error);
      this.isSaving = false;
    }
  }
  getRoleLabel(roleValue: string | null) {
    if (!roleValue) {
      return "";
    }
    const result = this.roleItems.filter(
      (role: any) => role.value === roleValue
    );
    return result[0].label;
  }
  close() {
    this.isEditValue = false;
  }
}
