
import {
  getUserGroupById,
  updateUserGroupById,
} from "@/interface/graphql/group/userGroup"

export const deleteUser = async (groupId: string, userId: string): Promise<void> => {
  const targetGroup = await getUserGroupById({Id: groupId});
  if (!targetGroup?.Members?.Users) return;
  if(targetGroup.Members.Users.includes(userId)){
    const users = targetGroup.Members.Users.filter((e) => {
      return e != null && (e !== userId);
    })
    await updateUserGroupById({
      input: {
        Id: targetGroup.Id,
        Members:{ 
          Users: users
        }
      }
    });
  }
}