




















































































































import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { AccountDialogInputType } from "@/types";
import { selectableRole } from "@/acl/Acl";
import { CognitoGroup } from "@/API";
import { Action, Getter } from "vuex-class";
import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";
@Component
export default class AccountEditDialog extends Vue {
  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;
  @Getter("auth/dbUser") dbUser!: any;

  @Prop() accountInfo!: AccountDialogInputType;
  @Prop() companyList!: Record<string, unknown>[];
  @Prop() shopList!: any;
  @Prop() roleItems!: Record<string, unknown>[];
  @Prop() currentRole!: string;
  @PropSync("isEdit", { type: Boolean }) isEditValue!: boolean;
  // data
  errorMessage: string | null = null;
  defaultItem: AccountDialogInputType = {
    Id: "",
    CompanyId: "",
    ShopId: null,
    Email: "",
    password: "",
    CognitoGroup: CognitoGroup.ShopUser,
    GroupId: "",
    MaintenanceCardId: "",
  };
  resetInput = {
    Email: "",
    password: "",
  };
  passwordShow = false;
  editedItem = this.accountInfo ?? this.defaultItem;
  hasShopIdError = false;
  isPasswordEditValue = false;
  shopIdErrorMessage: string | null = null;
  isValid = false;
  isPasswordValid = false;
  rules = {
    required: (value: any) => !!value || "必須項目です",
    email: (value: any) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "メールアドレスを入力してください";
    },
    emailLength: (value: any) => {
      return value.length <= 255 || "255文字以下で入力してください";
    },
    MaintenanceCardId: (value: any) => {
      return value.length == 14 || value.length == 0 || "14桁で入力してください";
    },
    password: (value: any) => {
      return value.length >= 8 || "8文字以上で入力してください";
    },
    passwordPolicy: {
      hasNumber: (value: any) =>
        /[0-9]+/.test(value) || "数字を1文字以上含めてください",
      hasUpper: (value: any) =>
        /[A-Z]+/.test(value) || "半角大文字を1文字以上含めてください",
      hasLower: (value: any) =>
        /[a-z]+/.test(value) || "半角小文字を1文字以上含めてください",
      hasSpecial: (value: any) =>
        /[=+\-^$*.\][}{)(?"!@#%&/\\,><':;|_~`]+/.test(value) ||
        "記号を1文字以上含めてください",
    },
    shopId: (value: any) => {
      const isShopRequired =
        ["ShopManager", "ShopUser"].indexOf(this.editedItem.CognitoGroup) > -1;
      return (isShopRequired && !value) || "店舗を選択してください";
    },
  };

  @Watch("isPasswordEditValue", { immediate: false, deep: true })
  onModalClosePasword(val: boolean, oldVal: boolean) {
    if (!val) {
      this.resetInput = {
        Email: "",
        password: "",
      };
      this.isPasswordEditValue = false;
      this.isPasswordValid = false;
      this.close();
    }
  }

  @Watch("isEditValue", { immediate: false, deep: true })
  onModalClose(val: boolean, oldVal: boolean): void {
    this.editedItem = this.accountInfo ?? {
      Id: "",
      CompanyId: "",
      ShopId: null,
      Email: "",
      password: "",
      CognitoGroup: CognitoGroup.ShopUser,
      GroupId: "",
      MaintenanceCardId: "",
    };
    if (!val) this.close();
  }

  // computed
  get formTitle(): string {
    return !this.accountInfo ? "アカウント作成" : "アカウント編集";
  }
  get saveButtonText(): string {
    return !this.accountInfo ? "作成" : "保存";
  }
  get filterdShopList() {
    return this.shopList.filter(
      (e: any) => e.CompanyId === this.editedItem.CompanyId
    );
  }
  get selectableRole() {
    return this.roleItems.filter((e: any) =>
      selectableRole(this.currentRole).includes(e.value)
    );
  }
  get recoveryTarget(): string {
    return this.editedItem.Email;
  }

  // method
  async save(): Promise<void> {
    try {
      if (this.editedItem.Id) {
        await this.$store.dispatch("user/updateUser", this.editedItem);
        await this.loggingOperationHistory({
          user: this.dbUser,
          viewName: "アカウント編集ダイアログ",
          viewId: "AccountCreateDialog",
          operationName: "アカウント編集",
          operationId: "accountUpdate",
          detail: {
            updatedUserId: this.editedItem.Email,
          },
        });
      } else {
        // 新規作成
        const saveData = {
          email: this.editedItem.Email,
          CompanyId: this.editedItem.CompanyId,
          ShopId: this.editedItem.ShopId,
          password: this.editedItem.password,
          CognitoGroup: this.editedItem.CognitoGroup,
          MaintenanceCardId: this.editedItem.MaintenanceCardId,
        };
        await this.$store.dispatch("user/createUser", saveData);
        await this.loggingOperationHistory({
          user: this.dbUser,
          viewName: "アカウント作成ダイアログ",
          viewId: "AccountCreateDialog",
          operationName: "アカウント作成",
          operationId: "accountCreate",
          detail: {
            createdUserId: this.editedItem.Email,
          },
        });
      }
      await this.$store.dispatch("user/fetchUsers");
      this.close();
    } catch (error: any) {
      console.log(JSON.stringify(error));
      if (error.code == "DUPLICATE_EMAIL_NAME")
        this.errorMessage =
          "すでに登録されているメールアドレスのため、登録ができません。";
      else
        // FIXME: トークン切れの場合を考慮する必要がある
        this.errorMessage =
          "入力されたメールアドレスはすでに登録されており、なおかつユーザが無効化されています。";
    }
  }
  openPassword(): void {
    this.resetInput.Email = this.editedItem.Email;
    this.isEditValue = false;
    this.isPasswordEditValue = true;
  }
  async savePassword(): Promise<void> {
    try {
      await this.$store.dispatch("auth/changePassword", this.resetInput);
    } catch (error: any) {
      if (error.code == "AUTH_ERROR")
        this.errorMessage = "Password is not valid";
      else this.errorMessage = "User is not authorized";
    }
  }

  close(): void {
    this.isEditValue = false;
    this.passwordShow = false;
    this.hasShopIdError = false;
    this.shopIdErrorMessage = null;
    this.isValid = false;
    this.errorMessage = null;
  }
  checkShopId(): void {
    const isShopRequired =
      ["ShopManager", "ShopUser"].indexOf(this.editedItem.CognitoGroup) > -1;
    this.hasShopIdError = isShopRequired && !this.editedItem.ShopId;
    if (this.hasShopIdError) {
      this.shopIdErrorMessage = "店舗を選択してください";
    } else {
      this.shopIdErrorMessage = null;
    }
  }
}
