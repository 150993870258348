import {
  TerminalSummary
} from "@/API";
import { deleteTerminal } from "./deleteTerminal"
import { terminalService } from '../../terminal'

// 該当TerminalSummaryと一致するTerminalのグループIDを更新し
export const updateTerminalAndTerminalGroup = async(terminal: TerminalSummary, newGroupId: string): Promise<void> => {
  const targetGroupIds = await terminalService.updateGroup(terminal, newGroupId);
  if (!targetGroupIds) return;
  // 変更前のグループから端末の削除
  for(const terminalGroupId of targetGroupIds){
    if(terminalGroupId === newGroupId)continue;
    await deleteTerminal(terminalGroupId, terminal)
  }
}