import { addTerminal } from './addTerminal'
import { deleteTerminal } from './deleteTerminal'
import { addUserGroup } from './addUserGroup'
import { deleteUserGroup } from './deleteUserGroup'
import { updateTerminalAndTerminalGroup } from './updateTerminalAndTerminalGroup'
import { deleteTerminalAndTerminalGroup } from './deleteTerminalAndTerminalGroup'
import { getPrincipalTerminalGroup } from './getPrincipalTerminalGroup'

export const terminalGroupService =  { 
  addTerminal,
  deleteTerminal,
  addUserGroup,
  deleteUserGroup,
  updateTerminalAndTerminalGroup,
  deleteTerminalAndTerminalGroup,
  getPrincipalTerminalGroup
}
