var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-card",
        {
          staticClass: "password-reset",
          attrs: { elevation: "2", outlined: "", tile: "" }
        },
        [
          _c("v-card-title", [_vm._v(" パスワードリセット")]),
          _c(
            "v-card-text",
            [
              _c("p", { staticClass: "send-verification-code" }, [
                _vm._v(
                  _vm._s(_vm.recoveryTarget) + "に確認コードを送信しました。"
                ),
                _c("br"),
                _vm._v("確認コードと新しいパスワードを入力してください。")
              ]),
              _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit($event)
                    }
                  }
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "10", sm: "10" } },
                            [
                              _c(
                                "v-text-field",
                                {
                                  attrs: {
                                    placeholder: "確認コード",
                                    outlined: ""
                                  },
                                  model: {
                                    value: _vm.resetInput.verificationCode,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.resetInput,
                                        "verificationCode",
                                        $$v
                                      )
                                    },
                                    expression: "resetInput.verificationCode"
                                  }
                                },
                                [_c("p", { staticClass: "caption mb-0" })]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "10", sm: "10" } },
                            [
                              _c(
                                "v-text-field",
                                {
                                  attrs: {
                                    placeholder: "パスワード",
                                    outlined: ""
                                  },
                                  model: {
                                    value: _vm.resetInput.password,
                                    callback: function($$v) {
                                      _vm.$set(_vm.resetInput, "password", $$v)
                                    },
                                    expression: "resetInput.password"
                                  }
                                },
                                [_c("p", { staticClass: "caption mb-0" })]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "10", sm: "10" } },
                            [
                              _c(
                                "v-text-field",
                                {
                                  attrs: {
                                    placeholder: "パスワード(再入力)",
                                    outlined: ""
                                  },
                                  model: {
                                    value: _vm.resetInput.passwordConfirm,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.resetInput,
                                        "passwordConfirm",
                                        $$v
                                      )
                                    },
                                    expression: "resetInput.passwordConfirm"
                                  }
                                },
                                [_c("p", { staticClass: "caption mb-0" })]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "10", sm: "10" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-4 blue white--text",
                                  attrs: { type: "submit", block: "" }
                                },
                                [_vm._v("パスワードを再設定")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }