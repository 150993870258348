






































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from 'vuex-class'
import { can } from "@/acl/Acl";
import GroupDeviceCreateDialog from "@/components/group/GroupDeviceCreateDialog.vue";

const Auth = namespace('auth');
const Company = namespace('company');
const Shop = namespace('shop');
const User = namespace('user');
const Group = namespace('group');

@Component({
  components: {
    GroupDeviceCreateDialog,
  },
})
export default class GroupDevice extends Vue {

  @Auth.Getter('userGroup') currentRole!: any[];
  @Company.Getter('searchableCompanies') companyList!: any[];
  @Company.Action("fetchCompanies") fetchCompanies!: () => Promise<void>;
  @Shop.Getter('searchableShops') shopList!: any[];
  @Shop.Action("fetchShops") fetchShops!: () => Promise<void>;
  // @User.Getter('searchableUsers') accountData!: any[];
  @User.Action("fetchUsers") fetchUsers!: () => Promise<void>;
  @Group.Getter('userGroups') userGroups!: any[];
  @Group.Getter('terminalGroups') terminalGroups!: any[];
  @Group.Action("fetchUserGroups") fetchUserGroups!: () => Promise<void>;
  @Group.Action("fetchTerminalGroups") fetchTerminalGroups!: () => Promise<void>;

  // -------------------------

  // data
  terminalSearch = "";
  userGroupSearch = "";
  groupHeaders = [
    {
      text: "ターミナルグループ",
      value: "Name",
      sortable: false,
      filter: this.groupNameFilter,
    },
    {
      text: "企業",
      value: "company.Name",
      sortable: false,
      filter: this.groupCompanyFilter,
    },
  ];
  terminalHeaders = [
    { text: "ID", value: "Id", sortable: false },
    { text: "表示名", value: "DisplayName", sortable: false },
    { text: "種別", value: "type", sortable: false },
    { text: "店舗", value: "store", sortable: false },
  ];
  accountHeaders = [
    { text: "ユーザーグループ", value: "Name", sortable: false },
  ];
  groupData = [];
  currentGroup: any | null = null;
  search = "";
  searchId = this.$route.query.group ?? null;
  company = this.$route.query.company ? this.$route.query.company : "";
  groupCreate = false;
  groupEdit = false;
  groupDelete = false;
  groupDetail = false;
  panel = null;
  createGroup = {};
  isEdit = false;
  editItem: any = null;
  searchCompany = "";
  defaultItem = {
    name: "",
    Id: "",
    groupList: [],
  };
  companyItems = [];
  terminalItems = ["チャージ機", "券売機"];

  loading = false;

  /** ロード中メッセージ(date table表示用) */
  protected messages = {
      loading: '読み込み中...',
      noData: 'データ無し'
  };

  // -------------------------

  // get accountData() {
  //   return this.$store.getters["group/searchableUserGroups"];
  // }
  get canCreate(): boolean {
    return can(this.currentRole, "create", "group");
  }
  get canDelete(): boolean {
    return can(this.currentRole, "delete", "group");
  }
  get canEdit(): boolean {
    return can(this.currentRole, "edit", "group");
  }

  get isLoading(): boolean {
    // TODO: マスタデータの読込待ち
    return this.loading;
  }


  // -------------------------

  created() {
    this.initialize();
  }

  async initialize() {
    this.loading = true;
    await this.fetchTerminalGroups();
    await this.fetchCompanies();
    await this.fetchShops();
    this.loading = false;
  }

  groupRowClasses(item: any) {
    if (this.currentGroup && item.Id === this.currentGroup.Id) {
      return "active";
    }
  }

  openEdit(item: any) {
    if (item) {
      this.editItem = Object.assign({}, item);
    } else {
      this.editItem = null;
    }
    this.isEdit = true;
  }

  openDetail(item: any) {
    this.currentGroup = item;
  }

  groupNameFilter(value: any, search: any, item: any) {
    if (this.search.length > 0) {
      return item.Name.match(this.search) !== null;
    } else if (this.searchId) {
      return item.Id === this.searchId;
    } else {
      return true;
    }
  }

  groupCompanyFilter(value: any, search: any, item: any) {
    if (this.searchCompany) {
      return item.CompanyId === this.searchCompany;
    } else if (this.searchId) {
      return item.Id === this.searchId;
    }
    return true;
  }
  kindToString(value: string) {
    if (value === "TopUp") return "チャージ機";
    if (value === "TicketKiosk") return "券売機";
  }

  afterSave() {
    if (this.currentGroup){
      const id = this.currentGroup.Id;
      this.currentGroup = this.terminalGroups.find((e: any) => e.Id === id);
    }
  }
}
