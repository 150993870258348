import { deleteUser } from './deleteUser'
import { addUser } from './addUser'
import { addTerminalGroup } from './addTerminalGroup'
import { deleteTerminalGroup } from './deleteTerminalGroup'
import { getPrincipalUserGroup } from './getPrincipalUserGroup'
import { updateUserAndUserGroup } from './updateUserAndUserGroup'
import { deleteUserAndUserGroup } from './deleteUserAndUserGroup'

export const userGroupService = { 
  deleteUser, 
  addUser, 
  deleteTerminalGroup, 
  addTerminalGroup,
  getPrincipalUserGroup,
  updateUserAndUserGroup,
  deleteUserAndUserGroup
}