











import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";
import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { namespace, Action, Getter } from "vuex-class";
// import { ShopDialogInputType } from '@/types';

const User = namespace('user');

@Component
export default class AccountDeleteDialog extends Vue {

  @Prop() accountInfo!: any;
  @PropSync("isDelete", { type: Boolean }) isDeleteValue!: boolean;

  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;
  @Getter("auth/dbUser") dbUser!: any;
  @User.Action("fetchUsers") fetchUsers!: () => Promise<void>;
  @User.Action("fetchUsersForDialog") fetchUsersForDialog!: (payload: any) => Promise<void>;
  @User.Action("deleteUser") deleteUser!: (payload: any) => Promise<void>;

  // data
  errorMessage: string | null = null;
  // method
  async deleteAccount(): Promise<void> {
    try {
      const deleteData = {
        Id: this.accountInfo.Id,
        GroupId: this.accountInfo.GroupId,
      };
      await this.deleteUser(deleteData);
      await this.loggingOperationHistory({
        user: this.dbUser,
        viewName: "アカウント削除ダイアログ",
        viewId: "AccountDeleteDialog",
        operationName: "アカウント削除",
        operationId: "accountDelete",
        detail: {
          deletedUserId: this.accountInfo.Email,
        },
      });
      await this.fetchUsers();
      await this.fetchUsersForDialog({ force: true }); // NOTE: 暫定対応
      this.close();
    } catch (e) {
      this.errorMessage = "削除時にエラーが発生しました";
    }
  }

  close() {
    this.isDeleteValue = false;
  }
}
