var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDetailValue && _vm.companyInfo
    ? _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.isDetailValue,
            callback: function($$v) {
              _vm.isDetailValue = $$v
            },
            expression: "isDetailValue"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [_vm._v("企業詳細")])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c("v-simple-table", [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [_vm._v("企業名")]),
                            _c("td", [_vm._v(_vm._s(_vm.companyInfo.Name))])
                          ]),
                          _c("tr", [
                            _c("td", [_vm._v("表示名")]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.companyInfo.DisplayName))
                            ])
                          ]),
                          _c(
                            "tr",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    path: "/store",
                                    query: { company: _vm.companyInfo.Id }
                                  })
                                }
                              }
                            },
                            [
                              _c("td", [_vm._v("店舗リスト")]),
                              _c(
                                "td",
                                { staticClass: "text-right pr-0" },
                                [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                                1
                              )
                            ]
                          ),
                          _c(
                            "tr",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    path: "/account",
                                    query: { company: _vm.companyInfo.Id }
                                  })
                                }
                              }
                            },
                            [
                              _c("td", [_vm._v("アカウントリスト")]),
                              _c(
                                "td",
                                { staticClass: "text-right pr-0" },
                                [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                                1
                              )
                            ]
                          ),
                          _vm.companyInfo.charge
                            ? _c(
                                "tr",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push({
                                        path: "/terminal/charge",
                                        query: {
                                          company: _vm.editedItem.company
                                        }
                                      })
                                    }
                                  }
                                },
                                [
                                  _c("td", [_vm._v("チャージ機リスト")]),
                                  _c(
                                    "td",
                                    { staticClass: "text-right pr-0" },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-right")
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.companyInfo.ticketVending
                            ? _c(
                                "tr",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push({
                                        path: "/terminal/ticket-vending",
                                        query: {
                                          company: _vm.editedItem.company
                                        }
                                      })
                                    }
                                  }
                                },
                                [
                                  _c("td", [_vm._v("券売機リスト")]),
                                  _c(
                                    "td",
                                    { staticClass: "text-right pr-0" },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-right")
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c("tr", [
                            _c("td", [_vm._v("消費税計算方法")]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.taxCalcMathodText(
                                    _vm.companyInfo.GeneralConfigs.TaxCalcMathod
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [_vm._v("SHIAGEL使用有無")]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.companyInfo.SubSystemConfigs.Common
                                    .Shiagel.Use
                                    ? "有"
                                    : "無"
                                )
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [_vm._v("SHIAGEL 認証コード")]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.companyInfo.SubSystemConfigs.Common
                                    .Shiagel.AuthCode
                                )
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [_vm._v("Fingo使用有無")]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.companyInfo.SubSystemConfigs.TicketKiosk
                                    .Fingo.Use
                                    ? "有"
                                    : "無"
                                )
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [_vm._v("クーポン計算方法")]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.couponCalcMathodText(
                                    _vm.companyInfo.SubSystemConfigs.TicketKiosk
                                      .Coupon.CalcMethod
                                  )
                                )
                              )
                            ])
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.companyInfo.isDelete === "true"
                ? _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "red darken-2",
                            dark: "",
                            depressed: ""
                          },
                          on: { click: _vm.cancelDeleteCompany }
                        },
                        [_vm._v("戻す")]
                      ),
                      _c("v-spacer")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }