import {
  TerminalSummary
} from "@/API";
import {
  updateTerminalByIdAndKind,
  getTerminalByIdAndKind,
} from '@/interface/graphql/terminal/terminal'
import { terminalGroupService } from '@/service/group/terminalGroup';

interface DeleteGroupResult{
  newGroupId: string;
  oldGroupIds: string[];
}
// 該当TerminalSummaryと一致するTerminalのグループIDを更新し
export const deleteGroup = async(terminalSummary: TerminalSummary): Promise<DeleteGroupResult | null> => {
  const terminal = await getTerminalByIdAndKind({
    Id: terminalSummary.Id,
    Kind: terminalSummary.Kind,
  })
  if (!terminal) return null;
  console.log("getTerminal", terminal);
  // 更新前のグループIDを取得。
  const targetGroupIds = new Set([terminal.GroupId, terminal.AffiliatedGroups.Principal])
  if(terminal.AffiliatedGroups.Secondary){
    targetGroupIds.add(terminal.AffiliatedGroups.Secondary)
    terminal.AffiliatedGroups.Secondary = null;
    terminal.GroupId = terminal.AffiliatedGroups.Principal;
  }else{
      const principalCompanyGroup = await terminalGroupService.getPrincipalTerminalGroup(terminal.CompanyId, "_");
      terminal.AffiliatedGroups.Principal = principalCompanyGroup.Id;
      terminal.GroupId = principalCompanyGroup.Id;
      terminal.ShopId = "_"
  } 
  terminal.company = undefined;
  terminal.shop = undefined;
  terminal.relTerminalGroup = undefined;
  await updateTerminalByIdAndKind({
    input: terminal,
  });
  return {newGroupId: terminal.GroupId, oldGroupIds: [...targetGroupIds]};
}