var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "offset-md-1" },
            [
              _c(
                "v-card",
                { staticClass: "information" },
                [
                  _c("v-card-title", [_vm._v("お知らせ")]),
                  _c("v-card-text", [_vm._v("お知らせ")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { staticClass: "information" },
                [
                  _c("v-card-title", [_vm._v("ドキュメント")]),
                  _vm._l(_vm.linkIndex, function(item) {
                    return _c(
                      "v-list-item",
                      { attrs: { "two-line": "" } },
                      [
                        _c(
                          "v-list-item-icon",
                          [_c("v-icon", [_vm._v("mdi-file-document")])],
                          1
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _c(
                                "a",
                                { attrs: { href: item.url, target: "_blank" } },
                                [_vm._v(_vm._s(item.title))]
                              )
                            ]),
                            _c("v-list-item-subtitle", [
                              _vm._v(_vm._s(item.description))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }