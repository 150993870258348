import { API } from "aws-amplify";
import { nanoid } from "nanoid";
import moment from "moment";
import {
  createUserGroup,
  createTerminalGroup,
} from "@/graphql/mutations";
import {
  CreateTerminalGroupInput,
  CreateUserGroupInput,
  ListUserGroupsQueryVariables,
  ListTerminalGroupsQueryVariables,
  TerminalSummary,
  UpdateTerminalGroupInput
} from "@/API";
import { ROLE } from "@/acl/Acl";
import {
  getAllUserGroups,
  getUserGroupById,
  ListUserGroups,
  updateUserGroupById,
} from "@/interface/graphql/group/userGroup"
import {
  getAllTerminalGroups, 
  getTerminalGroupById,
  ListTerminalGroups,
  updateTerminalGroupById,
} from "@/interface/graphql/group/terminalGroup"
import { terminalGroupService } from '@/service/group/terminalGroup';
import { userGroupService } from '@/service/group/userGroup';
import {
  CreatePrincipalUserGroupPayload,
  CreatePrincipalTerminalGroupPayload,
  CreateSecondaryTerminalGroupPayload,
  UpdateTerminalGroupPayload,
  UpdateUserGroupPayload,
} from "@/types";
import { GroupState, GroupAction} from './type'

export const group = {
  namespaced: true,
  state: {
    userGroups: [],
    terminalGroups: [],
    // dialog用
    dialog: {
      targetUserGroup: [],
      targetTerminalGroup: [],
    },
  },
  mutations: {
    setUserGroups(state: GroupState, payload: ListUserGroups): void {
      state.userGroups = payload;
    },
    setTerminalGroups(state: GroupState, payload: ListTerminalGroups): void {
      state.terminalGroups = payload;
    },
    setUserGroupsForDialog(state: GroupState, payload: ListUserGroups): void {
      state.dialog.targetUserGroup = payload;
    },
    setTerminalGroupsForDialog(state: GroupState, payload: ListTerminalGroups): void {
      state.dialog.targetTerminalGroup = payload;
    },
  },
  actions: {
    async fetchUserGroups({ commit, dispatch, rootGetters }: GroupAction): Promise<void> {
      let variables: ListUserGroupsQueryVariables | null= null
      // Dynamoから取得
      await dispatch("auth/authAction", null, { root: true });
      const role = rootGetters["auth/userGroup"];
      const groupId: string = rootGetters["auth/dbUser"].GroupId;
      if (groupId.startsWith("Secondary")){
        const userGroup = [await getUserGroupById({Id: groupId})]
        console.log("[Secondly UserGroup]: ", userGroup);
        commit("setUserGroups",  userGroup ? userGroup : []);
        return;
      }
      try {
        if (ROLE.CorpAdmin === role || ROLE.CorpSuperVisor === role) {
          variables = {
            filter: {
              CompanyId: {
                eq: rootGetters["auth/companyId"],
              },
            },
          }
        } else if (ROLE.ShopManager === role || ROLE.ShopUser === role){
          variables = {
            filter: {
              CompanyId: {
                eq: rootGetters["auth/companyId"],
              },
              ShopId: {
                eq: rootGetters["auth/shopId"],
              },
            },
          }
        }
        const groups = await getAllUserGroups(variables);
        commit("setUserGroups", groups);
      } catch (error) {
        console.log("error", error);
      }
    },
    async fetchTerminalGroups({ commit, dispatch, rootGetters }: GroupAction): Promise<void> {
      // Dynamoから取得
      await dispatch("auth/authAction", null, { root: true });
      const role = rootGetters["auth/userGroup"];
      const groupId: string = rootGetters["auth/dbUser"].GroupId;
      if (groupId.startsWith("Secondary")){
        const userGroup = await getUserGroupById({Id: groupId})
        if (!userGroup?.RelGroups)return;
        const terminalGroups = []
        for (const terminalGroupId of userGroup.RelGroups){
          if (!terminalGroupId)continue;
          const terminalGroup = await getTerminalGroupById({Id: terminalGroupId})
          if (!terminalGroup)continue;
          terminalGroups.push(terminalGroup)
        }
        console.log("[Secondly TerminalGroup]:", terminalGroups);
        commit("setTerminalGroups",  terminalGroups);
        return;
      }
      let variables: ListTerminalGroupsQueryVariables | null = null;
      try {
        if (ROLE.CorpAdmin === role || ROLE.CorpSuperVisor === role) {
          variables = {
            filter: {
              CompanyId: {
                eq: rootGetters["auth/companyId"],
              },
            },
          }
        } else if (ROLE.ShopManager === role || ROLE.ShopUser === role){
          variables = {
            filter: {
              CompanyId: {
                eq: rootGetters["auth/companyId"],
              },
              ShopId: {
                eq: rootGetters["auth/shopId"],
              },
            },
          }
        }
        const groups = await getAllTerminalGroups(variables)
        commit("setTerminalGroups", groups);
      } catch (error) {
        console.log("error", error);
      }
    },
    async createPrincipalUserGroup(
      _: GroupAction,
      payload: CreatePrincipalUserGroupPayload
    ): Promise<void> {
      // Dynamoへ保存(新規作成)
      try {
        let newGroupId;
        if (!payload.Id) {
          const groupId = nanoid(7);
          const groupKind = "Principal";
          newGroupId = `${groupKind}&&${payload.CompanyId}&&${payload.ShopId ??
            "_"}&&${groupId}`;
        } else {
          newGroupId = payload.Id;
        }
        console.log(payload);
        const group: CreateUserGroupInput = {
          Id: newGroupId,
          CompanyId: payload.CompanyId,
          ShopId: payload.ShopId,
          Name: payload.Name,
          Members: {
            Users: [],
          },
          RelGroups: payload.RelGroups,
          CreatedAt: moment()
            .utc()
            .unix(),
          UpdatedAt: moment()
            .utc()
            .unix(),
        };
        const result: any = await API.graphql({
          query: createUserGroup,
          variables: { input: group },
        });
        console.log(`[CreateUserGroup] ${result}`)
      } catch (error) {
        console.log("error", error);
      }
    },
    async createSecondaryUserGroup( _: GroupAction, payload: any): Promise<void> {
      console.info("create secondary", payload);
      try {
        const groupId = nanoid(7);
        const newGroupId = `${payload.groupInfo.Kind}&&${payload.groupInfo.CompanyId
          }&&${payload.groupInfo.ShopId ?? "_"}&&${groupId}`;
        const group = {
          Id: newGroupId,
          CompanyId: payload.groupInfo.CompanyId,
          ShopId: payload.groupInfo.ShopId,
          Name: payload.groupInfo.Name,
          Members: { Users: payload.selectedAccount },
          RelGroups: payload.selectedTerminalGroup,
          CreatedAt: moment()
            .utc()
            .unix(),
          UpdatedAt: moment()
            .utc()
            .unix(),
        };
        const result: any = await API.graphql({
          query: createUserGroup,
          variables: { input: group },
        });
        console.log('[createSecondaryUserGroup]', result);
        // userのgroupを更新
        for(const account of payload.selectedAccount){
          await userGroupService.updateUserAndUserGroup(account, newGroupId)
        }
        // ターミナルグループを更新
        for(const groupId of payload.selectedTerminalGroup){
          await terminalGroupService.addUserGroup(groupId, newGroupId)
        }
      } catch (error) {
        console.log("error", error);
      }
    },

    /** ユーザグループを更新する */
    async updateUserGroup(_: GroupAction, payload: UpdateUserGroupPayload): Promise<void> {
      // payload.isInitial.terminalGroup: ターミナルグループを変更していないかどうか(true: 変更していない, false: 変更した)
      // payload.isInitial.user: ユーザの紐づけを変更していないかどうか(true: 変更していない, false: 変更した)
      console.log("updateUserGroup", payload);
      try {
        // グループの更新
        const group = Object.assign({}, payload.groupInfo);
        group.UpdatedAt = moment().utc().unix() // 更新日時は必ず最新化する
        // ダイアログのユーザー選択画面表示が行われていなければ変更しない
        const users = (
          payload.isInitial.user
            ? (group.Members.Users as string[]) ?? []
            : payload.selectedAccount
        ).filter(e => e && e.length > 0) // 念のためフィルタする
        // ダイアログのターミナルグループ選択画面表示が行われていなければ変更しない
        const terminalGroups = (
          payload.isInitial.terminalGroup
            ? (group.RelGroups as string[]) ?? [] // 元データ
            : payload.selectedTerminalGroup // 編集済み
        ).filter(e => e && e.length > 0) // 念のためフィルタする
        await updateUserGroupById({
          input: {
            Id: group.Id as string,
            Name: group.Name as string,
            CompanyId: group.CompanyId as string,
            ShopId: group.ShopId as string,
            Members: {
              Users: users
            },
            RelGroups: terminalGroups,
            UpdatedAt: group.UpdatedAt,
          },
        });
        const groupId = group.Id as string;
        // ユーザーの紐づけを変更した場合の更新処理
        if (!payload.isInitial.user) {
          const initialUsers = (group.Members.Users as string[]) ?? [];
          const editedUsers = payload.selectedAccount;

          const addedUsers: string[] = editedUsers
            .filter(e => e && e.length > 0) // 念のためフィルタする
            .filter(e => !initialUsers.includes(e)); // 既存に含まれない = 追加されたユーザ
          const removedUsers: string[] = initialUsers
            .filter(e => e && e.length > 0) // 念のためフィルタする
            .filter(e => !editedUsers.includes(e)); // 編集後に含まれない = 削除されたユーザ
          
          console.log('updateUserGroup', 'updateUsers', addedUsers, removedUsers);

          // 追加されたユーザーの更新: グループ紐づけをユーザに追加
          for (const user of addedUsers) {
            await userGroupService.updateUserAndUserGroup(user, groupId);
          }
          // 削除されたユーザの更新: 所属する企業/店舗のPrincipalに所属させる。
          for (const user of removedUsers){
            await userGroupService.deleteUserAndUserGroup(user);
          }
        }
        // ターミナルグループの紐づけを変更した場合の処理
        if (!payload.isInitial.terminalGroup) { 
          const initialTerminalGroups = (group.RelGroups as string[]) ?? [];
          const editedTerminalGroups = payload.selectedTerminalGroup;

          const addedTerminalGroups: string[] = editedTerminalGroups
            .filter(e => e && e.length > 0) // 念のためフィルタする
            .filter(e => !initialTerminalGroups.includes(e)); // 既存に含まれない = 追加されたユーザ
          const removedTerminalGroups: string[] = initialTerminalGroups
            .filter(e => e && e.length > 0) // 念のためフィルタする
            .filter(e => !editedTerminalGroups.includes(e)); // 編集後に含まれない = 削除されたユーザ
          
          console.log('updateUserGroup', 'updateTerminalGroups', addedTerminalGroups, removedTerminalGroups);
          
          // 追加されたターミナルグループの更新
          for (const group of addedTerminalGroups) {
            await terminalGroupService.addUserGroup(group, groupId);
          }
          // ターミナルグループから削除分を更新
          for(const group of removedTerminalGroups){
            await terminalGroupService.deleteUserGroup(group, groupId);
          }
        }
      } catch (error) {
        console.log('updateUserGroup', error);
      }
    },

    async createPrincipalTerminalGroup(
      _: GroupAction,
      payload: CreatePrincipalTerminalGroupPayload
    ): Promise<void> {
      // Dynamoへ保存(新規作成)
      try {
        let newGroupId;
        if (!payload.Id) {
          const groupId = nanoid(7);
          const groupKind = "Principal";
          newGroupId = `${groupKind}&&${payload.CompanyId}&&${payload.ShopId ??
            "_"}&&${groupId}`;
        } else {
          newGroupId = payload.Id;
        }
        console.log(payload);
        const group: CreateTerminalGroupInput = {
          Id: newGroupId,
          CompanyId: payload.CompanyId,
          ShopId: payload.ShopId,
          Name: payload.Name,
          Members: {
            Terminals: [],
          },
          RelGroups: payload.RelGroups,
          CreatedAt: moment()
            .utc()
            .unix(),
          UpdatedAt: moment()
            .utc()
            .unix(),
        };
        const result: any = await API.graphql({
          query: createTerminalGroup,
          variables: { input: group },
        });
        console.log("createTerminal", result)
      } catch (error) {
        console.log("error", error);
      }
    },
    async createSecondaryTerminalGroup(
      _: GroupAction,
      payload: CreateSecondaryTerminalGroupPayload
    ): Promise<void> {
      // Dynamoへ保存(新規作成)
      console.log("create secondary", payload);
      try {
        // FIXME:現在の実装ではShopIdは必ずundefinedになる。
        console.log(payload);
        const groupId = nanoid(7);
        const newGroupId = `${payload.groupInfo.Kind}&&${payload.groupInfo.CompanyId
          }&&${payload.groupInfo.ShopId ?? "_"}&&${groupId}`;
        const group = {
          Id: newGroupId,
          CompanyId: payload.groupInfo.CompanyId,
          ShopId: payload.groupInfo.ShopId ?? "_",
          Name: payload.groupInfo.Name,
          Members: {
            Terminals: payload.selectedTerminal.map((e: TerminalSummary) => ({
              Id: e.Id,
              Kind: e.Kind,
            })),
          },
          RelGroups: payload.selectedUserGroup,
          CreatedAt: moment()
            .utc()
            .unix(),
          UpdatedAt: moment()
            .utc()
            .unix(),
        };
        console.log("createTerminalGroup", group);
        const result: any = await API.graphql({
          query: createTerminalGroup,
          variables: { input: group },
        });
        console.log("createTerminalGroup", result);
        // terminalのgroupを更新
        for(const terminal of payload.selectedTerminal){
          await terminalGroupService.updateTerminalAndTerminalGroup(terminal, newGroupId)
        }
        // ユーザーグループの更新
        for(const groupId of payload.selectedUserGroup){
          await userGroupService.addTerminalGroup(groupId, newGroupId);
        }
      } catch (error) {
        console.log("error", error);
      }
    },

    /** ターミナルグループを更新する */
    async updateTerminalGroup(_: GroupAction, payload: UpdateTerminalGroupPayload): Promise<void> {
      console.log("[UpdateTerminalGroup]", payload);
      // Dynamoへ保存(更新)
      try {
        // グループの更新
        const group = Object.assign({}, payload.groupInfo);
        group.UpdatedAt = moment().utc().unix() // 更新日時は必ず最新化する
        if(!group.Members?.Terminals){
          throw Error(`[Update terminal group]Invalid Member: ${group}`)
        }
        const variables: UpdateTerminalGroupInput = {
          Id: group.Id as string,
          Name: group.Name as string,
          CompanyId: group.CompanyId as string,
          ShopId: group.ShopId as string,
          Members: {
            Terminals: []
          },
          UpdatedAt: group.UpdatedAt,
        }

        // ダイアログのターミナル選択画面表示が行われていなければ変更しない
        if (!payload.isInitial.terminal) {
          variables.Members.Terminals = payload.selectedTerminal
            .map(x => { return { Id: x.Id || '', Kind: x.Kind! } })
        } else {
          if (!group.Members.Terminals) throw new Error("グループのデータが不正です");
          variables.Members.Terminals = (group.Members.Terminals as TerminalSummary[])
            .map(x => { return { Id: x.Id || '', Kind: x.Kind! } });
        }
        // ダイアログのユーザーグループ選択画面表示が行われていなければ変更しない
        if (!payload.isInitial.userGroup) {
          variables.RelGroups = payload.selectedUserGroup;
        }
        console.log('updateTerminalGroupById', group, variables);
        const result = await updateTerminalGroupById({ input: variables });

        const groupId = group.Id as string;

        // 端末の紐づけを変更した場合の更新処理
        if (!payload.isInitial.terminal) {
          const initialTerminals = (group.Members.Terminals as TerminalSummary[]);
          const addedTerminals: TerminalSummary[] = payload.selectedTerminal
            .filter(e => {  // 既存に含まれない = 追加された端末
              return e && (initialTerminals.find(s => s.Id == e.Id && s.Kind == e.Kind) == null);
            })
          const removedTerminals = payload.groupInfo.Members?.Terminals ? payload.groupInfo.Members.Terminals.filter(
            e => e && !payload.selectedTerminal.find((f) => f.Id == e.Id && f.Kind == e.Kind)
          ): [];
          console.log('updateTerminalGroup', 'updateTerminals', addedTerminals, removedTerminals);
          // グループに追加したterminalの更新
          for(const terminal of addedTerminals){
            await terminalGroupService.updateTerminalAndTerminalGroup(terminal, groupId);
          }
          // グループから削除したTerminalの更新
          for(const terminal of removedTerminals){
            if (!terminal)continue;
            await terminalGroupService.deleteTerminalAndTerminalGroup(terminal);
          }
        }
        // ユーザグループの紐づけを変更した場合の処理
        if (!payload.isInitial.userGroup) { 
          const initialUserGroups = (group.RelGroups as string[]) ?? [];
          const addedUserGroups: string[] = payload.selectedUserGroup
            .filter(e => e && e.length > 0) // 念のためフィルタする
            .filter(e => !initialUserGroups.includes(e)); // 既存に含まれない = 追加されたユーザ
          const removedUserGroups: Array<string|null> = payload.groupInfo.RelGroups ? payload.groupInfo.RelGroups
            .filter(e => e && e.length > 0) // 念のためフィルタする
            .filter( // 編集後に含まれない = 削除されたユーザグループ
              e => e && !payload.selectedUserGroup.includes(e)
            ): [];
            console.log('updateTerminalGroup', 'updateUserGroups', addedUserGroups, removedUserGroups);
          // ユーザーグループの更新
          for(const group of addedUserGroups){
            await userGroupService.addTerminalGroup(group, groupId);
          }
          // ユーザーグループから削除分を更新
          for (const group of removedUserGroups) {
            if (group == null) continue;
            await userGroupService.deleteTerminalGroup(group, groupId);
          }
        }
      } catch (error) {
        console.log('updateTerminalGroup', error);
      }
    },
    async fetchTerminalGroupsForDialog({ commit }: GroupAction, payload: {CompanyId: string}): Promise<void> {
      try {
        const groups = await getAllTerminalGroups({
            filter: {
              CompanyId: {
                eq: payload.CompanyId,
              },
            },
        });
        console.log(groups);
        commit("setTerminalGroupsForDialog", groups);
      } catch (error) {
        console.log(error);
        throw "グループ取得時にエラーが発生しました";
      }
    },
    async fetchUserGroupsForDialog({ commit }: GroupAction, payload: {CompanyId: string}): Promise<void> {
      try {
        const groups = await getAllUserGroups({
            filter: {
              CompanyId: {
                eq: payload.CompanyId,
              },
            },
        });
        console.log(groups);
        commit("setUserGroupsForDialog", groups);
      } catch (error) {
        console.log(error);
        throw "グループ取得時にエラーが発生しました";
      }
    },
  },
  getters: {
    userGroups: (state: GroupState): ListUserGroups => state.userGroups,
    terminalGroups: (state: GroupState): ListTerminalGroups => state.terminalGroups,
    searchableUserGroups: (state: GroupState): ListUserGroups => state.userGroups,
    searchableTerminalGroups: (state: GroupState): ListTerminalGroups => state.terminalGroups,
    userGroupsForDialog: (state: GroupState): ListUserGroups => state.dialog.targetUserGroup,
    terminalGroupsForDialog: (state: GroupState): ListTerminalGroups => state.dialog.targetTerminalGroup,
  },
};

