



















































import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { ShopDialogInputType } from "@/types";
import { Action, Getter } from "vuex-class";
import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";

@Component
export default class ShopEditDialog extends Vue {
  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;
  @Getter("auth/dbUser") dbUser!: any;

  @Prop() shopInfo!: any;
  @Prop() shops!: any;
  @Prop() companyList!: any;
  @PropSync("isEdit", { type: Boolean }) isEditValue!: boolean;
  // data
  errorMessage: string | null = null;
  defaultItem: ShopDialogInputType = {
    Name: "",
    DisplayName: "",
    CompanyId: "",
    StreetAddress: "",
    PhoneNo: "",
  };
  editedItem = this.shopInfo ?? this.defaultItem;
  isValid = false;
  rules = {
    required: (value: any) => !!value || "必須項目です",
    nameLength: (value: any) => {
      return value.length <= 255 || "店舗名は255文字以下で入力してください";
    },
    displayNameLength: (value: any) => {
      return value.length <= 255 || "表示名は255文字以下で入力してください";
    },
  };
  @Watch("isEditValue", { immediate: false, deep: true })
  onModalClose(val: boolean, oldVal: boolean) {
    this.editedItem = this.shopInfo ?? {
      Name: "",
      DisplayName: "",
      CompanyId: "",
      StreetAddress: "",
      PhoneNo: "",
    };

    if (!val) this.close();
  }

  // computed
  get formTitle(): string {
    return !this.shopInfo ? "店舗作成" : "店舗編集";
  }
  get saveButtonText(): string {
    return !this.shopInfo ? "作成" : "保存";
  }

  // method
  async save() {
    try {
      this.isValid = false;
      this.errorMessage = null;
      const checkDuplicateName = this.shops.filter(
        (e: any) =>
          e.Name === this.editedItem.Name &&
          e.CompanyId === this.editedItem.CompanyId &&
          e.Id != this.editedItem.Id
      );

      if (checkDuplicateName.length > 0) {
        this.isValid = true;
        throw { code: "DUPLICATE_SHOP_NAME" };
      }

      if ("Id" in this.editedItem) {
        await this.$store.dispatch("shop/updateShop", this.shopInfo);
        await this.loggingOperationHistory({
          user: this.dbUser,
          viewName: "店舗編集ダイアログ",
          viewId: "ShopEditDialog",
          operationName: "店舗編集",
          operationId: "shopEdit",
          detail: {
            shopName: this.shopInfo.Name,
            shopId: this.shopInfo.Id,
          },
        });
      } else {
        // 新規作成
        const saveData = {
          Name: this.editedItem.Name,
          DisplayName: this.editedItem.DisplayName,
          CompanyId: this.editedItem.CompanyId,
          StreetAddress: this.editedItem.StreetAddress,
          PhoneNo: this.editedItem.PhoneNo,
        };
        await this.$store.dispatch("shop/createShop", saveData);
        await this.loggingOperationHistory({
          user: this.dbUser,
          viewName: "店舗作成ダイアログ",
          viewId: "ShopCreateDialog",
          operationName: "店舗作成",
          operationId: "shopCreate",
          detail: {
            shopName: this.editedItem.Name,
            shopId: this.editedItem.Id,
          },
        });
      }
      await this.$store.dispatch("shop/fetchShops");
      this.close();
    } catch (e: any) {
      if (e.code == "DUPLICATE_SHOP_NAME")
        this.errorMessage = "既に登録されています";
      else this.errorMessage = "登録時にエラーが発生しました";
    }
  }

  close() {
    this.errorMessage = null;
    this.isEditValue = false;
    this.isValid = false;
  }
}
