import { getUserById, updateCognitoUserById } from "@/interface/graphql/user/user";

export const updateGroup = async (userId: string, groupId: string): Promise<string | null> => {
  const user = await getUserById({
    Id: userId,
  });
  if(!user) return null;
  const oldGroupId = user.GroupId;
  if(oldGroupId === groupId) return null;
  // 更新グループIDが企業PrincipalならShopIdを「_」にする。
  const newShopId = groupId.startsWith("Secondary") ? user.ShopId : groupId.split('&&')[2];
  await updateCognitoUserById({
      input: {
        CompanyId: user.CompanyId,
        ShopId: newShopId,
        Id: user.Id,
        Email: user.Email,
        GroupId: groupId,
        CognitoGroup: user.CognitoGroup,
        MaintenanceCardId: user.MaintenanceCardId,
        isDelete: user.isDelete ?? "false",
      },
  });
  return oldGroupId ?? null;
}