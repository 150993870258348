import { API } from "aws-amplify";
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import {
  listUserGroups,
  getUserGroup,
} from "@/graphql/queries";
import {
  createUserGroup as _createUserGroup,
  updateUserGroup,
  deleteUserGroup as _deleteUserGroup,
} from '@/graphql/mutations'
import {
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables,
  UpdateUserGroupMutation,
  ListUserGroupsQueryVariables,
  ListUserGroupsQuery,
  GetUserGroupQuery,
  GetUserGroupQueryVariables,
  UpdateUserGroupMutationVariables,
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables
} from "@/API";

type NullableUserGroups = NonNullable<NonNullable<ListUserGroupsQuery["listUserGroups"]>["items"]>
export type ListUserGroups = NonNullable<NullableUserGroups[number]>[]



export const getAllUserGroups = async (variables: null | ListUserGroupsQueryVariables = null): Promise<ListUserGroups> => {
  const queryOption = {
    query: listUserGroups,
  } as GraphQLOptions
  if (variables) { queryOption.variables = variables }
  const graphqlFunc = async (option: GraphQLOptions) => {
    const response =  await API.graphql(option) as {data: ListUserGroupsQuery}
    return response.data.listUserGroups
  }
  let queryResult = await graphqlFunc(queryOption)
  if (!queryResult) return []
  let result = queryResult.items? queryResult.items: []
  const nextVariables: ListUserGroupsQueryVariables = variables? {...variables} : {}
  while (queryResult.nextToken){
    nextVariables.nextToken = queryResult.nextToken
    queryResult = await graphqlFunc({query:listUserGroups, variables: nextVariables})
    if (!queryResult) break
    result = result.concat(queryResult.items? queryResult.items: [])
  }
  return result.filter((v: NullableUserGroups[number]) => v) as ListUserGroups
}

export type NullableUserGroup = GetUserGroupQuery['getUserGroup']

export const getUserGroupById = async (variables: GetUserGroupQueryVariables): Promise<NullableUserGroup> => {
  const queryOption = {
    query: getUserGroup,
    variables: variables,
  } as GraphQLOptions
  const graphqlFunc = async (option: GraphQLOptions) => {
    const response =  await API.graphql(option) as {data: GetUserGroupQuery}
    return response.data.getUserGroup
  }
  return  await graphqlFunc(queryOption)
}


export const updateUserGroupById = async (variables: UpdateUserGroupMutationVariables): Promise<void> => {
  console.info("[Update UserGroup Request]", variables)
  const result = await API.graphql({
    query: updateUserGroup,
    variables: variables,
  }) as UpdateUserGroupMutation;
  console.log("[Update UserGroup Response]",result);
}

type CreateUserGroup = CreateUserGroupMutation["createUserGroup"]

export const createUserGroup = async (variables: CreateUserGroupMutationVariables): Promise<CreateUserGroup> => {
  console.info("[Create UserGroup Request]", variables)
  const result = await API.graphql({
    query: _createUserGroup,
    variables: variables,
  }) as {data: CreateUserGroupMutation};
  console.log("[Create UserGroup Response]",result);
  return result.data.createUserGroup;
}

type DeleteUserGroup = DeleteUserGroupMutation["deleteUserGroup"]

export const deleteUserGroup = async (variables: DeleteUserGroupMutationVariables): Promise<DeleteUserGroup> => {
  console.info("[Delete UserGroup Request]", variables)
  const result = await API.graphql({
    query: _deleteUserGroup,
    variables: variables,
  }) as {data: DeleteUserGroupMutation};
  console.log("[Delete UserGroup Response]",result);
  return result.data.deleteUserGroup;
}

