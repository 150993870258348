/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type UpdateUserInput = {
  Id: string,
  Email?: string | null,
  CompanyId?: string | null,
  ShopId?: string | null,
  GroupId?: string | null,
  AffiliatedGroups?: AffiliatedGroupInput | null,
  isDelete?: string | null,
  CognitoGroup?: CognitoGroup | null,
  MaintenanceCardId?: string | null,
  LastLoginDate?: number | null,
  CreatedAt?: number | null,
  UpdatedAt?: number | null,
};

export type AffiliatedGroupInput = {
  Principal: string,
  Secondary?: string | null,
};

export enum CognitoGroup {
  ShopUser = "ShopUser",
  ShopManager = "ShopManager",
  CorpSuperVisor = "CorpSuperVisor",
  CorpAdmin = "CorpAdmin",
  SystemUser = "SystemUser",
  SystemAdmin = "SystemAdmin",
}


export type CreateUserInput = {
  Id?: string | null,
  Email: string,
  CompanyId: string,
  ShopId: string,
  GroupId?: string | null,
  AffiliatedGroups?: AffiliatedGroupInput | null,
  isDelete?: string | null,
  CognitoGroup: CognitoGroup,
  MaintenanceCardId?: string | null,
  LastLoginDate?: number | null,
  CreatedAt: number,
  UpdatedAt: number,
};

export type ModelUserConditionInput = {
  Email?: ModelStringInput | null,
  CompanyId?: ModelIDInput | null,
  ShopId?: ModelIDInput | null,
  GroupId?: ModelIDInput | null,
  isDelete?: ModelStringInput | null,
  CognitoGroup?: ModelCognitoGroupInput | null,
  MaintenanceCardId?: ModelStringInput | null,
  LastLoginDate?: ModelIntInput | null,
  CreatedAt?: ModelIntInput | null,
  UpdatedAt?: ModelIntInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelCognitoGroupInput = {
  eq?: CognitoGroup | null,
  ne?: CognitoGroup | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type User = {
  __typename: "User",
  Id: string,
  Email: string,
  CompanyId: string,
  ShopId: string,
  GroupId?: string | null,
  AffiliatedGroups?: AffiliatedGroup | null,
  isDelete?: string | null,
  CognitoGroup: CognitoGroup,
  company: Company,
  shop?: Shop | null,
  MaintenanceCardId?: string | null,
  LastLoginDate?: number | null,
  CreatedAt: number,
  UpdatedAt: number,
  relUserGroup?: UserGroup | null,
};

export type AffiliatedGroup = {
  __typename: "AffiliatedGroup",
  Principal: string,
  Secondary?: string | null,
};

export type Company = {
  __typename: "Company",
  Id: string,
  Name: string,
  DisplayName: string,
  Shops?: Array< string > | null,
  GeneralConfigs: GeneralConfigs,
  UseStatus: CompanyUseStatus,
  SubSystemConfigs: CompanySubSystemConfig,
  isDelete: string,
  CreatedAt: number,
  UpdatedAt: number,
  owner: string,
};

export type GeneralConfigs = {
  __typename: "GeneralConfigs",
  TaxCalcMathod: number,
};

export type CompanyUseStatus = {
  __typename: "CompanyUseStatus",
  TopUp: boolean,
  TicketKiosk: boolean,
};

export type CompanySubSystemConfig = {
  __typename: "CompanySubSystemConfig",
  Common: CommonConfig,
  TicketKiosk?: TicketKioskConfig | null,
};

export type CommonConfig = {
  __typename: "CommonConfig",
  Shiagel: ShiagelConfig,
};

export type ShiagelConfig = {
  __typename: "ShiagelConfig",
  AuthCode: string,
  Use: boolean,
};

export type TicketKioskConfig = {
  __typename: "TicketKioskConfig",
  Fingo?: FingoConfig | null,
  Coupon?: CouponConfig | null,
};

export type FingoConfig = {
  __typename: "FingoConfig",
  Use: boolean,
};

export type CouponConfig = {
  __typename: "CouponConfig",
  CalcMethod: number,
};

export type Shop = {
  __typename: "Shop",
  Id: string,
  CompanyId: string,
  Name: string,
  DisplayName: string,
  Terminals?:  Array<TerminalSummary | null > | null,
  StreetAddress?: string | null,
  PhoneNo?: string | null,
  isDelete?: string | null,
  CreatedAt: number,
  UpdatedAt: number,
  owner: string,
  company?: Company | null,
};

export type TerminalSummary = {
  __typename: "TerminalSummary",
  Id: string,
  Kind: TerminalKind,
};

export enum TerminalKind {
  TopUp = "TopUp",
  TicketKiosk = "TicketKiosk",
}


export type UserGroup = {
  __typename: "UserGroup",
  Id: string,
  MemberKind: string,
  Name: string,
  CompanyId: string,
  ShopId?: string | null,
  Members: UsersGroupMember,
  RelGroups?: Array< string | null > | null,
  CreatedAt: number,
  UpdatedAt: number,
  company?: Company | null,
  users?: ModelUserConnection | null,
  relTerminalGroups?: ModelTerminalGroupConnection | null,
};

export type UsersGroupMember = {
  __typename: "UsersGroupMember",
  Users?: Array< string | null > | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items?:  Array<User | null > | null,
  nextToken?: string | null,
};

export type ModelTerminalGroupConnection = {
  __typename: "ModelTerminalGroupConnection",
  items?:  Array<TerminalGroup | null > | null,
  nextToken?: string | null,
};

export type TerminalGroup = {
  __typename: "TerminalGroup",
  Id: string,
  MemberKind: string,
  Name: string,
  CompanyId: string,
  ShopId?: string | null,
  Members?: TerminalsGroupMember | null,
  RelGroups?: Array< string | null > | null,
  CreatedAt: number,
  UpdatedAt: number,
  company?: Company | null,
  terminals?: ModelTerminalConnection | null,
  relUserGroups?: ModelUserGroupConnection | null,
};

export type TerminalsGroupMember = {
  __typename: "TerminalsGroupMember",
  Terminals?:  Array<TerminalSummary | null > | null,
};

export type ModelTerminalConnection = {
  __typename: "ModelTerminalConnection",
  items?:  Array<Terminal | null > | null,
  nextToken?: string | null,
};

export type Terminal = {
  __typename: "Terminal",
  Id: string,
  Kind: TerminalKind,
  CompanyId: string,
  ShopId: string,
  GroupId: string,
  AffiliatedGroups: AffiliatedGroup,
  isDelete?: string | null,
  DisplayName: string,
  CreatedAt: number,
  UpdatedAt: number,
  company?: Company | null,
  shop?: Shop | null,
  relTerminalGroup?: TerminalGroup | null,
};

export type ModelUserGroupConnection = {
  __typename: "ModelUserGroupConnection",
  items?:  Array<UserGroup | null > | null,
  nextToken?: string | null,
};

export type DeleteUserInput = {
  Id?: string | null,
};

export type CreateCompanyInput = {
  Id?: string | null,
  Name: string,
  DisplayName: string,
  Shops?: Array< string > | null,
  GeneralConfigs: GeneralConfigsInput,
  UseStatus: CompanyUseStatusInput,
  SubSystemConfigs: CompanySubSystemConfigInput,
  isDelete: string,
  CreatedAt: number,
  UpdatedAt: number,
  owner: string,
};

export type GeneralConfigsInput = {
  TaxCalcMathod: number,
};

export type CompanyUseStatusInput = {
  TopUp: boolean,
  TicketKiosk: boolean,
};

export type CompanySubSystemConfigInput = {
  Common: CommonConfigInput,
  TicketKiosk?: TicketKioskConfigInput | null,
};

export type CommonConfigInput = {
  Shiagel: ShiagelConfigInput,
};

export type ShiagelConfigInput = {
  AuthCode: string,
  Use: boolean,
};

export type TicketKioskConfigInput = {
  Fingo?: FingoConfigInput | null,
  Coupon?: CouponConfigInput | null,
};

export type FingoConfigInput = {
  Use: boolean,
};

export type CouponConfigInput = {
  CalcMethod: number,
};

export type ModelCompanyConditionInput = {
  Name?: ModelStringInput | null,
  DisplayName?: ModelStringInput | null,
  Shops?: ModelStringInput | null,
  isDelete?: ModelStringInput | null,
  CreatedAt?: ModelIntInput | null,
  UpdatedAt?: ModelIntInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelCompanyConditionInput | null > | null,
  or?: Array< ModelCompanyConditionInput | null > | null,
  not?: ModelCompanyConditionInput | null,
};

export type UpdateCompanyInput = {
  Id: string,
  Name?: string | null,
  DisplayName?: string | null,
  Shops?: Array< string > | null,
  GeneralConfigs?: GeneralConfigsInput | null,
  UseStatus?: CompanyUseStatusInput | null,
  SubSystemConfigs?: CompanySubSystemConfigInput | null,
  isDelete?: string | null,
  CreatedAt?: number | null,
  UpdatedAt?: number | null,
  owner?: string | null,
};

export type DeleteCompanyInput = {
  Id?: string | null,
};

export type CreateShopInput = {
  Id?: string | null,
  CompanyId: string,
  Name: string,
  DisplayName: string,
  Terminals?: Array< TerminalSummaryInput | null > | null,
  StreetAddress?: string | null,
  PhoneNo?: string | null,
  isDelete?: string | null,
  CreatedAt?: number | null,
  UpdatedAt?: number | null,
  owner: string,
};

export type TerminalSummaryInput = {
  Id: string,
  Kind: TerminalKind,
};

export type ModelShopConditionInput = {
  CompanyId?: ModelIDInput | null,
  Name?: ModelStringInput | null,
  DisplayName?: ModelStringInput | null,
  StreetAddress?: ModelStringInput | null,
  PhoneNo?: ModelStringInput | null,
  isDelete?: ModelStringInput | null,
  CreatedAt?: ModelStringInput | null,
  UpdatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelShopConditionInput | null > | null,
  or?: Array< ModelShopConditionInput | null > | null,
  not?: ModelShopConditionInput | null,
};

export type UpdateShopInput = {
  Id: string,
  CompanyId?: string | null,
  Name?: string | null,
  DisplayName?: string | null,
  Terminals?: Array< TerminalSummaryInput | null > | null,
  StreetAddress?: string | null,
  PhoneNo?: string | null,
  isDelete?: string | null,
  CreatedAt?: number | null,
  UpdatedAt?: number | null,
  owner?: string | null,
};

export type DeleteShopInput = {
  Id?: string | null,
};

export type CreateUserGroupInput = {
  Id?: string | null,
  Name: string,
  CompanyId: string,
  ShopId?: string | null,
  Members: UserGroupMembersInput,
  RelGroups?: Array< string | null > | null,
  CreatedAt?: number | null,
  UpdatedAt?: number | null,
};

export type UserGroupMembersInput = {
  Users?: Array< string | null > | null,
};

export type ModelUserGroupConditionInput = {
  Name?: ModelStringInput | null,
  CompanyId?: ModelIDInput | null,
  ShopId?: ModelIDInput | null,
  Members?: ModelStringInput | null,
  RelGroups?: ModelIDInput | null,
  CreatedAt?: ModelStringInput | null,
  UpdatedAt?: ModelStringInput | null,
  and?: Array< ModelUserGroupConditionInput | null > | null,
  or?: Array< ModelUserGroupConditionInput | null > | null,
  not?: ModelUserGroupConditionInput | null,
};

export type UpdateUserGroupInput = {
  Id: string,
  Name?: string | null,
  MemberKind?: string | null,
  CompanyId?: string | null,
  ShopId?: string | null,
  Members: UserGroupMembersInput,
  RelGroups?: Array< string | null > | null,
  CreatedAt?: number | null,
  UpdatedAt?: number | null,
};

export type DeleteUserGroupInput = {
  Id?: string | null,
};

export type CreateTerminalGroupInput = {
  Id?: string | null,
  Name: string,
  CompanyId: string,
  ShopId?: string | null,
  Members: TerminalGroupMembersInput,
  RelGroups?: Array< string | null > | null,
  CreatedAt?: number | null,
  UpdatedAt?: number | null,
};

export type TerminalGroupMembersInput = {
  Terminals?: Array< TerminalSummaryInput | null > | null,
};

export type ModelTerminalGroupConditionInput = {
  Name?: ModelStringInput | null,
  CompanyId?: ModelIDInput | null,
  ShopId?: ModelIDInput | null,
  Members?: ModelStringInput | null,
  RelGroups?: ModelStringInput | null,
  CreatedAt?: ModelStringInput | null,
  UpdatedAt?: ModelStringInput | null,
  and?: Array< ModelTerminalGroupConditionInput | null > | null,
  or?: Array< ModelTerminalGroupConditionInput | null > | null,
  not?: ModelTerminalGroupConditionInput | null,
};

export type UpdateTerminalGroupInput = {
  Id: string,
  Name?: string | null,
  MemberKind?: string | null,
  CompanyId?: string | null,
  ShopId?: string | null,
  Members: TerminalGroupMembersInput,
  RelGroups?: Array< string | null > | null,
  CreatedAt?: number | null,
  UpdatedAt?: number | null,
};

export type DeleteTerminalGroupInput = {
  Id?: string | null,
};

export type CreateTerminalInput = {
  Id?: string | null,
  Kind: TerminalKind,
  CompanyId: string,
  ShopId: string,
  GroupId: string,
  AffiliatedGroups: AffiliatedGroupInput,
  isDelete?: string | null,
  DisplayName: string,
  CreatedAt: number,
  UpdatedAt: number,
};

export type ModelTerminalConditionInput = {
  CompanyId?: ModelIDInput | null,
  ShopId?: ModelIDInput | null,
  GroupId?: ModelIDInput | null,
  isDelete?: ModelStringInput | null,
  DisplayName?: ModelStringInput | null,
  CreatedAt?: ModelIntInput | null,
  UpdatedAt?: ModelIntInput | null,
  and?: Array< ModelTerminalConditionInput | null > | null,
  or?: Array< ModelTerminalConditionInput | null > | null,
  not?: ModelTerminalConditionInput | null,
};

export type UpdateTerminalInput = {
  Id: string,
  Kind?: TerminalKind | null,
  CompanyId?: string | null,
  ShopId?: string | null,
  GroupId?: string | null,
  AffiliatedGroups?: AffiliatedGroupInput | null,
  isDelete?: string | null,
  DisplayName?: string | null,
  CreatedAt?: number | null,
  UpdatedAt?: number | null,
};

export type DeleteTerminalInput = {
  Id?: string | null,
};

export type CreateSystemOperationHistoriesInput = {
  UserId: string,
  UserMail: string,
  TimestampMs: number,
  ViewName: string,
  ViewId: string,
  OperationName: string,
  OperationId: string,
  Detail?: string | null,
  CreatedAt: number,
};

export type SystemOperationHistories = {
  __typename: "SystemOperationHistories",
  UserId: string,
  UserMail: string,
  TimestampMs: number,
  ViewName: string,
  ViewId: string,
  OperationName: string,
  OperationId: string,
  Detail?: string | null,
  CreatedAt: number,
};

export type CreateSystemPlannedMaintenanceSchedules = {
  ScheduleId: string,
  StartTimestampMs?: number | null,
  EndTimestampMs?: number | null,
  Message: string,
  UserId: string,
  UserName: string,
  CreatedAt: number,
};

export type SystemPlannedMaintenanceSchedules = {
  __typename: "SystemPlannedMaintenanceSchedules",
  ScheduleId: string,
  StartTimestampMs?: number | null,
  EndTimestampMs?: number | null,
  Message: string,
  UserId: string,
  UserName: string,
  CreatedAt: number,
};

export type UpdateSystemPlannedMaintenanceSchedules = {
  ScheduleId: string,
  StartTimestampMs?: number | null,
  EndTimestampMs?: number | null,
  Message: string,
  UserId: string,
  UserName: string,
  CreatedAt: number,
};

export type ModelUserFilterInput = {
  Id?: ModelIDInput | null,
  Email?: ModelStringInput | null,
  CompanyId?: ModelIDInput | null,
  ShopId?: ModelIDInput | null,
  GroupId?: ModelIDInput | null,
  isDelete?: ModelStringInput | null,
  CognitoGroup?: ModelCognitoGroupInput | null,
  MaintenanceCardId?: ModelStringInput | null,
  LastLoginDate?: ModelIntInput | null,
  CreatedAt?: ModelIntInput | null,
  UpdatedAt?: ModelIntInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelCompanyFilterInput = {
  Id?: ModelIDInput | null,
  Name?: ModelStringInput | null,
  DisplayName?: ModelStringInput | null,
  Shops?: ModelStringInput | null,
  isDelete?: ModelStringInput | null,
  CreatedAt?: ModelIntInput | null,
  UpdatedAt?: ModelIntInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelCompanyFilterInput | null > | null,
  or?: Array< ModelCompanyFilterInput | null > | null,
  not?: ModelCompanyFilterInput | null,
};

export type ModelCompanyConnection = {
  __typename: "ModelCompanyConnection",
  items?:  Array<Company | null > | null,
  nextToken?: string | null,
};

export type ModelShopFilterInput = {
  Id?: ModelIDInput | null,
  CompanyId?: ModelIDInput | null,
  Name?: ModelStringInput | null,
  DisplayName?: ModelStringInput | null,
  StreetAddress?: ModelStringInput | null,
  PhoneNo?: ModelStringInput | null,
  isDelete?: ModelStringInput | null,
  CreatedAt?: ModelStringInput | null,
  UpdatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelShopFilterInput | null > | null,
  or?: Array< ModelShopFilterInput | null > | null,
  not?: ModelShopFilterInput | null,
};

export type ModelShopConnection = {
  __typename: "ModelShopConnection",
  items?:  Array<Shop | null > | null,
  nextToken?: string | null,
};

export type ModelUserGroupFilterInput = {
  Id?: ModelIDInput | null,
  Name?: ModelStringInput | null,
  CompanyId?: ModelIDInput | null,
  ShopId?: ModelIDInput | null,
  Members?: ModelStringInput | null,
  RelGroups?: ModelIDInput | null,
  CreatedAt?: ModelStringInput | null,
  UpdatedAt?: ModelStringInput | null,
  and?: Array< ModelUserGroupFilterInput | null > | null,
  or?: Array< ModelUserGroupFilterInput | null > | null,
  not?: ModelUserGroupFilterInput | null,
};

export type ModelTerminalGroupFilterInput = {
  Id?: ModelIDInput | null,
  Name?: ModelStringInput | null,
  CompanyId?: ModelIDInput | null,
  ShopId?: ModelIDInput | null,
  Members?: ModelStringInput | null,
  RelGroups?: ModelStringInput | null,
  CreatedAt?: ModelStringInput | null,
  UpdatedAt?: ModelStringInput | null,
  and?: Array< ModelTerminalGroupFilterInput | null > | null,
  or?: Array< ModelTerminalGroupFilterInput | null > | null,
  not?: ModelTerminalGroupFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelTerminalFilterInput = {
  Id?: ModelIDInput | null,
  Kind?: ModelTerminalKindInput | null,
  CompanyId?: ModelIDInput | null,
  ShopId?: ModelIDInput | null,
  GroupId?: ModelIDInput | null,
  isDelete?: ModelStringInput | null,
  DisplayName?: ModelStringInput | null,
  CreatedAt?: ModelIntInput | null,
  UpdatedAt?: ModelIntInput | null,
  and?: Array< ModelTerminalFilterInput | null > | null,
  or?: Array< ModelTerminalFilterInput | null > | null,
  not?: ModelTerminalFilterInput | null,
};

export type ModelTerminalKindInput = {
  eq?: TerminalKind | null,
  ne?: TerminalKind | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserUserByGroupWithCompanyAndDeleteStatusCompositeKeyConditionInput = {
  eq?: ModelUserUserByGroupWithCompanyAndDeleteStatusCompositeKeyInput | null,
  le?: ModelUserUserByGroupWithCompanyAndDeleteStatusCompositeKeyInput | null,
  lt?: ModelUserUserByGroupWithCompanyAndDeleteStatusCompositeKeyInput | null,
  ge?: ModelUserUserByGroupWithCompanyAndDeleteStatusCompositeKeyInput | null,
  gt?: ModelUserUserByGroupWithCompanyAndDeleteStatusCompositeKeyInput | null,
  between?: Array< ModelUserUserByGroupWithCompanyAndDeleteStatusCompositeKeyInput | null > | null,
  beginsWith?: ModelUserUserByGroupWithCompanyAndDeleteStatusCompositeKeyInput | null,
};

export type ModelUserUserByGroupWithCompanyAndDeleteStatusCompositeKeyInput = {
  CompanyId?: string | null,
  isDelete?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type SystemOperationHistoriesFilterInput = {
  UserId?: string | null,
  UserMail?: StringFilterInput | null,
  TimestampMs?: FloatFilterInput | null,
  ViewName?: StringFilterInput | null,
  ViewId?: StringFilterInput | null,
  OperationName?: StringFilterInput | null,
  OperationId?: StringFilterInput | null,
};

export type StringFilterInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type FloatFilterInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  contains?: number | null,
  notContains?: number | null,
  between?: Array< number | null > | null,
};

export type SystemOperationHistoriesConnection = {
  __typename: "SystemOperationHistoriesConnection",
  items?:  Array<SystemOperationHistories | null > | null,
  nextToken?: string | null,
};

export type SystemPlannedMaintenanceSchedulesFilterInput = {
  ScheduleId?: IDFilterInput | null,
  StartTimestampMs?: FloatFilterInput | null,
  EndTimestampMs?: FloatFilterInput | null,
  Message?: StringFilterInput | null,
  UserId?: StringFilterInput | null,
  UserName?: StringFilterInput | null,
  CreatedAt?: FloatFilterInput | null,
};

export type IDFilterInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type SystemPlannedMaintenanceSchedulesConnection = {
  __typename: "SystemPlannedMaintenanceSchedulesConnection",
  items?:  Array<SystemPlannedMaintenanceSchedules | null > | null,
  nextToken?: string | null,
};

export type CreateCognitoUserMutationVariables = {
  Email?: string | null,
  password?: string | null,
  CompanyId?: string | null,
  ShopId?: string | null,
  CognitoGroup?: string | null,
  GroupId?: string | null,
  MaintenanceCardId?: string | null,
};

export type CreateCognitoUserMutation = {
  createCognitoUser?: string | null,
};

export type UpdateCognitoUserMutationVariables = {
  input: UpdateUserInput,
};

export type UpdateCognitoUserMutation = {
  updateCognitoUser?: string | null,
};

export type DeleteCognitoUserMutationVariables = {
  Id?: string | null,
};

export type DeleteCognitoUserMutation = {
  deleteCognitoUser?: string | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    Id: string,
    Email: string,
    CompanyId: string,
    ShopId: string,
    GroupId?: string | null,
    AffiliatedGroups?:  {
      __typename: "AffiliatedGroup",
      Principal: string,
      Secondary?: string | null,
    } | null,
    isDelete?: string | null,
    CognitoGroup: CognitoGroup,
    company:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    },
    shop?:  {
      __typename: "Shop",
      Id: string,
      CompanyId: string,
      Name: string,
      DisplayName: string,
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
      StreetAddress?: string | null,
      PhoneNo?: string | null,
      isDelete?: string | null,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
    } | null,
    MaintenanceCardId?: string | null,
    LastLoginDate?: number | null,
    CreatedAt: number,
    UpdatedAt: number,
    relUserGroup?:  {
      __typename: "UserGroup",
      Id: string,
      MemberKind: string,
      Name: string,
      CompanyId: string,
      ShopId?: string | null,
      Members:  {
        __typename: "UsersGroupMember",
        Users?: Array< string | null > | null,
      },
      RelGroups?: Array< string | null > | null,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items?:  Array< {
          __typename: "User",
          Id: string,
          Email: string,
          CompanyId: string,
          ShopId: string,
          GroupId?: string | null,
          AffiliatedGroups?:  {
            __typename: "AffiliatedGroup",
            Principal: string,
            Secondary?: string | null,
          } | null,
          isDelete?: string | null,
          CognitoGroup: CognitoGroup,
          company:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          },
          shop?:  {
            __typename: "Shop",
            Id: string,
            CompanyId: string,
            Name: string,
            DisplayName: string,
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
            StreetAddress?: string | null,
            PhoneNo?: string | null,
            isDelete?: string | null,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
          } | null,
          MaintenanceCardId?: string | null,
          LastLoginDate?: number | null,
          CreatedAt: number,
          UpdatedAt: number,
          relUserGroup?:  {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      relTerminalGroups?:  {
        __typename: "ModelTerminalGroupConnection",
        items?:  Array< {
          __typename: "TerminalGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members?:  {
            __typename: "TerminalsGroupMember",
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
          } | null,
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          terminals?:  {
            __typename: "ModelTerminalConnection",
            items?:  Array< {
              __typename: "Terminal",
              Id: string,
              Kind: TerminalKind,
              CompanyId: string,
              ShopId: string,
              GroupId: string,
              isDelete?: string | null,
              DisplayName: string,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relUserGroups?:  {
            __typename: "ModelUserGroupConnection",
            items?:  Array< {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    Id: string,
    Email: string,
    CompanyId: string,
    ShopId: string,
    GroupId?: string | null,
    AffiliatedGroups?:  {
      __typename: "AffiliatedGroup",
      Principal: string,
      Secondary?: string | null,
    } | null,
    isDelete?: string | null,
    CognitoGroup: CognitoGroup,
    company:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    },
    shop?:  {
      __typename: "Shop",
      Id: string,
      CompanyId: string,
      Name: string,
      DisplayName: string,
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
      StreetAddress?: string | null,
      PhoneNo?: string | null,
      isDelete?: string | null,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
    } | null,
    MaintenanceCardId?: string | null,
    LastLoginDate?: number | null,
    CreatedAt: number,
    UpdatedAt: number,
    relUserGroup?:  {
      __typename: "UserGroup",
      Id: string,
      MemberKind: string,
      Name: string,
      CompanyId: string,
      ShopId?: string | null,
      Members:  {
        __typename: "UsersGroupMember",
        Users?: Array< string | null > | null,
      },
      RelGroups?: Array< string | null > | null,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items?:  Array< {
          __typename: "User",
          Id: string,
          Email: string,
          CompanyId: string,
          ShopId: string,
          GroupId?: string | null,
          AffiliatedGroups?:  {
            __typename: "AffiliatedGroup",
            Principal: string,
            Secondary?: string | null,
          } | null,
          isDelete?: string | null,
          CognitoGroup: CognitoGroup,
          company:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          },
          shop?:  {
            __typename: "Shop",
            Id: string,
            CompanyId: string,
            Name: string,
            DisplayName: string,
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
            StreetAddress?: string | null,
            PhoneNo?: string | null,
            isDelete?: string | null,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
          } | null,
          MaintenanceCardId?: string | null,
          LastLoginDate?: number | null,
          CreatedAt: number,
          UpdatedAt: number,
          relUserGroup?:  {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      relTerminalGroups?:  {
        __typename: "ModelTerminalGroupConnection",
        items?:  Array< {
          __typename: "TerminalGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members?:  {
            __typename: "TerminalsGroupMember",
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
          } | null,
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          terminals?:  {
            __typename: "ModelTerminalConnection",
            items?:  Array< {
              __typename: "Terminal",
              Id: string,
              Kind: TerminalKind,
              CompanyId: string,
              ShopId: string,
              GroupId: string,
              isDelete?: string | null,
              DisplayName: string,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relUserGroups?:  {
            __typename: "ModelUserGroupConnection",
            items?:  Array< {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    Id: string,
    Email: string,
    CompanyId: string,
    ShopId: string,
    GroupId?: string | null,
    AffiliatedGroups?:  {
      __typename: "AffiliatedGroup",
      Principal: string,
      Secondary?: string | null,
    } | null,
    isDelete?: string | null,
    CognitoGroup: CognitoGroup,
    company:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    },
    shop?:  {
      __typename: "Shop",
      Id: string,
      CompanyId: string,
      Name: string,
      DisplayName: string,
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
      StreetAddress?: string | null,
      PhoneNo?: string | null,
      isDelete?: string | null,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
    } | null,
    MaintenanceCardId?: string | null,
    LastLoginDate?: number | null,
    CreatedAt: number,
    UpdatedAt: number,
    relUserGroup?:  {
      __typename: "UserGroup",
      Id: string,
      MemberKind: string,
      Name: string,
      CompanyId: string,
      ShopId?: string | null,
      Members:  {
        __typename: "UsersGroupMember",
        Users?: Array< string | null > | null,
      },
      RelGroups?: Array< string | null > | null,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items?:  Array< {
          __typename: "User",
          Id: string,
          Email: string,
          CompanyId: string,
          ShopId: string,
          GroupId?: string | null,
          AffiliatedGroups?:  {
            __typename: "AffiliatedGroup",
            Principal: string,
            Secondary?: string | null,
          } | null,
          isDelete?: string | null,
          CognitoGroup: CognitoGroup,
          company:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          },
          shop?:  {
            __typename: "Shop",
            Id: string,
            CompanyId: string,
            Name: string,
            DisplayName: string,
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
            StreetAddress?: string | null,
            PhoneNo?: string | null,
            isDelete?: string | null,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
          } | null,
          MaintenanceCardId?: string | null,
          LastLoginDate?: number | null,
          CreatedAt: number,
          UpdatedAt: number,
          relUserGroup?:  {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      relTerminalGroups?:  {
        __typename: "ModelTerminalGroupConnection",
        items?:  Array< {
          __typename: "TerminalGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members?:  {
            __typename: "TerminalsGroupMember",
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
          } | null,
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          terminals?:  {
            __typename: "ModelTerminalConnection",
            items?:  Array< {
              __typename: "Terminal",
              Id: string,
              Kind: TerminalKind,
              CompanyId: string,
              ShopId: string,
              GroupId: string,
              isDelete?: string | null,
              DisplayName: string,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relUserGroups?:  {
            __typename: "ModelUserGroupConnection",
            items?:  Array< {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type CreateCompanyMutation = {
  createCompany?:  {
    __typename: "Company",
    Id: string,
    Name: string,
    DisplayName: string,
    Shops?: Array< string > | null,
    GeneralConfigs:  {
      __typename: "GeneralConfigs",
      TaxCalcMathod: number,
    },
    UseStatus:  {
      __typename: "CompanyUseStatus",
      TopUp: boolean,
      TicketKiosk: boolean,
    },
    SubSystemConfigs:  {
      __typename: "CompanySubSystemConfig",
      Common:  {
        __typename: "CommonConfig",
        Shiagel:  {
          __typename: "ShiagelConfig",
          AuthCode: string,
          Use: boolean,
        },
      },
      TicketKiosk?:  {
        __typename: "TicketKioskConfig",
        Fingo?:  {
          __typename: "FingoConfig",
          Use: boolean,
        } | null,
        Coupon?:  {
          __typename: "CouponConfig",
          CalcMethod: number,
        } | null,
      } | null,
    },
    isDelete: string,
    CreatedAt: number,
    UpdatedAt: number,
    owner: string,
  } | null,
};

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type UpdateCompanyMutation = {
  updateCompany?:  {
    __typename: "Company",
    Id: string,
    Name: string,
    DisplayName: string,
    Shops?: Array< string > | null,
    GeneralConfigs:  {
      __typename: "GeneralConfigs",
      TaxCalcMathod: number,
    },
    UseStatus:  {
      __typename: "CompanyUseStatus",
      TopUp: boolean,
      TicketKiosk: boolean,
    },
    SubSystemConfigs:  {
      __typename: "CompanySubSystemConfig",
      Common:  {
        __typename: "CommonConfig",
        Shiagel:  {
          __typename: "ShiagelConfig",
          AuthCode: string,
          Use: boolean,
        },
      },
      TicketKiosk?:  {
        __typename: "TicketKioskConfig",
        Fingo?:  {
          __typename: "FingoConfig",
          Use: boolean,
        } | null,
        Coupon?:  {
          __typename: "CouponConfig",
          CalcMethod: number,
        } | null,
      } | null,
    },
    isDelete: string,
    CreatedAt: number,
    UpdatedAt: number,
    owner: string,
  } | null,
};

export type DeleteCompanyMutationVariables = {
  input: DeleteCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type DeleteCompanyMutation = {
  deleteCompany?:  {
    __typename: "Company",
    Id: string,
    Name: string,
    DisplayName: string,
    Shops?: Array< string > | null,
    GeneralConfigs:  {
      __typename: "GeneralConfigs",
      TaxCalcMathod: number,
    },
    UseStatus:  {
      __typename: "CompanyUseStatus",
      TopUp: boolean,
      TicketKiosk: boolean,
    },
    SubSystemConfigs:  {
      __typename: "CompanySubSystemConfig",
      Common:  {
        __typename: "CommonConfig",
        Shiagel:  {
          __typename: "ShiagelConfig",
          AuthCode: string,
          Use: boolean,
        },
      },
      TicketKiosk?:  {
        __typename: "TicketKioskConfig",
        Fingo?:  {
          __typename: "FingoConfig",
          Use: boolean,
        } | null,
        Coupon?:  {
          __typename: "CouponConfig",
          CalcMethod: number,
        } | null,
      } | null,
    },
    isDelete: string,
    CreatedAt: number,
    UpdatedAt: number,
    owner: string,
  } | null,
};

export type CreateShopMutationVariables = {
  input: CreateShopInput,
  condition?: ModelShopConditionInput | null,
};

export type CreateShopMutation = {
  createShop?:  {
    __typename: "Shop",
    Id: string,
    CompanyId: string,
    Name: string,
    DisplayName: string,
    Terminals?:  Array< {
      __typename: "TerminalSummary",
      Id: string,
      Kind: TerminalKind,
    } | null > | null,
    StreetAddress?: string | null,
    PhoneNo?: string | null,
    isDelete?: string | null,
    CreatedAt: number,
    UpdatedAt: number,
    owner: string,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
  } | null,
};

export type UpdateShopMutationVariables = {
  input: UpdateShopInput,
  condition?: ModelShopConditionInput | null,
};

export type UpdateShopMutation = {
  updateShop?:  {
    __typename: "Shop",
    Id: string,
    CompanyId: string,
    Name: string,
    DisplayName: string,
    Terminals?:  Array< {
      __typename: "TerminalSummary",
      Id: string,
      Kind: TerminalKind,
    } | null > | null,
    StreetAddress?: string | null,
    PhoneNo?: string | null,
    isDelete?: string | null,
    CreatedAt: number,
    UpdatedAt: number,
    owner: string,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
  } | null,
};

export type DeleteShopMutationVariables = {
  input: DeleteShopInput,
  condition?: ModelShopConditionInput | null,
};

export type DeleteShopMutation = {
  deleteShop?:  {
    __typename: "Shop",
    Id: string,
    CompanyId: string,
    Name: string,
    DisplayName: string,
    Terminals?:  Array< {
      __typename: "TerminalSummary",
      Id: string,
      Kind: TerminalKind,
    } | null > | null,
    StreetAddress?: string | null,
    PhoneNo?: string | null,
    isDelete?: string | null,
    CreatedAt: number,
    UpdatedAt: number,
    owner: string,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
  } | null,
};

export type CreateUserGroupMutationVariables = {
  input: CreateUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type CreateUserGroupMutation = {
  createUserGroup?:  {
    __typename: "UserGroup",
    Id: string,
    MemberKind: string,
    Name: string,
    CompanyId: string,
    ShopId?: string | null,
    Members:  {
      __typename: "UsersGroupMember",
      Users?: Array< string | null > | null,
    },
    RelGroups?: Array< string | null > | null,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items?:  Array< {
        __typename: "User",
        Id: string,
        Email: string,
        CompanyId: string,
        ShopId: string,
        GroupId?: string | null,
        AffiliatedGroups?:  {
          __typename: "AffiliatedGroup",
          Principal: string,
          Secondary?: string | null,
        } | null,
        isDelete?: string | null,
        CognitoGroup: CognitoGroup,
        company:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        },
        shop?:  {
          __typename: "Shop",
          Id: string,
          CompanyId: string,
          Name: string,
          DisplayName: string,
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
          StreetAddress?: string | null,
          PhoneNo?: string | null,
          isDelete?: string | null,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
        } | null,
        MaintenanceCardId?: string | null,
        LastLoginDate?: number | null,
        CreatedAt: number,
        UpdatedAt: number,
        relUserGroup?:  {
          __typename: "UserGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members:  {
            __typename: "UsersGroupMember",
            Users?: Array< string | null > | null,
          },
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          users?:  {
            __typename: "ModelUserConnection",
            items?:  Array< {
              __typename: "User",
              Id: string,
              Email: string,
              CompanyId: string,
              ShopId: string,
              GroupId?: string | null,
              isDelete?: string | null,
              CognitoGroup: CognitoGroup,
              MaintenanceCardId?: string | null,
              LastLoginDate?: number | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relTerminalGroups?:  {
            __typename: "ModelTerminalGroupConnection",
            items?:  Array< {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    relTerminalGroups?:  {
      __typename: "ModelTerminalGroupConnection",
      items?:  Array< {
        __typename: "TerminalGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members?:  {
          __typename: "TerminalsGroupMember",
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
        } | null,
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        terminals?:  {
          __typename: "ModelTerminalConnection",
          items?:  Array< {
            __typename: "Terminal",
            Id: string,
            Kind: TerminalKind,
            CompanyId: string,
            ShopId: string,
            GroupId: string,
            AffiliatedGroups:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            },
            isDelete?: string | null,
            DisplayName: string,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            relTerminalGroup?:  {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relUserGroups?:  {
          __typename: "ModelUserGroupConnection",
          items?:  Array< {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateUserGroupMutationVariables = {
  input: UpdateUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type UpdateUserGroupMutation = {
  updateUserGroup?:  {
    __typename: "UserGroup",
    Id: string,
    MemberKind: string,
    Name: string,
    CompanyId: string,
    ShopId?: string | null,
    Members:  {
      __typename: "UsersGroupMember",
      Users?: Array< string | null > | null,
    },
    RelGroups?: Array< string | null > | null,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items?:  Array< {
        __typename: "User",
        Id: string,
        Email: string,
        CompanyId: string,
        ShopId: string,
        GroupId?: string | null,
        AffiliatedGroups?:  {
          __typename: "AffiliatedGroup",
          Principal: string,
          Secondary?: string | null,
        } | null,
        isDelete?: string | null,
        CognitoGroup: CognitoGroup,
        company:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        },
        shop?:  {
          __typename: "Shop",
          Id: string,
          CompanyId: string,
          Name: string,
          DisplayName: string,
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
          StreetAddress?: string | null,
          PhoneNo?: string | null,
          isDelete?: string | null,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
        } | null,
        MaintenanceCardId?: string | null,
        LastLoginDate?: number | null,
        CreatedAt: number,
        UpdatedAt: number,
        relUserGroup?:  {
          __typename: "UserGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members:  {
            __typename: "UsersGroupMember",
            Users?: Array< string | null > | null,
          },
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          users?:  {
            __typename: "ModelUserConnection",
            items?:  Array< {
              __typename: "User",
              Id: string,
              Email: string,
              CompanyId: string,
              ShopId: string,
              GroupId?: string | null,
              isDelete?: string | null,
              CognitoGroup: CognitoGroup,
              MaintenanceCardId?: string | null,
              LastLoginDate?: number | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relTerminalGroups?:  {
            __typename: "ModelTerminalGroupConnection",
            items?:  Array< {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    relTerminalGroups?:  {
      __typename: "ModelTerminalGroupConnection",
      items?:  Array< {
        __typename: "TerminalGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members?:  {
          __typename: "TerminalsGroupMember",
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
        } | null,
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        terminals?:  {
          __typename: "ModelTerminalConnection",
          items?:  Array< {
            __typename: "Terminal",
            Id: string,
            Kind: TerminalKind,
            CompanyId: string,
            ShopId: string,
            GroupId: string,
            AffiliatedGroups:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            },
            isDelete?: string | null,
            DisplayName: string,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            relTerminalGroup?:  {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relUserGroups?:  {
          __typename: "ModelUserGroupConnection",
          items?:  Array< {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteUserGroupMutationVariables = {
  input: DeleteUserGroupInput,
  condition?: ModelUserGroupConditionInput | null,
};

export type DeleteUserGroupMutation = {
  deleteUserGroup?:  {
    __typename: "UserGroup",
    Id: string,
    MemberKind: string,
    Name: string,
    CompanyId: string,
    ShopId?: string | null,
    Members:  {
      __typename: "UsersGroupMember",
      Users?: Array< string | null > | null,
    },
    RelGroups?: Array< string | null > | null,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items?:  Array< {
        __typename: "User",
        Id: string,
        Email: string,
        CompanyId: string,
        ShopId: string,
        GroupId?: string | null,
        AffiliatedGroups?:  {
          __typename: "AffiliatedGroup",
          Principal: string,
          Secondary?: string | null,
        } | null,
        isDelete?: string | null,
        CognitoGroup: CognitoGroup,
        company:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        },
        shop?:  {
          __typename: "Shop",
          Id: string,
          CompanyId: string,
          Name: string,
          DisplayName: string,
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
          StreetAddress?: string | null,
          PhoneNo?: string | null,
          isDelete?: string | null,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
        } | null,
        MaintenanceCardId?: string | null,
        LastLoginDate?: number | null,
        CreatedAt: number,
        UpdatedAt: number,
        relUserGroup?:  {
          __typename: "UserGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members:  {
            __typename: "UsersGroupMember",
            Users?: Array< string | null > | null,
          },
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          users?:  {
            __typename: "ModelUserConnection",
            items?:  Array< {
              __typename: "User",
              Id: string,
              Email: string,
              CompanyId: string,
              ShopId: string,
              GroupId?: string | null,
              isDelete?: string | null,
              CognitoGroup: CognitoGroup,
              MaintenanceCardId?: string | null,
              LastLoginDate?: number | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relTerminalGroups?:  {
            __typename: "ModelTerminalGroupConnection",
            items?:  Array< {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    relTerminalGroups?:  {
      __typename: "ModelTerminalGroupConnection",
      items?:  Array< {
        __typename: "TerminalGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members?:  {
          __typename: "TerminalsGroupMember",
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
        } | null,
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        terminals?:  {
          __typename: "ModelTerminalConnection",
          items?:  Array< {
            __typename: "Terminal",
            Id: string,
            Kind: TerminalKind,
            CompanyId: string,
            ShopId: string,
            GroupId: string,
            AffiliatedGroups:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            },
            isDelete?: string | null,
            DisplayName: string,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            relTerminalGroup?:  {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relUserGroups?:  {
          __typename: "ModelUserGroupConnection",
          items?:  Array< {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateTerminalGroupMutationVariables = {
  input: CreateTerminalGroupInput,
  condition?: ModelTerminalGroupConditionInput | null,
};

export type CreateTerminalGroupMutation = {
  createTerminalGroup?:  {
    __typename: "TerminalGroup",
    Id: string,
    MemberKind: string,
    Name: string,
    CompanyId: string,
    ShopId?: string | null,
    Members?:  {
      __typename: "TerminalsGroupMember",
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
    } | null,
    RelGroups?: Array< string | null > | null,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    terminals?:  {
      __typename: "ModelTerminalConnection",
      items?:  Array< {
        __typename: "Terminal",
        Id: string,
        Kind: TerminalKind,
        CompanyId: string,
        ShopId: string,
        GroupId: string,
        AffiliatedGroups:  {
          __typename: "AffiliatedGroup",
          Principal: string,
          Secondary?: string | null,
        },
        isDelete?: string | null,
        DisplayName: string,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        shop?:  {
          __typename: "Shop",
          Id: string,
          CompanyId: string,
          Name: string,
          DisplayName: string,
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
          StreetAddress?: string | null,
          PhoneNo?: string | null,
          isDelete?: string | null,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
        } | null,
        relTerminalGroup?:  {
          __typename: "TerminalGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members?:  {
            __typename: "TerminalsGroupMember",
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
          } | null,
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          terminals?:  {
            __typename: "ModelTerminalConnection",
            items?:  Array< {
              __typename: "Terminal",
              Id: string,
              Kind: TerminalKind,
              CompanyId: string,
              ShopId: string,
              GroupId: string,
              isDelete?: string | null,
              DisplayName: string,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relUserGroups?:  {
            __typename: "ModelUserGroupConnection",
            items?:  Array< {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    relUserGroups?:  {
      __typename: "ModelUserGroupConnection",
      items?:  Array< {
        __typename: "UserGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members:  {
          __typename: "UsersGroupMember",
          Users?: Array< string | null > | null,
        },
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          items?:  Array< {
            __typename: "User",
            Id: string,
            Email: string,
            CompanyId: string,
            ShopId: string,
            GroupId?: string | null,
            AffiliatedGroups?:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            } | null,
            isDelete?: string | null,
            CognitoGroup: CognitoGroup,
            company:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            },
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            MaintenanceCardId?: string | null,
            LastLoginDate?: number | null,
            CreatedAt: number,
            UpdatedAt: number,
            relUserGroup?:  {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relTerminalGroups?:  {
          __typename: "ModelTerminalGroupConnection",
          items?:  Array< {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateTerminalGroupMutationVariables = {
  input: UpdateTerminalGroupInput,
  condition?: ModelTerminalGroupConditionInput | null,
};

export type UpdateTerminalGroupMutation = {
  updateTerminalGroup?:  {
    __typename: "TerminalGroup",
    Id: string,
    MemberKind: string,
    Name: string,
    CompanyId: string,
    ShopId?: string | null,
    Members?:  {
      __typename: "TerminalsGroupMember",
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
    } | null,
    RelGroups?: Array< string | null > | null,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    terminals?:  {
      __typename: "ModelTerminalConnection",
      items?:  Array< {
        __typename: "Terminal",
        Id: string,
        Kind: TerminalKind,
        CompanyId: string,
        ShopId: string,
        GroupId: string,
        AffiliatedGroups:  {
          __typename: "AffiliatedGroup",
          Principal: string,
          Secondary?: string | null,
        },
        isDelete?: string | null,
        DisplayName: string,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        shop?:  {
          __typename: "Shop",
          Id: string,
          CompanyId: string,
          Name: string,
          DisplayName: string,
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
          StreetAddress?: string | null,
          PhoneNo?: string | null,
          isDelete?: string | null,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
        } | null,
        relTerminalGroup?:  {
          __typename: "TerminalGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members?:  {
            __typename: "TerminalsGroupMember",
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
          } | null,
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          terminals?:  {
            __typename: "ModelTerminalConnection",
            items?:  Array< {
              __typename: "Terminal",
              Id: string,
              Kind: TerminalKind,
              CompanyId: string,
              ShopId: string,
              GroupId: string,
              isDelete?: string | null,
              DisplayName: string,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relUserGroups?:  {
            __typename: "ModelUserGroupConnection",
            items?:  Array< {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    relUserGroups?:  {
      __typename: "ModelUserGroupConnection",
      items?:  Array< {
        __typename: "UserGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members:  {
          __typename: "UsersGroupMember",
          Users?: Array< string | null > | null,
        },
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          items?:  Array< {
            __typename: "User",
            Id: string,
            Email: string,
            CompanyId: string,
            ShopId: string,
            GroupId?: string | null,
            AffiliatedGroups?:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            } | null,
            isDelete?: string | null,
            CognitoGroup: CognitoGroup,
            company:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            },
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            MaintenanceCardId?: string | null,
            LastLoginDate?: number | null,
            CreatedAt: number,
            UpdatedAt: number,
            relUserGroup?:  {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relTerminalGroups?:  {
          __typename: "ModelTerminalGroupConnection",
          items?:  Array< {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteTerminalGroupMutationVariables = {
  input: DeleteTerminalGroupInput,
  condition?: ModelTerminalGroupConditionInput | null,
};

export type DeleteTerminalGroupMutation = {
  deleteTerminalGroup?:  {
    __typename: "TerminalGroup",
    Id: string,
    MemberKind: string,
    Name: string,
    CompanyId: string,
    ShopId?: string | null,
    Members?:  {
      __typename: "TerminalsGroupMember",
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
    } | null,
    RelGroups?: Array< string | null > | null,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    terminals?:  {
      __typename: "ModelTerminalConnection",
      items?:  Array< {
        __typename: "Terminal",
        Id: string,
        Kind: TerminalKind,
        CompanyId: string,
        ShopId: string,
        GroupId: string,
        AffiliatedGroups:  {
          __typename: "AffiliatedGroup",
          Principal: string,
          Secondary?: string | null,
        },
        isDelete?: string | null,
        DisplayName: string,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        shop?:  {
          __typename: "Shop",
          Id: string,
          CompanyId: string,
          Name: string,
          DisplayName: string,
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
          StreetAddress?: string | null,
          PhoneNo?: string | null,
          isDelete?: string | null,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
        } | null,
        relTerminalGroup?:  {
          __typename: "TerminalGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members?:  {
            __typename: "TerminalsGroupMember",
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
          } | null,
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          terminals?:  {
            __typename: "ModelTerminalConnection",
            items?:  Array< {
              __typename: "Terminal",
              Id: string,
              Kind: TerminalKind,
              CompanyId: string,
              ShopId: string,
              GroupId: string,
              isDelete?: string | null,
              DisplayName: string,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relUserGroups?:  {
            __typename: "ModelUserGroupConnection",
            items?:  Array< {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    relUserGroups?:  {
      __typename: "ModelUserGroupConnection",
      items?:  Array< {
        __typename: "UserGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members:  {
          __typename: "UsersGroupMember",
          Users?: Array< string | null > | null,
        },
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          items?:  Array< {
            __typename: "User",
            Id: string,
            Email: string,
            CompanyId: string,
            ShopId: string,
            GroupId?: string | null,
            AffiliatedGroups?:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            } | null,
            isDelete?: string | null,
            CognitoGroup: CognitoGroup,
            company:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            },
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            MaintenanceCardId?: string | null,
            LastLoginDate?: number | null,
            CreatedAt: number,
            UpdatedAt: number,
            relUserGroup?:  {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relTerminalGroups?:  {
          __typename: "ModelTerminalGroupConnection",
          items?:  Array< {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateTerminalMutationVariables = {
  input: CreateTerminalInput,
  condition?: ModelTerminalConditionInput | null,
};

export type CreateTerminalMutation = {
  createTerminal?:  {
    __typename: "Terminal",
    Id: string,
    Kind: TerminalKind,
    CompanyId: string,
    ShopId: string,
    GroupId: string,
    AffiliatedGroups:  {
      __typename: "AffiliatedGroup",
      Principal: string,
      Secondary?: string | null,
    },
    isDelete?: string | null,
    DisplayName: string,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    shop?:  {
      __typename: "Shop",
      Id: string,
      CompanyId: string,
      Name: string,
      DisplayName: string,
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
      StreetAddress?: string | null,
      PhoneNo?: string | null,
      isDelete?: string | null,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
    } | null,
    relTerminalGroup?:  {
      __typename: "TerminalGroup",
      Id: string,
      MemberKind: string,
      Name: string,
      CompanyId: string,
      ShopId?: string | null,
      Members?:  {
        __typename: "TerminalsGroupMember",
        Terminals?:  Array< {
          __typename: "TerminalSummary",
          Id: string,
          Kind: TerminalKind,
        } | null > | null,
      } | null,
      RelGroups?: Array< string | null > | null,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      terminals?:  {
        __typename: "ModelTerminalConnection",
        items?:  Array< {
          __typename: "Terminal",
          Id: string,
          Kind: TerminalKind,
          CompanyId: string,
          ShopId: string,
          GroupId: string,
          AffiliatedGroups:  {
            __typename: "AffiliatedGroup",
            Principal: string,
            Secondary?: string | null,
          },
          isDelete?: string | null,
          DisplayName: string,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          shop?:  {
            __typename: "Shop",
            Id: string,
            CompanyId: string,
            Name: string,
            DisplayName: string,
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
            StreetAddress?: string | null,
            PhoneNo?: string | null,
            isDelete?: string | null,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
          } | null,
          relTerminalGroup?:  {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      relUserGroups?:  {
        __typename: "ModelUserGroupConnection",
        items?:  Array< {
          __typename: "UserGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members:  {
            __typename: "UsersGroupMember",
            Users?: Array< string | null > | null,
          },
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          users?:  {
            __typename: "ModelUserConnection",
            items?:  Array< {
              __typename: "User",
              Id: string,
              Email: string,
              CompanyId: string,
              ShopId: string,
              GroupId?: string | null,
              isDelete?: string | null,
              CognitoGroup: CognitoGroup,
              MaintenanceCardId?: string | null,
              LastLoginDate?: number | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relTerminalGroups?:  {
            __typename: "ModelTerminalGroupConnection",
            items?:  Array< {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateTerminalMutationVariables = {
  input: UpdateTerminalInput,
  condition?: ModelTerminalConditionInput | null,
};

export type UpdateTerminalMutation = {
  updateTerminal?:  {
    __typename: "Terminal",
    Id: string,
    Kind: TerminalKind,
    CompanyId: string,
    ShopId: string,
    GroupId: string,
    AffiliatedGroups:  {
      __typename: "AffiliatedGroup",
      Principal: string,
      Secondary?: string | null,
    },
    isDelete?: string | null,
    DisplayName: string,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    shop?:  {
      __typename: "Shop",
      Id: string,
      CompanyId: string,
      Name: string,
      DisplayName: string,
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
      StreetAddress?: string | null,
      PhoneNo?: string | null,
      isDelete?: string | null,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
    } | null,
    relTerminalGroup?:  {
      __typename: "TerminalGroup",
      Id: string,
      MemberKind: string,
      Name: string,
      CompanyId: string,
      ShopId?: string | null,
      Members?:  {
        __typename: "TerminalsGroupMember",
        Terminals?:  Array< {
          __typename: "TerminalSummary",
          Id: string,
          Kind: TerminalKind,
        } | null > | null,
      } | null,
      RelGroups?: Array< string | null > | null,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      terminals?:  {
        __typename: "ModelTerminalConnection",
        items?:  Array< {
          __typename: "Terminal",
          Id: string,
          Kind: TerminalKind,
          CompanyId: string,
          ShopId: string,
          GroupId: string,
          AffiliatedGroups:  {
            __typename: "AffiliatedGroup",
            Principal: string,
            Secondary?: string | null,
          },
          isDelete?: string | null,
          DisplayName: string,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          shop?:  {
            __typename: "Shop",
            Id: string,
            CompanyId: string,
            Name: string,
            DisplayName: string,
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
            StreetAddress?: string | null,
            PhoneNo?: string | null,
            isDelete?: string | null,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
          } | null,
          relTerminalGroup?:  {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      relUserGroups?:  {
        __typename: "ModelUserGroupConnection",
        items?:  Array< {
          __typename: "UserGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members:  {
            __typename: "UsersGroupMember",
            Users?: Array< string | null > | null,
          },
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          users?:  {
            __typename: "ModelUserConnection",
            items?:  Array< {
              __typename: "User",
              Id: string,
              Email: string,
              CompanyId: string,
              ShopId: string,
              GroupId?: string | null,
              isDelete?: string | null,
              CognitoGroup: CognitoGroup,
              MaintenanceCardId?: string | null,
              LastLoginDate?: number | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relTerminalGroups?:  {
            __typename: "ModelTerminalGroupConnection",
            items?:  Array< {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteTerminalMutationVariables = {
  input: DeleteTerminalInput,
  condition?: ModelTerminalConditionInput | null,
};

export type DeleteTerminalMutation = {
  deleteTerminal?:  {
    __typename: "Terminal",
    Id: string,
    Kind: TerminalKind,
    CompanyId: string,
    ShopId: string,
    GroupId: string,
    AffiliatedGroups:  {
      __typename: "AffiliatedGroup",
      Principal: string,
      Secondary?: string | null,
    },
    isDelete?: string | null,
    DisplayName: string,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    shop?:  {
      __typename: "Shop",
      Id: string,
      CompanyId: string,
      Name: string,
      DisplayName: string,
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
      StreetAddress?: string | null,
      PhoneNo?: string | null,
      isDelete?: string | null,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
    } | null,
    relTerminalGroup?:  {
      __typename: "TerminalGroup",
      Id: string,
      MemberKind: string,
      Name: string,
      CompanyId: string,
      ShopId?: string | null,
      Members?:  {
        __typename: "TerminalsGroupMember",
        Terminals?:  Array< {
          __typename: "TerminalSummary",
          Id: string,
          Kind: TerminalKind,
        } | null > | null,
      } | null,
      RelGroups?: Array< string | null > | null,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      terminals?:  {
        __typename: "ModelTerminalConnection",
        items?:  Array< {
          __typename: "Terminal",
          Id: string,
          Kind: TerminalKind,
          CompanyId: string,
          ShopId: string,
          GroupId: string,
          AffiliatedGroups:  {
            __typename: "AffiliatedGroup",
            Principal: string,
            Secondary?: string | null,
          },
          isDelete?: string | null,
          DisplayName: string,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          shop?:  {
            __typename: "Shop",
            Id: string,
            CompanyId: string,
            Name: string,
            DisplayName: string,
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
            StreetAddress?: string | null,
            PhoneNo?: string | null,
            isDelete?: string | null,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
          } | null,
          relTerminalGroup?:  {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      relUserGroups?:  {
        __typename: "ModelUserGroupConnection",
        items?:  Array< {
          __typename: "UserGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members:  {
            __typename: "UsersGroupMember",
            Users?: Array< string | null > | null,
          },
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          users?:  {
            __typename: "ModelUserConnection",
            items?:  Array< {
              __typename: "User",
              Id: string,
              Email: string,
              CompanyId: string,
              ShopId: string,
              GroupId?: string | null,
              isDelete?: string | null,
              CognitoGroup: CognitoGroup,
              MaintenanceCardId?: string | null,
              LastLoginDate?: number | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relTerminalGroups?:  {
            __typename: "ModelTerminalGroupConnection",
            items?:  Array< {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateSystemOperationHistoriesMutationVariables = {
  input: CreateSystemOperationHistoriesInput,
};

export type CreateSystemOperationHistoriesMutation = {
  createSystemOperationHistories?:  {
    __typename: "SystemOperationHistories",
    UserId: string,
    UserMail: string,
    TimestampMs: number,
    ViewName: string,
    ViewId: string,
    OperationName: string,
    OperationId: string,
    Detail?: string | null,
    CreatedAt: number,
  } | null,
};

export type CreateSystemPlannedMaintenanceSchedulesMutationVariables = {
  input: CreateSystemPlannedMaintenanceSchedules,
};

export type CreateSystemPlannedMaintenanceSchedulesMutation = {
  createSystemPlannedMaintenanceSchedules?:  {
    __typename: "SystemPlannedMaintenanceSchedules",
    ScheduleId: string,
    StartTimestampMs?: number | null,
    EndTimestampMs?: number | null,
    Message: string,
    UserId: string,
    UserName: string,
    CreatedAt: number,
  } | null,
};

export type UpdateSystemPlannedMaintenanceSchedulesMutationVariables = {
  input: UpdateSystemPlannedMaintenanceSchedules,
};

export type UpdateSystemPlannedMaintenanceSchedulesMutation = {
  updateSystemPlannedMaintenanceSchedules?:  {
    __typename: "SystemPlannedMaintenanceSchedules",
    ScheduleId: string,
    StartTimestampMs?: number | null,
    EndTimestampMs?: number | null,
    Message: string,
    UserId: string,
    UserName: string,
    CreatedAt: number,
  } | null,
};

export type GetUserQueryVariables = {
  Id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    Id: string,
    Email: string,
    CompanyId: string,
    ShopId: string,
    GroupId?: string | null,
    AffiliatedGroups?:  {
      __typename: "AffiliatedGroup",
      Principal: string,
      Secondary?: string | null,
    } | null,
    isDelete?: string | null,
    CognitoGroup: CognitoGroup,
    company:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    },
    shop?:  {
      __typename: "Shop",
      Id: string,
      CompanyId: string,
      Name: string,
      DisplayName: string,
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
      StreetAddress?: string | null,
      PhoneNo?: string | null,
      isDelete?: string | null,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
    } | null,
    MaintenanceCardId?: string | null,
    LastLoginDate?: number | null,
    CreatedAt: number,
    UpdatedAt: number,
    relUserGroup?:  {
      __typename: "UserGroup",
      Id: string,
      MemberKind: string,
      Name: string,
      CompanyId: string,
      ShopId?: string | null,
      Members:  {
        __typename: "UsersGroupMember",
        Users?: Array< string | null > | null,
      },
      RelGroups?: Array< string | null > | null,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items?:  Array< {
          __typename: "User",
          Id: string,
          Email: string,
          CompanyId: string,
          ShopId: string,
          GroupId?: string | null,
          AffiliatedGroups?:  {
            __typename: "AffiliatedGroup",
            Principal: string,
            Secondary?: string | null,
          } | null,
          isDelete?: string | null,
          CognitoGroup: CognitoGroup,
          company:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          },
          shop?:  {
            __typename: "Shop",
            Id: string,
            CompanyId: string,
            Name: string,
            DisplayName: string,
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
            StreetAddress?: string | null,
            PhoneNo?: string | null,
            isDelete?: string | null,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
          } | null,
          MaintenanceCardId?: string | null,
          LastLoginDate?: number | null,
          CreatedAt: number,
          UpdatedAt: number,
          relUserGroup?:  {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      relTerminalGroups?:  {
        __typename: "ModelTerminalGroupConnection",
        items?:  Array< {
          __typename: "TerminalGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members?:  {
            __typename: "TerminalsGroupMember",
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
          } | null,
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          terminals?:  {
            __typename: "ModelTerminalConnection",
            items?:  Array< {
              __typename: "Terminal",
              Id: string,
              Kind: TerminalKind,
              CompanyId: string,
              ShopId: string,
              GroupId: string,
              isDelete?: string | null,
              DisplayName: string,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relUserGroups?:  {
            __typename: "ModelUserGroupConnection",
            items?:  Array< {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items?:  Array< {
      __typename: "User",
      Id: string,
      Email: string,
      CompanyId: string,
      ShopId: string,
      GroupId?: string | null,
      AffiliatedGroups?:  {
        __typename: "AffiliatedGroup",
        Principal: string,
        Secondary?: string | null,
      } | null,
      isDelete?: string | null,
      CognitoGroup: CognitoGroup,
      company:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      },
      shop?:  {
        __typename: "Shop",
        Id: string,
        CompanyId: string,
        Name: string,
        DisplayName: string,
        Terminals?:  Array< {
          __typename: "TerminalSummary",
          Id: string,
          Kind: TerminalKind,
        } | null > | null,
        StreetAddress?: string | null,
        PhoneNo?: string | null,
        isDelete?: string | null,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
      } | null,
      MaintenanceCardId?: string | null,
      LastLoginDate?: number | null,
      CreatedAt: number,
      UpdatedAt: number,
      relUserGroup?:  {
        __typename: "UserGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members:  {
          __typename: "UsersGroupMember",
          Users?: Array< string | null > | null,
        },
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          items?:  Array< {
            __typename: "User",
            Id: string,
            Email: string,
            CompanyId: string,
            ShopId: string,
            GroupId?: string | null,
            AffiliatedGroups?:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            } | null,
            isDelete?: string | null,
            CognitoGroup: CognitoGroup,
            company:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            },
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            MaintenanceCardId?: string | null,
            LastLoginDate?: number | null,
            CreatedAt: number,
            UpdatedAt: number,
            relUserGroup?:  {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relTerminalGroups?:  {
          __typename: "ModelTerminalGroupConnection",
          items?:  Array< {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyQueryVariables = {
  Id: string,
};

export type GetCompanyQuery = {
  getCompany?:  {
    __typename: "Company",
    Id: string,
    Name: string,
    DisplayName: string,
    Shops?: Array< string > | null,
    GeneralConfigs:  {
      __typename: "GeneralConfigs",
      TaxCalcMathod: number,
    },
    UseStatus:  {
      __typename: "CompanyUseStatus",
      TopUp: boolean,
      TicketKiosk: boolean,
    },
    SubSystemConfigs:  {
      __typename: "CompanySubSystemConfig",
      Common:  {
        __typename: "CommonConfig",
        Shiagel:  {
          __typename: "ShiagelConfig",
          AuthCode: string,
          Use: boolean,
        },
      },
      TicketKiosk?:  {
        __typename: "TicketKioskConfig",
        Fingo?:  {
          __typename: "FingoConfig",
          Use: boolean,
        } | null,
        Coupon?:  {
          __typename: "CouponConfig",
          CalcMethod: number,
        } | null,
      } | null,
    },
    isDelete: string,
    CreatedAt: number,
    UpdatedAt: number,
    owner: string,
  } | null,
};

export type ListCompanysQueryVariables = {
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompanysQuery = {
  listCompanys?:  {
    __typename: "ModelCompanyConnection",
    items?:  Array< {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetShopQueryVariables = {
  Id: string,
};

export type GetShopQuery = {
  getShop?:  {
    __typename: "Shop",
    Id: string,
    CompanyId: string,
    Name: string,
    DisplayName: string,
    Terminals?:  Array< {
      __typename: "TerminalSummary",
      Id: string,
      Kind: TerminalKind,
    } | null > | null,
    StreetAddress?: string | null,
    PhoneNo?: string | null,
    isDelete?: string | null,
    CreatedAt: number,
    UpdatedAt: number,
    owner: string,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
  } | null,
};

export type ListShopsQueryVariables = {
  filter?: ModelShopFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListShopsQuery = {
  listShops?:  {
    __typename: "ModelShopConnection",
    items?:  Array< {
      __typename: "Shop",
      Id: string,
      CompanyId: string,
      Name: string,
      DisplayName: string,
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
      StreetAddress?: string | null,
      PhoneNo?: string | null,
      isDelete?: string | null,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserGroupQueryVariables = {
  Id: string,
};

export type GetUserGroupQuery = {
  getUserGroup?:  {
    __typename: "UserGroup",
    Id: string,
    MemberKind: string,
    Name: string,
    CompanyId: string,
    ShopId?: string | null,
    Members:  {
      __typename: "UsersGroupMember",
      Users?: Array< string | null > | null,
    },
    RelGroups?: Array< string | null > | null,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items?:  Array< {
        __typename: "User",
        Id: string,
        Email: string,
        CompanyId: string,
        ShopId: string,
        GroupId?: string | null,
        AffiliatedGroups?:  {
          __typename: "AffiliatedGroup",
          Principal: string,
          Secondary?: string | null,
        } | null,
        isDelete?: string | null,
        CognitoGroup: CognitoGroup,
        company:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        },
        shop?:  {
          __typename: "Shop",
          Id: string,
          CompanyId: string,
          Name: string,
          DisplayName: string,
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
          StreetAddress?: string | null,
          PhoneNo?: string | null,
          isDelete?: string | null,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
        } | null,
        MaintenanceCardId?: string | null,
        LastLoginDate?: number | null,
        CreatedAt: number,
        UpdatedAt: number,
        relUserGroup?:  {
          __typename: "UserGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members:  {
            __typename: "UsersGroupMember",
            Users?: Array< string | null > | null,
          },
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          users?:  {
            __typename: "ModelUserConnection",
            items?:  Array< {
              __typename: "User",
              Id: string,
              Email: string,
              CompanyId: string,
              ShopId: string,
              GroupId?: string | null,
              isDelete?: string | null,
              CognitoGroup: CognitoGroup,
              MaintenanceCardId?: string | null,
              LastLoginDate?: number | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relTerminalGroups?:  {
            __typename: "ModelTerminalGroupConnection",
            items?:  Array< {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    relTerminalGroups?:  {
      __typename: "ModelTerminalGroupConnection",
      items?:  Array< {
        __typename: "TerminalGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members?:  {
          __typename: "TerminalsGroupMember",
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
        } | null,
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        terminals?:  {
          __typename: "ModelTerminalConnection",
          items?:  Array< {
            __typename: "Terminal",
            Id: string,
            Kind: TerminalKind,
            CompanyId: string,
            ShopId: string,
            GroupId: string,
            AffiliatedGroups:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            },
            isDelete?: string | null,
            DisplayName: string,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            relTerminalGroup?:  {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relUserGroups?:  {
          __typename: "ModelUserGroupConnection",
          items?:  Array< {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListUserGroupsQueryVariables = {
  filter?: ModelUserGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserGroupsQuery = {
  listUserGroups?:  {
    __typename: "ModelUserGroupConnection",
    items?:  Array< {
      __typename: "UserGroup",
      Id: string,
      MemberKind: string,
      Name: string,
      CompanyId: string,
      ShopId?: string | null,
      Members:  {
        __typename: "UsersGroupMember",
        Users?: Array< string | null > | null,
      },
      RelGroups?: Array< string | null > | null,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items?:  Array< {
          __typename: "User",
          Id: string,
          Email: string,
          CompanyId: string,
          ShopId: string,
          GroupId?: string | null,
          AffiliatedGroups?:  {
            __typename: "AffiliatedGroup",
            Principal: string,
            Secondary?: string | null,
          } | null,
          isDelete?: string | null,
          CognitoGroup: CognitoGroup,
          company:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          },
          shop?:  {
            __typename: "Shop",
            Id: string,
            CompanyId: string,
            Name: string,
            DisplayName: string,
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
            StreetAddress?: string | null,
            PhoneNo?: string | null,
            isDelete?: string | null,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
          } | null,
          MaintenanceCardId?: string | null,
          LastLoginDate?: number | null,
          CreatedAt: number,
          UpdatedAt: number,
          relUserGroup?:  {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      relTerminalGroups?:  {
        __typename: "ModelTerminalGroupConnection",
        items?:  Array< {
          __typename: "TerminalGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members?:  {
            __typename: "TerminalsGroupMember",
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
          } | null,
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          terminals?:  {
            __typename: "ModelTerminalConnection",
            items?:  Array< {
              __typename: "Terminal",
              Id: string,
              Kind: TerminalKind,
              CompanyId: string,
              ShopId: string,
              GroupId: string,
              isDelete?: string | null,
              DisplayName: string,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relUserGroups?:  {
            __typename: "ModelUserGroupConnection",
            items?:  Array< {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTerminalGroupQueryVariables = {
  Id: string,
};

export type GetTerminalGroupQuery = {
  getTerminalGroup?:  {
    __typename: "TerminalGroup",
    Id: string,
    MemberKind: string,
    Name: string,
    CompanyId: string,
    ShopId?: string | null,
    Members?:  {
      __typename: "TerminalsGroupMember",
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
    } | null,
    RelGroups?: Array< string | null > | null,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    terminals?:  {
      __typename: "ModelTerminalConnection",
      items?:  Array< {
        __typename: "Terminal",
        Id: string,
        Kind: TerminalKind,
        CompanyId: string,
        ShopId: string,
        GroupId: string,
        AffiliatedGroups:  {
          __typename: "AffiliatedGroup",
          Principal: string,
          Secondary?: string | null,
        },
        isDelete?: string | null,
        DisplayName: string,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        shop?:  {
          __typename: "Shop",
          Id: string,
          CompanyId: string,
          Name: string,
          DisplayName: string,
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
          StreetAddress?: string | null,
          PhoneNo?: string | null,
          isDelete?: string | null,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
        } | null,
        relTerminalGroup?:  {
          __typename: "TerminalGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members?:  {
            __typename: "TerminalsGroupMember",
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
          } | null,
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          terminals?:  {
            __typename: "ModelTerminalConnection",
            items?:  Array< {
              __typename: "Terminal",
              Id: string,
              Kind: TerminalKind,
              CompanyId: string,
              ShopId: string,
              GroupId: string,
              isDelete?: string | null,
              DisplayName: string,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relUserGroups?:  {
            __typename: "ModelUserGroupConnection",
            items?:  Array< {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    relUserGroups?:  {
      __typename: "ModelUserGroupConnection",
      items?:  Array< {
        __typename: "UserGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members:  {
          __typename: "UsersGroupMember",
          Users?: Array< string | null > | null,
        },
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          items?:  Array< {
            __typename: "User",
            Id: string,
            Email: string,
            CompanyId: string,
            ShopId: string,
            GroupId?: string | null,
            AffiliatedGroups?:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            } | null,
            isDelete?: string | null,
            CognitoGroup: CognitoGroup,
            company:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            },
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            MaintenanceCardId?: string | null,
            LastLoginDate?: number | null,
            CreatedAt: number,
            UpdatedAt: number,
            relUserGroup?:  {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relTerminalGroups?:  {
          __typename: "ModelTerminalGroupConnection",
          items?:  Array< {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListTerminalGroupsQueryVariables = {
  filter?: ModelTerminalGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTerminalGroupsQuery = {
  listTerminalGroups?:  {
    __typename: "ModelTerminalGroupConnection",
    items?:  Array< {
      __typename: "TerminalGroup",
      Id: string,
      MemberKind: string,
      Name: string,
      CompanyId: string,
      ShopId?: string | null,
      Members?:  {
        __typename: "TerminalsGroupMember",
        Terminals?:  Array< {
          __typename: "TerminalSummary",
          Id: string,
          Kind: TerminalKind,
        } | null > | null,
      } | null,
      RelGroups?: Array< string | null > | null,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      terminals?:  {
        __typename: "ModelTerminalConnection",
        items?:  Array< {
          __typename: "Terminal",
          Id: string,
          Kind: TerminalKind,
          CompanyId: string,
          ShopId: string,
          GroupId: string,
          AffiliatedGroups:  {
            __typename: "AffiliatedGroup",
            Principal: string,
            Secondary?: string | null,
          },
          isDelete?: string | null,
          DisplayName: string,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          shop?:  {
            __typename: "Shop",
            Id: string,
            CompanyId: string,
            Name: string,
            DisplayName: string,
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
            StreetAddress?: string | null,
            PhoneNo?: string | null,
            isDelete?: string | null,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
          } | null,
          relTerminalGroup?:  {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      relUserGroups?:  {
        __typename: "ModelUserGroupConnection",
        items?:  Array< {
          __typename: "UserGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members:  {
            __typename: "UsersGroupMember",
            Users?: Array< string | null > | null,
          },
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          users?:  {
            __typename: "ModelUserConnection",
            items?:  Array< {
              __typename: "User",
              Id: string,
              Email: string,
              CompanyId: string,
              ShopId: string,
              GroupId?: string | null,
              isDelete?: string | null,
              CognitoGroup: CognitoGroup,
              MaintenanceCardId?: string | null,
              LastLoginDate?: number | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relTerminalGroups?:  {
            __typename: "ModelTerminalGroupConnection",
            items?:  Array< {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTerminalQueryVariables = {
  Id: string,
  Kind: TerminalKind,
};

export type GetTerminalQuery = {
  getTerminal?:  {
    __typename: "Terminal",
    Id: string,
    Kind: TerminalKind,
    CompanyId: string,
    ShopId: string,
    GroupId: string,
    AffiliatedGroups:  {
      __typename: "AffiliatedGroup",
      Principal: string,
      Secondary?: string | null,
    },
    isDelete?: string | null,
    DisplayName: string,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    shop?:  {
      __typename: "Shop",
      Id: string,
      CompanyId: string,
      Name: string,
      DisplayName: string,
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
      StreetAddress?: string | null,
      PhoneNo?: string | null,
      isDelete?: string | null,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
    } | null,
    relTerminalGroup?:  {
      __typename: "TerminalGroup",
      Id: string,
      MemberKind: string,
      Name: string,
      CompanyId: string,
      ShopId?: string | null,
      Members?:  {
        __typename: "TerminalsGroupMember",
        Terminals?:  Array< {
          __typename: "TerminalSummary",
          Id: string,
          Kind: TerminalKind,
        } | null > | null,
      } | null,
      RelGroups?: Array< string | null > | null,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      terminals?:  {
        __typename: "ModelTerminalConnection",
        items?:  Array< {
          __typename: "Terminal",
          Id: string,
          Kind: TerminalKind,
          CompanyId: string,
          ShopId: string,
          GroupId: string,
          AffiliatedGroups:  {
            __typename: "AffiliatedGroup",
            Principal: string,
            Secondary?: string | null,
          },
          isDelete?: string | null,
          DisplayName: string,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          shop?:  {
            __typename: "Shop",
            Id: string,
            CompanyId: string,
            Name: string,
            DisplayName: string,
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
            StreetAddress?: string | null,
            PhoneNo?: string | null,
            isDelete?: string | null,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
          } | null,
          relTerminalGroup?:  {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      relUserGroups?:  {
        __typename: "ModelUserGroupConnection",
        items?:  Array< {
          __typename: "UserGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members:  {
            __typename: "UsersGroupMember",
            Users?: Array< string | null > | null,
          },
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          users?:  {
            __typename: "ModelUserConnection",
            items?:  Array< {
              __typename: "User",
              Id: string,
              Email: string,
              CompanyId: string,
              ShopId: string,
              GroupId?: string | null,
              isDelete?: string | null,
              CognitoGroup: CognitoGroup,
              MaintenanceCardId?: string | null,
              LastLoginDate?: number | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relTerminalGroups?:  {
            __typename: "ModelTerminalGroupConnection",
            items?:  Array< {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListTerminalsQueryVariables = {
  Id?: string | null,
  Kind?: ModelStringKeyConditionInput | null,
  filter?: ModelTerminalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTerminalsQuery = {
  listTerminals?:  {
    __typename: "ModelTerminalConnection",
    items?:  Array< {
      __typename: "Terminal",
      Id: string,
      Kind: TerminalKind,
      CompanyId: string,
      ShopId: string,
      GroupId: string,
      AffiliatedGroups:  {
        __typename: "AffiliatedGroup",
        Principal: string,
        Secondary?: string | null,
      },
      isDelete?: string | null,
      DisplayName: string,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      shop?:  {
        __typename: "Shop",
        Id: string,
        CompanyId: string,
        Name: string,
        DisplayName: string,
        Terminals?:  Array< {
          __typename: "TerminalSummary",
          Id: string,
          Kind: TerminalKind,
        } | null > | null,
        StreetAddress?: string | null,
        PhoneNo?: string | null,
        isDelete?: string | null,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
      } | null,
      relTerminalGroup?:  {
        __typename: "TerminalGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members?:  {
          __typename: "TerminalsGroupMember",
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
        } | null,
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        terminals?:  {
          __typename: "ModelTerminalConnection",
          items?:  Array< {
            __typename: "Terminal",
            Id: string,
            Kind: TerminalKind,
            CompanyId: string,
            ShopId: string,
            GroupId: string,
            AffiliatedGroups:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            },
            isDelete?: string | null,
            DisplayName: string,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            relTerminalGroup?:  {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relUserGroups?:  {
          __typename: "ModelUserGroupConnection",
          items?:  Array< {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type UserByGroupQueryVariables = {
  GroupId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByGroupQuery = {
  userByGroup?:  {
    __typename: "ModelUserConnection",
    items?:  Array< {
      __typename: "User",
      Id: string,
      Email: string,
      CompanyId: string,
      ShopId: string,
      GroupId?: string | null,
      AffiliatedGroups?:  {
        __typename: "AffiliatedGroup",
        Principal: string,
        Secondary?: string | null,
      } | null,
      isDelete?: string | null,
      CognitoGroup: CognitoGroup,
      company:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      },
      shop?:  {
        __typename: "Shop",
        Id: string,
        CompanyId: string,
        Name: string,
        DisplayName: string,
        Terminals?:  Array< {
          __typename: "TerminalSummary",
          Id: string,
          Kind: TerminalKind,
        } | null > | null,
        StreetAddress?: string | null,
        PhoneNo?: string | null,
        isDelete?: string | null,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
      } | null,
      MaintenanceCardId?: string | null,
      LastLoginDate?: number | null,
      CreatedAt: number,
      UpdatedAt: number,
      relUserGroup?:  {
        __typename: "UserGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members:  {
          __typename: "UsersGroupMember",
          Users?: Array< string | null > | null,
        },
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          items?:  Array< {
            __typename: "User",
            Id: string,
            Email: string,
            CompanyId: string,
            ShopId: string,
            GroupId?: string | null,
            AffiliatedGroups?:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            } | null,
            isDelete?: string | null,
            CognitoGroup: CognitoGroup,
            company:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            },
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            MaintenanceCardId?: string | null,
            LastLoginDate?: number | null,
            CreatedAt: number,
            UpdatedAt: number,
            relUserGroup?:  {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relTerminalGroups?:  {
          __typename: "ModelTerminalGroupConnection",
          items?:  Array< {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type UserByGroupWithCompanyAndDeleteStatusQueryVariables = {
  GroupId?: string | null,
  companyIdIsDelete?: ModelUserUserByGroupWithCompanyAndDeleteStatusCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByGroupWithCompanyAndDeleteStatusQuery = {
  userByGroupWithCompanyAndDeleteStatus?:  {
    __typename: "ModelUserConnection",
    items?:  Array< {
      __typename: "User",
      Id: string,
      Email: string,
      CompanyId: string,
      ShopId: string,
      GroupId?: string | null,
      AffiliatedGroups?:  {
        __typename: "AffiliatedGroup",
        Principal: string,
        Secondary?: string | null,
      } | null,
      isDelete?: string | null,
      CognitoGroup: CognitoGroup,
      company:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      },
      shop?:  {
        __typename: "Shop",
        Id: string,
        CompanyId: string,
        Name: string,
        DisplayName: string,
        Terminals?:  Array< {
          __typename: "TerminalSummary",
          Id: string,
          Kind: TerminalKind,
        } | null > | null,
        StreetAddress?: string | null,
        PhoneNo?: string | null,
        isDelete?: string | null,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
      } | null,
      MaintenanceCardId?: string | null,
      LastLoginDate?: number | null,
      CreatedAt: number,
      UpdatedAt: number,
      relUserGroup?:  {
        __typename: "UserGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members:  {
          __typename: "UsersGroupMember",
          Users?: Array< string | null > | null,
        },
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          items?:  Array< {
            __typename: "User",
            Id: string,
            Email: string,
            CompanyId: string,
            ShopId: string,
            GroupId?: string | null,
            AffiliatedGroups?:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            } | null,
            isDelete?: string | null,
            CognitoGroup: CognitoGroup,
            company:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            },
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            MaintenanceCardId?: string | null,
            LastLoginDate?: number | null,
            CreatedAt: number,
            UpdatedAt: number,
            relUserGroup?:  {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relTerminalGroups?:  {
          __typename: "ModelTerminalGroupConnection",
          items?:  Array< {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type UserByDeleteStatusWithCompanyQueryVariables = {
  isDelete?: string | null,
  CompanyId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByDeleteStatusWithCompanyQuery = {
  userByDeleteStatusWithCompany?:  {
    __typename: "ModelUserConnection",
    items?:  Array< {
      __typename: "User",
      Id: string,
      Email: string,
      CompanyId: string,
      ShopId: string,
      GroupId?: string | null,
      AffiliatedGroups?:  {
        __typename: "AffiliatedGroup",
        Principal: string,
        Secondary?: string | null,
      } | null,
      isDelete?: string | null,
      CognitoGroup: CognitoGroup,
      company:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      },
      shop?:  {
        __typename: "Shop",
        Id: string,
        CompanyId: string,
        Name: string,
        DisplayName: string,
        Terminals?:  Array< {
          __typename: "TerminalSummary",
          Id: string,
          Kind: TerminalKind,
        } | null > | null,
        StreetAddress?: string | null,
        PhoneNo?: string | null,
        isDelete?: string | null,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
      } | null,
      MaintenanceCardId?: string | null,
      LastLoginDate?: number | null,
      CreatedAt: number,
      UpdatedAt: number,
      relUserGroup?:  {
        __typename: "UserGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members:  {
          __typename: "UsersGroupMember",
          Users?: Array< string | null > | null,
        },
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          items?:  Array< {
            __typename: "User",
            Id: string,
            Email: string,
            CompanyId: string,
            ShopId: string,
            GroupId?: string | null,
            AffiliatedGroups?:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            } | null,
            isDelete?: string | null,
            CognitoGroup: CognitoGroup,
            company:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            },
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            MaintenanceCardId?: string | null,
            LastLoginDate?: number | null,
            CreatedAt: number,
            UpdatedAt: number,
            relUserGroup?:  {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relTerminalGroups?:  {
          __typename: "ModelTerminalGroupConnection",
          items?:  Array< {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyByDeleteStatusQueryVariables = {
  isDelete?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCompanyByDeleteStatusQuery = {
  getCompanyByDeleteStatus?:  {
    __typename: "ModelCompanyConnection",
    items?:  Array< {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetShopByDeleteStatusQueryVariables = {
  isDelete?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelShopFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetShopByDeleteStatusQuery = {
  getShopByDeleteStatus?:  {
    __typename: "ModelShopConnection",
    items?:  Array< {
      __typename: "Shop",
      Id: string,
      CompanyId: string,
      Name: string,
      DisplayName: string,
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
      StreetAddress?: string | null,
      PhoneNo?: string | null,
      isDelete?: string | null,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type TerminalbyTerminalKindQueryVariables = {
  Kind?: TerminalKind | null,
  isDelete?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTerminalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TerminalbyTerminalKindQuery = {
  terminalbyTerminalKind?:  {
    __typename: "ModelTerminalConnection",
    items?:  Array< {
      __typename: "Terminal",
      Id: string,
      Kind: TerminalKind,
      CompanyId: string,
      ShopId: string,
      GroupId: string,
      AffiliatedGroups:  {
        __typename: "AffiliatedGroup",
        Principal: string,
        Secondary?: string | null,
      },
      isDelete?: string | null,
      DisplayName: string,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      shop?:  {
        __typename: "Shop",
        Id: string,
        CompanyId: string,
        Name: string,
        DisplayName: string,
        Terminals?:  Array< {
          __typename: "TerminalSummary",
          Id: string,
          Kind: TerminalKind,
        } | null > | null,
        StreetAddress?: string | null,
        PhoneNo?: string | null,
        isDelete?: string | null,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
      } | null,
      relTerminalGroup?:  {
        __typename: "TerminalGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members?:  {
          __typename: "TerminalsGroupMember",
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
        } | null,
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        terminals?:  {
          __typename: "ModelTerminalConnection",
          items?:  Array< {
            __typename: "Terminal",
            Id: string,
            Kind: TerminalKind,
            CompanyId: string,
            ShopId: string,
            GroupId: string,
            AffiliatedGroups:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            },
            isDelete?: string | null,
            DisplayName: string,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            relTerminalGroup?:  {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relUserGroups?:  {
          __typename: "ModelUserGroupConnection",
          items?:  Array< {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type TerminalByGroupQueryVariables = {
  GroupId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTerminalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TerminalByGroupQuery = {
  terminalByGroup?:  {
    __typename: "ModelTerminalConnection",
    items?:  Array< {
      __typename: "Terminal",
      Id: string,
      Kind: TerminalKind,
      CompanyId: string,
      ShopId: string,
      GroupId: string,
      AffiliatedGroups:  {
        __typename: "AffiliatedGroup",
        Principal: string,
        Secondary?: string | null,
      },
      isDelete?: string | null,
      DisplayName: string,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      shop?:  {
        __typename: "Shop",
        Id: string,
        CompanyId: string,
        Name: string,
        DisplayName: string,
        Terminals?:  Array< {
          __typename: "TerminalSummary",
          Id: string,
          Kind: TerminalKind,
        } | null > | null,
        StreetAddress?: string | null,
        PhoneNo?: string | null,
        isDelete?: string | null,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
      } | null,
      relTerminalGroup?:  {
        __typename: "TerminalGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members?:  {
          __typename: "TerminalsGroupMember",
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
        } | null,
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        terminals?:  {
          __typename: "ModelTerminalConnection",
          items?:  Array< {
            __typename: "Terminal",
            Id: string,
            Kind: TerminalKind,
            CompanyId: string,
            ShopId: string,
            GroupId: string,
            AffiliatedGroups:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            },
            isDelete?: string | null,
            DisplayName: string,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            relTerminalGroup?:  {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relUserGroups?:  {
          __typename: "ModelUserGroupConnection",
          items?:  Array< {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type TerminalByIdQueryVariables = {
  Id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTerminalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TerminalByIdQuery = {
  terminalById?:  {
    __typename: "ModelTerminalConnection",
    items?:  Array< {
      __typename: "Terminal",
      Id: string,
      Kind: TerminalKind,
      CompanyId: string,
      ShopId: string,
      GroupId: string,
      AffiliatedGroups:  {
        __typename: "AffiliatedGroup",
        Principal: string,
        Secondary?: string | null,
      },
      isDelete?: string | null,
      DisplayName: string,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      shop?:  {
        __typename: "Shop",
        Id: string,
        CompanyId: string,
        Name: string,
        DisplayName: string,
        Terminals?:  Array< {
          __typename: "TerminalSummary",
          Id: string,
          Kind: TerminalKind,
        } | null > | null,
        StreetAddress?: string | null,
        PhoneNo?: string | null,
        isDelete?: string | null,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
      } | null,
      relTerminalGroup?:  {
        __typename: "TerminalGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members?:  {
          __typename: "TerminalsGroupMember",
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
        } | null,
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        terminals?:  {
          __typename: "ModelTerminalConnection",
          items?:  Array< {
            __typename: "Terminal",
            Id: string,
            Kind: TerminalKind,
            CompanyId: string,
            ShopId: string,
            GroupId: string,
            AffiliatedGroups:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            },
            isDelete?: string | null,
            DisplayName: string,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            relTerminalGroup?:  {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relUserGroups?:  {
          __typename: "ModelUserGroupConnection",
          items?:  Array< {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSystemOperationHistoriesQueryVariables = {
  filter?: SystemOperationHistoriesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSystemOperationHistoriesQuery = {
  listSystemOperationHistories?:  {
    __typename: "SystemOperationHistoriesConnection",
    items?:  Array< {
      __typename: "SystemOperationHistories",
      UserId: string,
      UserMail: string,
      TimestampMs: number,
      ViewName: string,
      ViewId: string,
      OperationName: string,
      OperationId: string,
      Detail?: string | null,
      CreatedAt: number,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSystemPlannedMaintenanceSchedulesByIdQueryVariables = {
  ScheduleId: string,
};

export type GetSystemPlannedMaintenanceSchedulesByIdQuery = {
  getSystemPlannedMaintenanceSchedulesById?:  {
    __typename: "SystemPlannedMaintenanceSchedules",
    ScheduleId: string,
    StartTimestampMs?: number | null,
    EndTimestampMs?: number | null,
    Message: string,
    UserId: string,
    UserName: string,
    CreatedAt: number,
  } | null,
};

export type ListSystemPlannedMaintenanceSchedulesQueryVariables = {
  filter?: SystemPlannedMaintenanceSchedulesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSystemPlannedMaintenanceSchedulesQuery = {
  listSystemPlannedMaintenanceSchedules?:  {
    __typename: "SystemPlannedMaintenanceSchedulesConnection",
    items?:  Array< {
      __typename: "SystemPlannedMaintenanceSchedules",
      ScheduleId: string,
      StartTimestampMs?: number | null,
      EndTimestampMs?: number | null,
      Message: string,
      UserId: string,
      UserName: string,
      CreatedAt: number,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    Id: string,
    Email: string,
    CompanyId: string,
    ShopId: string,
    GroupId?: string | null,
    AffiliatedGroups?:  {
      __typename: "AffiliatedGroup",
      Principal: string,
      Secondary?: string | null,
    } | null,
    isDelete?: string | null,
    CognitoGroup: CognitoGroup,
    company:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    },
    shop?:  {
      __typename: "Shop",
      Id: string,
      CompanyId: string,
      Name: string,
      DisplayName: string,
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
      StreetAddress?: string | null,
      PhoneNo?: string | null,
      isDelete?: string | null,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
    } | null,
    MaintenanceCardId?: string | null,
    LastLoginDate?: number | null,
    CreatedAt: number,
    UpdatedAt: number,
    relUserGroup?:  {
      __typename: "UserGroup",
      Id: string,
      MemberKind: string,
      Name: string,
      CompanyId: string,
      ShopId?: string | null,
      Members:  {
        __typename: "UsersGroupMember",
        Users?: Array< string | null > | null,
      },
      RelGroups?: Array< string | null > | null,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items?:  Array< {
          __typename: "User",
          Id: string,
          Email: string,
          CompanyId: string,
          ShopId: string,
          GroupId?: string | null,
          AffiliatedGroups?:  {
            __typename: "AffiliatedGroup",
            Principal: string,
            Secondary?: string | null,
          } | null,
          isDelete?: string | null,
          CognitoGroup: CognitoGroup,
          company:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          },
          shop?:  {
            __typename: "Shop",
            Id: string,
            CompanyId: string,
            Name: string,
            DisplayName: string,
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
            StreetAddress?: string | null,
            PhoneNo?: string | null,
            isDelete?: string | null,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
          } | null,
          MaintenanceCardId?: string | null,
          LastLoginDate?: number | null,
          CreatedAt: number,
          UpdatedAt: number,
          relUserGroup?:  {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      relTerminalGroups?:  {
        __typename: "ModelTerminalGroupConnection",
        items?:  Array< {
          __typename: "TerminalGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members?:  {
            __typename: "TerminalsGroupMember",
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
          } | null,
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          terminals?:  {
            __typename: "ModelTerminalConnection",
            items?:  Array< {
              __typename: "Terminal",
              Id: string,
              Kind: TerminalKind,
              CompanyId: string,
              ShopId: string,
              GroupId: string,
              isDelete?: string | null,
              DisplayName: string,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relUserGroups?:  {
            __typename: "ModelUserGroupConnection",
            items?:  Array< {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    Id: string,
    Email: string,
    CompanyId: string,
    ShopId: string,
    GroupId?: string | null,
    AffiliatedGroups?:  {
      __typename: "AffiliatedGroup",
      Principal: string,
      Secondary?: string | null,
    } | null,
    isDelete?: string | null,
    CognitoGroup: CognitoGroup,
    company:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    },
    shop?:  {
      __typename: "Shop",
      Id: string,
      CompanyId: string,
      Name: string,
      DisplayName: string,
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
      StreetAddress?: string | null,
      PhoneNo?: string | null,
      isDelete?: string | null,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
    } | null,
    MaintenanceCardId?: string | null,
    LastLoginDate?: number | null,
    CreatedAt: number,
    UpdatedAt: number,
    relUserGroup?:  {
      __typename: "UserGroup",
      Id: string,
      MemberKind: string,
      Name: string,
      CompanyId: string,
      ShopId?: string | null,
      Members:  {
        __typename: "UsersGroupMember",
        Users?: Array< string | null > | null,
      },
      RelGroups?: Array< string | null > | null,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items?:  Array< {
          __typename: "User",
          Id: string,
          Email: string,
          CompanyId: string,
          ShopId: string,
          GroupId?: string | null,
          AffiliatedGroups?:  {
            __typename: "AffiliatedGroup",
            Principal: string,
            Secondary?: string | null,
          } | null,
          isDelete?: string | null,
          CognitoGroup: CognitoGroup,
          company:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          },
          shop?:  {
            __typename: "Shop",
            Id: string,
            CompanyId: string,
            Name: string,
            DisplayName: string,
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
            StreetAddress?: string | null,
            PhoneNo?: string | null,
            isDelete?: string | null,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
          } | null,
          MaintenanceCardId?: string | null,
          LastLoginDate?: number | null,
          CreatedAt: number,
          UpdatedAt: number,
          relUserGroup?:  {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      relTerminalGroups?:  {
        __typename: "ModelTerminalGroupConnection",
        items?:  Array< {
          __typename: "TerminalGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members?:  {
            __typename: "TerminalsGroupMember",
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
          } | null,
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          terminals?:  {
            __typename: "ModelTerminalConnection",
            items?:  Array< {
              __typename: "Terminal",
              Id: string,
              Kind: TerminalKind,
              CompanyId: string,
              ShopId: string,
              GroupId: string,
              isDelete?: string | null,
              DisplayName: string,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relUserGroups?:  {
            __typename: "ModelUserGroupConnection",
            items?:  Array< {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    Id: string,
    Email: string,
    CompanyId: string,
    ShopId: string,
    GroupId?: string | null,
    AffiliatedGroups?:  {
      __typename: "AffiliatedGroup",
      Principal: string,
      Secondary?: string | null,
    } | null,
    isDelete?: string | null,
    CognitoGroup: CognitoGroup,
    company:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    },
    shop?:  {
      __typename: "Shop",
      Id: string,
      CompanyId: string,
      Name: string,
      DisplayName: string,
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
      StreetAddress?: string | null,
      PhoneNo?: string | null,
      isDelete?: string | null,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
    } | null,
    MaintenanceCardId?: string | null,
    LastLoginDate?: number | null,
    CreatedAt: number,
    UpdatedAt: number,
    relUserGroup?:  {
      __typename: "UserGroup",
      Id: string,
      MemberKind: string,
      Name: string,
      CompanyId: string,
      ShopId?: string | null,
      Members:  {
        __typename: "UsersGroupMember",
        Users?: Array< string | null > | null,
      },
      RelGroups?: Array< string | null > | null,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      users?:  {
        __typename: "ModelUserConnection",
        items?:  Array< {
          __typename: "User",
          Id: string,
          Email: string,
          CompanyId: string,
          ShopId: string,
          GroupId?: string | null,
          AffiliatedGroups?:  {
            __typename: "AffiliatedGroup",
            Principal: string,
            Secondary?: string | null,
          } | null,
          isDelete?: string | null,
          CognitoGroup: CognitoGroup,
          company:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          },
          shop?:  {
            __typename: "Shop",
            Id: string,
            CompanyId: string,
            Name: string,
            DisplayName: string,
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
            StreetAddress?: string | null,
            PhoneNo?: string | null,
            isDelete?: string | null,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
          } | null,
          MaintenanceCardId?: string | null,
          LastLoginDate?: number | null,
          CreatedAt: number,
          UpdatedAt: number,
          relUserGroup?:  {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      relTerminalGroups?:  {
        __typename: "ModelTerminalGroupConnection",
        items?:  Array< {
          __typename: "TerminalGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members?:  {
            __typename: "TerminalsGroupMember",
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
          } | null,
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          terminals?:  {
            __typename: "ModelTerminalConnection",
            items?:  Array< {
              __typename: "Terminal",
              Id: string,
              Kind: TerminalKind,
              CompanyId: string,
              ShopId: string,
              GroupId: string,
              isDelete?: string | null,
              DisplayName: string,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relUserGroups?:  {
            __typename: "ModelUserGroupConnection",
            items?:  Array< {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateCompanySubscription = {
  onCreateCompany?:  {
    __typename: "Company",
    Id: string,
    Name: string,
    DisplayName: string,
    Shops?: Array< string > | null,
    GeneralConfigs:  {
      __typename: "GeneralConfigs",
      TaxCalcMathod: number,
    },
    UseStatus:  {
      __typename: "CompanyUseStatus",
      TopUp: boolean,
      TicketKiosk: boolean,
    },
    SubSystemConfigs:  {
      __typename: "CompanySubSystemConfig",
      Common:  {
        __typename: "CommonConfig",
        Shiagel:  {
          __typename: "ShiagelConfig",
          AuthCode: string,
          Use: boolean,
        },
      },
      TicketKiosk?:  {
        __typename: "TicketKioskConfig",
        Fingo?:  {
          __typename: "FingoConfig",
          Use: boolean,
        } | null,
        Coupon?:  {
          __typename: "CouponConfig",
          CalcMethod: number,
        } | null,
      } | null,
    },
    isDelete: string,
    CreatedAt: number,
    UpdatedAt: number,
    owner: string,
  } | null,
};

export type OnUpdateCompanySubscription = {
  onUpdateCompany?:  {
    __typename: "Company",
    Id: string,
    Name: string,
    DisplayName: string,
    Shops?: Array< string > | null,
    GeneralConfigs:  {
      __typename: "GeneralConfigs",
      TaxCalcMathod: number,
    },
    UseStatus:  {
      __typename: "CompanyUseStatus",
      TopUp: boolean,
      TicketKiosk: boolean,
    },
    SubSystemConfigs:  {
      __typename: "CompanySubSystemConfig",
      Common:  {
        __typename: "CommonConfig",
        Shiagel:  {
          __typename: "ShiagelConfig",
          AuthCode: string,
          Use: boolean,
        },
      },
      TicketKiosk?:  {
        __typename: "TicketKioskConfig",
        Fingo?:  {
          __typename: "FingoConfig",
          Use: boolean,
        } | null,
        Coupon?:  {
          __typename: "CouponConfig",
          CalcMethod: number,
        } | null,
      } | null,
    },
    isDelete: string,
    CreatedAt: number,
    UpdatedAt: number,
    owner: string,
  } | null,
};

export type OnDeleteCompanySubscription = {
  onDeleteCompany?:  {
    __typename: "Company",
    Id: string,
    Name: string,
    DisplayName: string,
    Shops?: Array< string > | null,
    GeneralConfigs:  {
      __typename: "GeneralConfigs",
      TaxCalcMathod: number,
    },
    UseStatus:  {
      __typename: "CompanyUseStatus",
      TopUp: boolean,
      TicketKiosk: boolean,
    },
    SubSystemConfigs:  {
      __typename: "CompanySubSystemConfig",
      Common:  {
        __typename: "CommonConfig",
        Shiagel:  {
          __typename: "ShiagelConfig",
          AuthCode: string,
          Use: boolean,
        },
      },
      TicketKiosk?:  {
        __typename: "TicketKioskConfig",
        Fingo?:  {
          __typename: "FingoConfig",
          Use: boolean,
        } | null,
        Coupon?:  {
          __typename: "CouponConfig",
          CalcMethod: number,
        } | null,
      } | null,
    },
    isDelete: string,
    CreatedAt: number,
    UpdatedAt: number,
    owner: string,
  } | null,
};

export type OnCreateShopSubscription = {
  onCreateShop?:  {
    __typename: "Shop",
    Id: string,
    CompanyId: string,
    Name: string,
    DisplayName: string,
    Terminals?:  Array< {
      __typename: "TerminalSummary",
      Id: string,
      Kind: TerminalKind,
    } | null > | null,
    StreetAddress?: string | null,
    PhoneNo?: string | null,
    isDelete?: string | null,
    CreatedAt: number,
    UpdatedAt: number,
    owner: string,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
  } | null,
};

export type OnUpdateShopSubscription = {
  onUpdateShop?:  {
    __typename: "Shop",
    Id: string,
    CompanyId: string,
    Name: string,
    DisplayName: string,
    Terminals?:  Array< {
      __typename: "TerminalSummary",
      Id: string,
      Kind: TerminalKind,
    } | null > | null,
    StreetAddress?: string | null,
    PhoneNo?: string | null,
    isDelete?: string | null,
    CreatedAt: number,
    UpdatedAt: number,
    owner: string,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
  } | null,
};

export type OnDeleteShopSubscription = {
  onDeleteShop?:  {
    __typename: "Shop",
    Id: string,
    CompanyId: string,
    Name: string,
    DisplayName: string,
    Terminals?:  Array< {
      __typename: "TerminalSummary",
      Id: string,
      Kind: TerminalKind,
    } | null > | null,
    StreetAddress?: string | null,
    PhoneNo?: string | null,
    isDelete?: string | null,
    CreatedAt: number,
    UpdatedAt: number,
    owner: string,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
  } | null,
};

export type OnCreateUserGroupSubscription = {
  onCreateUserGroup?:  {
    __typename: "UserGroup",
    Id: string,
    MemberKind: string,
    Name: string,
    CompanyId: string,
    ShopId?: string | null,
    Members:  {
      __typename: "UsersGroupMember",
      Users?: Array< string | null > | null,
    },
    RelGroups?: Array< string | null > | null,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items?:  Array< {
        __typename: "User",
        Id: string,
        Email: string,
        CompanyId: string,
        ShopId: string,
        GroupId?: string | null,
        AffiliatedGroups?:  {
          __typename: "AffiliatedGroup",
          Principal: string,
          Secondary?: string | null,
        } | null,
        isDelete?: string | null,
        CognitoGroup: CognitoGroup,
        company:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        },
        shop?:  {
          __typename: "Shop",
          Id: string,
          CompanyId: string,
          Name: string,
          DisplayName: string,
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
          StreetAddress?: string | null,
          PhoneNo?: string | null,
          isDelete?: string | null,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
        } | null,
        MaintenanceCardId?: string | null,
        LastLoginDate?: number | null,
        CreatedAt: number,
        UpdatedAt: number,
        relUserGroup?:  {
          __typename: "UserGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members:  {
            __typename: "UsersGroupMember",
            Users?: Array< string | null > | null,
          },
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          users?:  {
            __typename: "ModelUserConnection",
            items?:  Array< {
              __typename: "User",
              Id: string,
              Email: string,
              CompanyId: string,
              ShopId: string,
              GroupId?: string | null,
              isDelete?: string | null,
              CognitoGroup: CognitoGroup,
              MaintenanceCardId?: string | null,
              LastLoginDate?: number | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relTerminalGroups?:  {
            __typename: "ModelTerminalGroupConnection",
            items?:  Array< {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    relTerminalGroups?:  {
      __typename: "ModelTerminalGroupConnection",
      items?:  Array< {
        __typename: "TerminalGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members?:  {
          __typename: "TerminalsGroupMember",
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
        } | null,
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        terminals?:  {
          __typename: "ModelTerminalConnection",
          items?:  Array< {
            __typename: "Terminal",
            Id: string,
            Kind: TerminalKind,
            CompanyId: string,
            ShopId: string,
            GroupId: string,
            AffiliatedGroups:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            },
            isDelete?: string | null,
            DisplayName: string,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            relTerminalGroup?:  {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relUserGroups?:  {
          __typename: "ModelUserGroupConnection",
          items?:  Array< {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateUserGroupSubscription = {
  onUpdateUserGroup?:  {
    __typename: "UserGroup",
    Id: string,
    MemberKind: string,
    Name: string,
    CompanyId: string,
    ShopId?: string | null,
    Members:  {
      __typename: "UsersGroupMember",
      Users?: Array< string | null > | null,
    },
    RelGroups?: Array< string | null > | null,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items?:  Array< {
        __typename: "User",
        Id: string,
        Email: string,
        CompanyId: string,
        ShopId: string,
        GroupId?: string | null,
        AffiliatedGroups?:  {
          __typename: "AffiliatedGroup",
          Principal: string,
          Secondary?: string | null,
        } | null,
        isDelete?: string | null,
        CognitoGroup: CognitoGroup,
        company:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        },
        shop?:  {
          __typename: "Shop",
          Id: string,
          CompanyId: string,
          Name: string,
          DisplayName: string,
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
          StreetAddress?: string | null,
          PhoneNo?: string | null,
          isDelete?: string | null,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
        } | null,
        MaintenanceCardId?: string | null,
        LastLoginDate?: number | null,
        CreatedAt: number,
        UpdatedAt: number,
        relUserGroup?:  {
          __typename: "UserGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members:  {
            __typename: "UsersGroupMember",
            Users?: Array< string | null > | null,
          },
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          users?:  {
            __typename: "ModelUserConnection",
            items?:  Array< {
              __typename: "User",
              Id: string,
              Email: string,
              CompanyId: string,
              ShopId: string,
              GroupId?: string | null,
              isDelete?: string | null,
              CognitoGroup: CognitoGroup,
              MaintenanceCardId?: string | null,
              LastLoginDate?: number | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relTerminalGroups?:  {
            __typename: "ModelTerminalGroupConnection",
            items?:  Array< {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    relTerminalGroups?:  {
      __typename: "ModelTerminalGroupConnection",
      items?:  Array< {
        __typename: "TerminalGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members?:  {
          __typename: "TerminalsGroupMember",
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
        } | null,
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        terminals?:  {
          __typename: "ModelTerminalConnection",
          items?:  Array< {
            __typename: "Terminal",
            Id: string,
            Kind: TerminalKind,
            CompanyId: string,
            ShopId: string,
            GroupId: string,
            AffiliatedGroups:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            },
            isDelete?: string | null,
            DisplayName: string,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            relTerminalGroup?:  {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relUserGroups?:  {
          __typename: "ModelUserGroupConnection",
          items?:  Array< {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteUserGroupSubscription = {
  onDeleteUserGroup?:  {
    __typename: "UserGroup",
    Id: string,
    MemberKind: string,
    Name: string,
    CompanyId: string,
    ShopId?: string | null,
    Members:  {
      __typename: "UsersGroupMember",
      Users?: Array< string | null > | null,
    },
    RelGroups?: Array< string | null > | null,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    users?:  {
      __typename: "ModelUserConnection",
      items?:  Array< {
        __typename: "User",
        Id: string,
        Email: string,
        CompanyId: string,
        ShopId: string,
        GroupId?: string | null,
        AffiliatedGroups?:  {
          __typename: "AffiliatedGroup",
          Principal: string,
          Secondary?: string | null,
        } | null,
        isDelete?: string | null,
        CognitoGroup: CognitoGroup,
        company:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        },
        shop?:  {
          __typename: "Shop",
          Id: string,
          CompanyId: string,
          Name: string,
          DisplayName: string,
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
          StreetAddress?: string | null,
          PhoneNo?: string | null,
          isDelete?: string | null,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
        } | null,
        MaintenanceCardId?: string | null,
        LastLoginDate?: number | null,
        CreatedAt: number,
        UpdatedAt: number,
        relUserGroup?:  {
          __typename: "UserGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members:  {
            __typename: "UsersGroupMember",
            Users?: Array< string | null > | null,
          },
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          users?:  {
            __typename: "ModelUserConnection",
            items?:  Array< {
              __typename: "User",
              Id: string,
              Email: string,
              CompanyId: string,
              ShopId: string,
              GroupId?: string | null,
              isDelete?: string | null,
              CognitoGroup: CognitoGroup,
              MaintenanceCardId?: string | null,
              LastLoginDate?: number | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relTerminalGroups?:  {
            __typename: "ModelTerminalGroupConnection",
            items?:  Array< {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    relTerminalGroups?:  {
      __typename: "ModelTerminalGroupConnection",
      items?:  Array< {
        __typename: "TerminalGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members?:  {
          __typename: "TerminalsGroupMember",
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
        } | null,
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        terminals?:  {
          __typename: "ModelTerminalConnection",
          items?:  Array< {
            __typename: "Terminal",
            Id: string,
            Kind: TerminalKind,
            CompanyId: string,
            ShopId: string,
            GroupId: string,
            AffiliatedGroups:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            },
            isDelete?: string | null,
            DisplayName: string,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            relTerminalGroup?:  {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relUserGroups?:  {
          __typename: "ModelUserGroupConnection",
          items?:  Array< {
            __typename: "UserGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            Members:  {
              __typename: "UsersGroupMember",
              Users?: Array< string | null > | null,
            },
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            users?:  {
              __typename: "ModelUserConnection",
              nextToken?: string | null,
            } | null,
            relTerminalGroups?:  {
              __typename: "ModelTerminalGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateTerminalGroupSubscription = {
  onCreateTerminalGroup?:  {
    __typename: "TerminalGroup",
    Id: string,
    MemberKind: string,
    Name: string,
    CompanyId: string,
    ShopId?: string | null,
    Members?:  {
      __typename: "TerminalsGroupMember",
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
    } | null,
    RelGroups?: Array< string | null > | null,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    terminals?:  {
      __typename: "ModelTerminalConnection",
      items?:  Array< {
        __typename: "Terminal",
        Id: string,
        Kind: TerminalKind,
        CompanyId: string,
        ShopId: string,
        GroupId: string,
        AffiliatedGroups:  {
          __typename: "AffiliatedGroup",
          Principal: string,
          Secondary?: string | null,
        },
        isDelete?: string | null,
        DisplayName: string,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        shop?:  {
          __typename: "Shop",
          Id: string,
          CompanyId: string,
          Name: string,
          DisplayName: string,
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
          StreetAddress?: string | null,
          PhoneNo?: string | null,
          isDelete?: string | null,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
        } | null,
        relTerminalGroup?:  {
          __typename: "TerminalGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members?:  {
            __typename: "TerminalsGroupMember",
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
          } | null,
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          terminals?:  {
            __typename: "ModelTerminalConnection",
            items?:  Array< {
              __typename: "Terminal",
              Id: string,
              Kind: TerminalKind,
              CompanyId: string,
              ShopId: string,
              GroupId: string,
              isDelete?: string | null,
              DisplayName: string,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relUserGroups?:  {
            __typename: "ModelUserGroupConnection",
            items?:  Array< {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    relUserGroups?:  {
      __typename: "ModelUserGroupConnection",
      items?:  Array< {
        __typename: "UserGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members:  {
          __typename: "UsersGroupMember",
          Users?: Array< string | null > | null,
        },
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          items?:  Array< {
            __typename: "User",
            Id: string,
            Email: string,
            CompanyId: string,
            ShopId: string,
            GroupId?: string | null,
            AffiliatedGroups?:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            } | null,
            isDelete?: string | null,
            CognitoGroup: CognitoGroup,
            company:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            },
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            MaintenanceCardId?: string | null,
            LastLoginDate?: number | null,
            CreatedAt: number,
            UpdatedAt: number,
            relUserGroup?:  {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relTerminalGroups?:  {
          __typename: "ModelTerminalGroupConnection",
          items?:  Array< {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateTerminalGroupSubscription = {
  onUpdateTerminalGroup?:  {
    __typename: "TerminalGroup",
    Id: string,
    MemberKind: string,
    Name: string,
    CompanyId: string,
    ShopId?: string | null,
    Members?:  {
      __typename: "TerminalsGroupMember",
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
    } | null,
    RelGroups?: Array< string | null > | null,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    terminals?:  {
      __typename: "ModelTerminalConnection",
      items?:  Array< {
        __typename: "Terminal",
        Id: string,
        Kind: TerminalKind,
        CompanyId: string,
        ShopId: string,
        GroupId: string,
        AffiliatedGroups:  {
          __typename: "AffiliatedGroup",
          Principal: string,
          Secondary?: string | null,
        },
        isDelete?: string | null,
        DisplayName: string,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        shop?:  {
          __typename: "Shop",
          Id: string,
          CompanyId: string,
          Name: string,
          DisplayName: string,
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
          StreetAddress?: string | null,
          PhoneNo?: string | null,
          isDelete?: string | null,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
        } | null,
        relTerminalGroup?:  {
          __typename: "TerminalGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members?:  {
            __typename: "TerminalsGroupMember",
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
          } | null,
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          terminals?:  {
            __typename: "ModelTerminalConnection",
            items?:  Array< {
              __typename: "Terminal",
              Id: string,
              Kind: TerminalKind,
              CompanyId: string,
              ShopId: string,
              GroupId: string,
              isDelete?: string | null,
              DisplayName: string,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relUserGroups?:  {
            __typename: "ModelUserGroupConnection",
            items?:  Array< {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    relUserGroups?:  {
      __typename: "ModelUserGroupConnection",
      items?:  Array< {
        __typename: "UserGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members:  {
          __typename: "UsersGroupMember",
          Users?: Array< string | null > | null,
        },
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          items?:  Array< {
            __typename: "User",
            Id: string,
            Email: string,
            CompanyId: string,
            ShopId: string,
            GroupId?: string | null,
            AffiliatedGroups?:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            } | null,
            isDelete?: string | null,
            CognitoGroup: CognitoGroup,
            company:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            },
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            MaintenanceCardId?: string | null,
            LastLoginDate?: number | null,
            CreatedAt: number,
            UpdatedAt: number,
            relUserGroup?:  {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relTerminalGroups?:  {
          __typename: "ModelTerminalGroupConnection",
          items?:  Array< {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteTerminalGroupSubscription = {
  onDeleteTerminalGroup?:  {
    __typename: "TerminalGroup",
    Id: string,
    MemberKind: string,
    Name: string,
    CompanyId: string,
    ShopId?: string | null,
    Members?:  {
      __typename: "TerminalsGroupMember",
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
    } | null,
    RelGroups?: Array< string | null > | null,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    terminals?:  {
      __typename: "ModelTerminalConnection",
      items?:  Array< {
        __typename: "Terminal",
        Id: string,
        Kind: TerminalKind,
        CompanyId: string,
        ShopId: string,
        GroupId: string,
        AffiliatedGroups:  {
          __typename: "AffiliatedGroup",
          Principal: string,
          Secondary?: string | null,
        },
        isDelete?: string | null,
        DisplayName: string,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        shop?:  {
          __typename: "Shop",
          Id: string,
          CompanyId: string,
          Name: string,
          DisplayName: string,
          Terminals?:  Array< {
            __typename: "TerminalSummary",
            Id: string,
            Kind: TerminalKind,
          } | null > | null,
          StreetAddress?: string | null,
          PhoneNo?: string | null,
          isDelete?: string | null,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
        } | null,
        relTerminalGroup?:  {
          __typename: "TerminalGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members?:  {
            __typename: "TerminalsGroupMember",
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
          } | null,
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          terminals?:  {
            __typename: "ModelTerminalConnection",
            items?:  Array< {
              __typename: "Terminal",
              Id: string,
              Kind: TerminalKind,
              CompanyId: string,
              ShopId: string,
              GroupId: string,
              isDelete?: string | null,
              DisplayName: string,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relUserGroups?:  {
            __typename: "ModelUserGroupConnection",
            items?:  Array< {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    relUserGroups?:  {
      __typename: "ModelUserGroupConnection",
      items?:  Array< {
        __typename: "UserGroup",
        Id: string,
        MemberKind: string,
        Name: string,
        CompanyId: string,
        ShopId?: string | null,
        Members:  {
          __typename: "UsersGroupMember",
          Users?: Array< string | null > | null,
        },
        RelGroups?: Array< string | null > | null,
        CreatedAt: number,
        UpdatedAt: number,
        company?:  {
          __typename: "Company",
          Id: string,
          Name: string,
          DisplayName: string,
          Shops?: Array< string > | null,
          GeneralConfigs:  {
            __typename: "GeneralConfigs",
            TaxCalcMathod: number,
          },
          UseStatus:  {
            __typename: "CompanyUseStatus",
            TopUp: boolean,
            TicketKiosk: boolean,
          },
          isDelete: string,
          CreatedAt: number,
          UpdatedAt: number,
          owner: string,
        } | null,
        users?:  {
          __typename: "ModelUserConnection",
          items?:  Array< {
            __typename: "User",
            Id: string,
            Email: string,
            CompanyId: string,
            ShopId: string,
            GroupId?: string | null,
            AffiliatedGroups?:  {
              __typename: "AffiliatedGroup",
              Principal: string,
              Secondary?: string | null,
            } | null,
            isDelete?: string | null,
            CognitoGroup: CognitoGroup,
            company:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            },
            shop?:  {
              __typename: "Shop",
              Id: string,
              CompanyId: string,
              Name: string,
              DisplayName: string,
              StreetAddress?: string | null,
              PhoneNo?: string | null,
              isDelete?: string | null,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            MaintenanceCardId?: string | null,
            LastLoginDate?: number | null,
            CreatedAt: number,
            UpdatedAt: number,
            relUserGroup?:  {
              __typename: "UserGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
        relTerminalGroups?:  {
          __typename: "ModelTerminalGroupConnection",
          items?:  Array< {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null > | null,
          nextToken?: string | null,
        } | null,
      } | null > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateTerminalSubscription = {
  onCreateTerminal?:  {
    __typename: "Terminal",
    Id: string,
    Kind: TerminalKind,
    CompanyId: string,
    ShopId: string,
    GroupId: string,
    AffiliatedGroups:  {
      __typename: "AffiliatedGroup",
      Principal: string,
      Secondary?: string | null,
    },
    isDelete?: string | null,
    DisplayName: string,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    shop?:  {
      __typename: "Shop",
      Id: string,
      CompanyId: string,
      Name: string,
      DisplayName: string,
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
      StreetAddress?: string | null,
      PhoneNo?: string | null,
      isDelete?: string | null,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
    } | null,
    relTerminalGroup?:  {
      __typename: "TerminalGroup",
      Id: string,
      MemberKind: string,
      Name: string,
      CompanyId: string,
      ShopId?: string | null,
      Members?:  {
        __typename: "TerminalsGroupMember",
        Terminals?:  Array< {
          __typename: "TerminalSummary",
          Id: string,
          Kind: TerminalKind,
        } | null > | null,
      } | null,
      RelGroups?: Array< string | null > | null,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      terminals?:  {
        __typename: "ModelTerminalConnection",
        items?:  Array< {
          __typename: "Terminal",
          Id: string,
          Kind: TerminalKind,
          CompanyId: string,
          ShopId: string,
          GroupId: string,
          AffiliatedGroups:  {
            __typename: "AffiliatedGroup",
            Principal: string,
            Secondary?: string | null,
          },
          isDelete?: string | null,
          DisplayName: string,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          shop?:  {
            __typename: "Shop",
            Id: string,
            CompanyId: string,
            Name: string,
            DisplayName: string,
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
            StreetAddress?: string | null,
            PhoneNo?: string | null,
            isDelete?: string | null,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
          } | null,
          relTerminalGroup?:  {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      relUserGroups?:  {
        __typename: "ModelUserGroupConnection",
        items?:  Array< {
          __typename: "UserGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members:  {
            __typename: "UsersGroupMember",
            Users?: Array< string | null > | null,
          },
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          users?:  {
            __typename: "ModelUserConnection",
            items?:  Array< {
              __typename: "User",
              Id: string,
              Email: string,
              CompanyId: string,
              ShopId: string,
              GroupId?: string | null,
              isDelete?: string | null,
              CognitoGroup: CognitoGroup,
              MaintenanceCardId?: string | null,
              LastLoginDate?: number | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relTerminalGroups?:  {
            __typename: "ModelTerminalGroupConnection",
            items?:  Array< {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateTerminalSubscription = {
  onUpdateTerminal?:  {
    __typename: "Terminal",
    Id: string,
    Kind: TerminalKind,
    CompanyId: string,
    ShopId: string,
    GroupId: string,
    AffiliatedGroups:  {
      __typename: "AffiliatedGroup",
      Principal: string,
      Secondary?: string | null,
    },
    isDelete?: string | null,
    DisplayName: string,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    shop?:  {
      __typename: "Shop",
      Id: string,
      CompanyId: string,
      Name: string,
      DisplayName: string,
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
      StreetAddress?: string | null,
      PhoneNo?: string | null,
      isDelete?: string | null,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
    } | null,
    relTerminalGroup?:  {
      __typename: "TerminalGroup",
      Id: string,
      MemberKind: string,
      Name: string,
      CompanyId: string,
      ShopId?: string | null,
      Members?:  {
        __typename: "TerminalsGroupMember",
        Terminals?:  Array< {
          __typename: "TerminalSummary",
          Id: string,
          Kind: TerminalKind,
        } | null > | null,
      } | null,
      RelGroups?: Array< string | null > | null,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      terminals?:  {
        __typename: "ModelTerminalConnection",
        items?:  Array< {
          __typename: "Terminal",
          Id: string,
          Kind: TerminalKind,
          CompanyId: string,
          ShopId: string,
          GroupId: string,
          AffiliatedGroups:  {
            __typename: "AffiliatedGroup",
            Principal: string,
            Secondary?: string | null,
          },
          isDelete?: string | null,
          DisplayName: string,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          shop?:  {
            __typename: "Shop",
            Id: string,
            CompanyId: string,
            Name: string,
            DisplayName: string,
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
            StreetAddress?: string | null,
            PhoneNo?: string | null,
            isDelete?: string | null,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
          } | null,
          relTerminalGroup?:  {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      relUserGroups?:  {
        __typename: "ModelUserGroupConnection",
        items?:  Array< {
          __typename: "UserGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members:  {
            __typename: "UsersGroupMember",
            Users?: Array< string | null > | null,
          },
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          users?:  {
            __typename: "ModelUserConnection",
            items?:  Array< {
              __typename: "User",
              Id: string,
              Email: string,
              CompanyId: string,
              ShopId: string,
              GroupId?: string | null,
              isDelete?: string | null,
              CognitoGroup: CognitoGroup,
              MaintenanceCardId?: string | null,
              LastLoginDate?: number | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relTerminalGroups?:  {
            __typename: "ModelTerminalGroupConnection",
            items?:  Array< {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteTerminalSubscription = {
  onDeleteTerminal?:  {
    __typename: "Terminal",
    Id: string,
    Kind: TerminalKind,
    CompanyId: string,
    ShopId: string,
    GroupId: string,
    AffiliatedGroups:  {
      __typename: "AffiliatedGroup",
      Principal: string,
      Secondary?: string | null,
    },
    isDelete?: string | null,
    DisplayName: string,
    CreatedAt: number,
    UpdatedAt: number,
    company?:  {
      __typename: "Company",
      Id: string,
      Name: string,
      DisplayName: string,
      Shops?: Array< string > | null,
      GeneralConfigs:  {
        __typename: "GeneralConfigs",
        TaxCalcMathod: number,
      },
      UseStatus:  {
        __typename: "CompanyUseStatus",
        TopUp: boolean,
        TicketKiosk: boolean,
      },
      SubSystemConfigs:  {
        __typename: "CompanySubSystemConfig",
        Common:  {
          __typename: "CommonConfig",
          Shiagel:  {
            __typename: "ShiagelConfig",
            AuthCode: string,
            Use: boolean,
          },
        },
        TicketKiosk?:  {
          __typename: "TicketKioskConfig",
          Fingo?:  {
            __typename: "FingoConfig",
            Use: boolean,
          } | null,
          Coupon?:  {
            __typename: "CouponConfig",
            CalcMethod: number,
          } | null,
        } | null,
      },
      isDelete: string,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
    } | null,
    shop?:  {
      __typename: "Shop",
      Id: string,
      CompanyId: string,
      Name: string,
      DisplayName: string,
      Terminals?:  Array< {
        __typename: "TerminalSummary",
        Id: string,
        Kind: TerminalKind,
      } | null > | null,
      StreetAddress?: string | null,
      PhoneNo?: string | null,
      isDelete?: string | null,
      CreatedAt: number,
      UpdatedAt: number,
      owner: string,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
    } | null,
    relTerminalGroup?:  {
      __typename: "TerminalGroup",
      Id: string,
      MemberKind: string,
      Name: string,
      CompanyId: string,
      ShopId?: string | null,
      Members?:  {
        __typename: "TerminalsGroupMember",
        Terminals?:  Array< {
          __typename: "TerminalSummary",
          Id: string,
          Kind: TerminalKind,
        } | null > | null,
      } | null,
      RelGroups?: Array< string | null > | null,
      CreatedAt: number,
      UpdatedAt: number,
      company?:  {
        __typename: "Company",
        Id: string,
        Name: string,
        DisplayName: string,
        Shops?: Array< string > | null,
        GeneralConfigs:  {
          __typename: "GeneralConfigs",
          TaxCalcMathod: number,
        },
        UseStatus:  {
          __typename: "CompanyUseStatus",
          TopUp: boolean,
          TicketKiosk: boolean,
        },
        SubSystemConfigs:  {
          __typename: "CompanySubSystemConfig",
          Common:  {
            __typename: "CommonConfig",
            Shiagel:  {
              __typename: "ShiagelConfig",
              AuthCode: string,
              Use: boolean,
            },
          },
          TicketKiosk?:  {
            __typename: "TicketKioskConfig",
            Fingo?:  {
              __typename: "FingoConfig",
              Use: boolean,
            } | null,
            Coupon?:  {
              __typename: "CouponConfig",
              CalcMethod: number,
            } | null,
          } | null,
        },
        isDelete: string,
        CreatedAt: number,
        UpdatedAt: number,
        owner: string,
      } | null,
      terminals?:  {
        __typename: "ModelTerminalConnection",
        items?:  Array< {
          __typename: "Terminal",
          Id: string,
          Kind: TerminalKind,
          CompanyId: string,
          ShopId: string,
          GroupId: string,
          AffiliatedGroups:  {
            __typename: "AffiliatedGroup",
            Principal: string,
            Secondary?: string | null,
          },
          isDelete?: string | null,
          DisplayName: string,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          shop?:  {
            __typename: "Shop",
            Id: string,
            CompanyId: string,
            Name: string,
            DisplayName: string,
            Terminals?:  Array< {
              __typename: "TerminalSummary",
              Id: string,
              Kind: TerminalKind,
            } | null > | null,
            StreetAddress?: string | null,
            PhoneNo?: string | null,
            isDelete?: string | null,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
          } | null,
          relTerminalGroup?:  {
            __typename: "TerminalGroup",
            Id: string,
            MemberKind: string,
            Name: string,
            CompanyId: string,
            ShopId?: string | null,
            RelGroups?: Array< string | null > | null,
            CreatedAt: number,
            UpdatedAt: number,
            company?:  {
              __typename: "Company",
              Id: string,
              Name: string,
              DisplayName: string,
              Shops?: Array< string > | null,
              isDelete: string,
              CreatedAt: number,
              UpdatedAt: number,
              owner: string,
            } | null,
            terminals?:  {
              __typename: "ModelTerminalConnection",
              nextToken?: string | null,
            } | null,
            relUserGroups?:  {
              __typename: "ModelUserGroupConnection",
              nextToken?: string | null,
            } | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
      relUserGroups?:  {
        __typename: "ModelUserGroupConnection",
        items?:  Array< {
          __typename: "UserGroup",
          Id: string,
          MemberKind: string,
          Name: string,
          CompanyId: string,
          ShopId?: string | null,
          Members:  {
            __typename: "UsersGroupMember",
            Users?: Array< string | null > | null,
          },
          RelGroups?: Array< string | null > | null,
          CreatedAt: number,
          UpdatedAt: number,
          company?:  {
            __typename: "Company",
            Id: string,
            Name: string,
            DisplayName: string,
            Shops?: Array< string > | null,
            GeneralConfigs:  {
              __typename: "GeneralConfigs",
              TaxCalcMathod: number,
            },
            UseStatus:  {
              __typename: "CompanyUseStatus",
              TopUp: boolean,
              TicketKiosk: boolean,
            },
            isDelete: string,
            CreatedAt: number,
            UpdatedAt: number,
            owner: string,
          } | null,
          users?:  {
            __typename: "ModelUserConnection",
            items?:  Array< {
              __typename: "User",
              Id: string,
              Email: string,
              CompanyId: string,
              ShopId: string,
              GroupId?: string | null,
              isDelete?: string | null,
              CognitoGroup: CognitoGroup,
              MaintenanceCardId?: string | null,
              LastLoginDate?: number | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
          relTerminalGroups?:  {
            __typename: "ModelTerminalGroupConnection",
            items?:  Array< {
              __typename: "TerminalGroup",
              Id: string,
              MemberKind: string,
              Name: string,
              CompanyId: string,
              ShopId?: string | null,
              RelGroups?: Array< string | null > | null,
              CreatedAt: number,
              UpdatedAt: number,
            } | null > | null,
            nextToken?: string | null,
          } | null,
        } | null > | null,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};
