import { API } from "aws-amplify";
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import {
  listUsers,
  userByDeleteStatusWithCompany,
  getUser
} from "@/graphql/queries";
import { 
  createCognitoUser as _createCognitoUser,
  updateCognitoUser 
} from '@/graphql/mutations';
import {
  UserByDeleteStatusWithCompanyQueryVariables,
  UserByDeleteStatusWithCompanyQuery,
  ListUsersQueryVariables,
  ListUsersQuery,
  GetUserQueryVariables,
  GetUserQuery,
  CreateCognitoUserMutation,
  CognitoGroup
} from "@/API";


type NullableUsers = NonNullable<NonNullable<ListUsersQuery["listUsers"]>["items"]>
export type ListUsers = NonNullable<NullableUsers[number]>[]
type NullableUserByDeleteStatusWithCompany = NonNullable<NonNullable<UserByDeleteStatusWithCompanyQuery["userByDeleteStatusWithCompany"]>["items"]>
type UserByDeleteStatusWithCompany = NonNullable<NullableUserByDeleteStatusWithCompany[number]>[]
export type NullableUser =  GetUserQuery["getUser"]

export const getAllUsers = async (variables: null | ListUsersQueryVariables = null): Promise<ListUsers> => {
  const queryOption = {
    query: listUsers,
  } as GraphQLOptions
  if (variables) { queryOption.variables = variables }
  const graphqlFunc = async (option: GraphQLOptions) => {
    const response =  await API.graphql(option) as {data: ListUsersQuery}
    return response.data.listUsers
  }
  let queryResult = await graphqlFunc(queryOption)
  if (!queryResult) return []
  let result = queryResult.items? queryResult.items: []
  const nextVariables: ListUsersQueryVariables = variables? {...variables} : {}
  while (queryResult.nextToken){
    nextVariables.nextToken = queryResult.nextToken
    queryResult = await graphqlFunc({query:listUsers, variables: nextVariables})
    if (!queryResult) break
    result = result.concat(queryResult.items? queryResult.items: [])
  }
  return result.filter((v: NullableUsers[number]) => v) as ListUsers
}


export const getUserByDeleteStatusWithCompany = async (variables: null | UserByDeleteStatusWithCompanyQueryVariables = null): Promise<ListUsers> => {
  const queryOption = {
    query: userByDeleteStatusWithCompany,
  } as GraphQLOptions
  if (variables) { queryOption.variables = variables }
  const graphqlFunc = async (option: GraphQLOptions) => {
    const response =  await API.graphql(option) as {data: UserByDeleteStatusWithCompanyQuery}
    return response.data.userByDeleteStatusWithCompany
  }
  let queryResult = await graphqlFunc(queryOption)
  if (!queryResult) return []
  let result = queryResult.items? queryResult.items: []
  const nextVariables: UserByDeleteStatusWithCompanyQueryVariables = variables? {...variables} : {}
  while (queryResult.nextToken){
    nextVariables.nextToken = queryResult.nextToken
    queryResult = await graphqlFunc({query:userByDeleteStatusWithCompany, variables: nextVariables})
    if (!queryResult) break
    result = result.concat(queryResult.items? queryResult.items: [])
  }
  console.log('[getUserByDeleteStatusWithCompany]', result);
  return result.filter((v: NullableUserByDeleteStatusWithCompany[number]) => v) as UserByDeleteStatusWithCompany
}


export const getUserById = async (variables: GetUserQueryVariables): Promise<NullableUser> => {
  const queryOption = {
    query: getUser,
    variables: variables,
  } as GraphQLOptions
  const response =  await API.graphql(queryOption) as {data: GetUserQuery}
  const result = response.data.getUser
  console.log("[Get User]", result)
  return result
}

// FIXME:lambdaの実装の都合上、余計な値を渡す必要がある・・・。
// lambda側に合わせて変更する。
interface UpdateCognitoUserMutationVariables{
    CompanyId: string;
    ShopId: string;
    Id: string;
    Email: string;
    GroupId: string;
    CognitoGroup: CognitoGroup;
    MaintenanceCardId: string | null | undefined;
    isDelete: string;
}
export const updateCognitoUserById = async (variables: {input: UpdateCognitoUserMutationVariables}): Promise<void> => {
  console.log("[Update User Request]", variables);
  const result = await API.graphql({
    query: updateCognitoUser,
    variables: variables,
  });
  // FIXME:何も帰ってこない・・・。
  console.log("[Update User Response]", result)
}

interface CreateCognitoUserMutationVariables { 
  CompanyId: string;
  ShopId: string;
  Email: string;
  password: string;
  CognitoGroup: CognitoGroup;
  GroupId: string;
  MaintenanceCardId: string;
}

export const createCognitoUser = async (variables: CreateCognitoUserMutationVariables): Promise<string | null> => {
  console.info("[Create Cognito User Request]", variables)
  const result = await API.graphql({
    query: _createCognitoUser,
    variables: variables,
  }) as {data: CreateCognitoUserMutation};
  console.log("[Create Cognito User Response]",result);
  return result.data.createCognitoUser ?? null;
}

