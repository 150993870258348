


























































































import { Component, Vue } from "vue-property-decorator";
import { TerminalKind } from "../../API";
import { can } from "@/acl/Acl";
import { formatEpochSec } from "@/tools/datetime";

import TicketKioskDeleteDialog from "@/components/terminal/TicketKioskDeleteDialog.vue";
import TicketKioskDetailDialog from "@/components/terminal/TicketKioskDetailDialog.vue";
import TicketKioskEditDialog from "@/components/terminal/TicketKioskEditDialog.vue";

// const currentTerminalKind =
@Component({
  components: {
    TicketKioskEditDialog,
    TicketKioskDetailDialog,
    TicketKioskDeleteDialog,
  },
})
export default class TicketKiosk extends Vue {
  // data
  search = "";
  company = this.$route.query.company ? this.$route.query.company : null;
  shop = this.$route.query.shop ? this.$route.query.shop : null;
  deviceEdit = false;
  deviceDetail = false;
  deviceDelete = false;
  headers = [
    { text: "ID", value: "Id", filter: this.terminalIdAndNameFilter },
    { text: "表示名", value: "DisplayName" },
    { text: "企業", value: "company.Name", filter: this.terminalCompanyFilter },
    { text: "店舗", value: "shop.Name", filter: this.terminalShopFilter },
    { text: "登録日", value: "CreatedAt" },
    { text: "", value: "actions", align: "right", sortable: false },
  ];
  editItem = null;
  detailItem = null;
  deleteItem = null;
  isEdit = false;
  isDetail = false;
  isDelete = false;
  loading = true;
  // computed
  get terminals() {
    return this.$store.getters["terminal/terminals"];
  }
  get companyList() {
    return this.$store.getters["company/searchableCompanies"];
  }
  get shopList() {
    if (this.company) {
      const shops = this.$store.getters["shop/searchableShops"];
      return shops.filter((e: any) => e.CompanyId === this.company);
    }
    return this.$store.getters["shop/searchableShops"];
  }
  get canCreate() {
    return can(this.currentRole, "create", "terminal");
  }
  get canDelete() {
    return can(this.currentRole, "delete", "terminal");
  }
  get canEdit() {
    return can(this.currentRole, "edit", "terminal");
  }
  get currentRole() {
    return this.$store.getters["auth/userGroup"];
  }
  get currentTerminalKind() {
    return TerminalKind.TicketKiosk;
  }
  get includeDelete(): boolean {
    return this.$store.getters["terminal/includeDelete"][
      this.currentTerminalKind
    ];
  }
  set includeDelete(value) {
    this.$store.dispatch("terminal/setIncludeDelete", {
      terminalKind: this.currentTerminalKind,
      value: value,
    });
  }
  // method

  /** UTCエポック秒をフォーマットする(時刻あり) */
  unixSecToDateTime(sec: number): string { 
    return formatEpochSec(sec, true);
  }
  openDetail(item: any) {
    this.detailItem = Object.assign({}, item);
    this.isDetail = true;
  }
  openEdit(item: any) {
    if (item) {
      this.editItem = Object.assign({}, item);
    } else {
      this.editItem = null;
    }
    this.isEdit = true;
  }
  async initialize() {
    await this.$store.dispatch(
      "terminal/fetchTerminals",
      this.currentTerminalKind
    );
    await this.$store.dispatch("company/fetchCompanies");
    await this.$store.dispatch("shop/fetchShops");
  }

  openDelete(item: any) {
    this.deleteItem = Object.assign({}, item);
    this.isDelete = true;
  }
  tableClass(item: any) {
    return item.isDelete === "true" ? "blue-grey lighten-5" : "";
  }

  terminalIdAndNameFilter(value: any, search: any, item: any) {
    if(this.search) {
      return (item.Id.match(this.search) !== null) || (item.DisplayName.match(this.search) !== null);
    }
    return true
  }
  terminalCompanyFilter(value: any, search: any, item: any) {
    if (this.company) {
      return item.CompanyId === this.company;
    }
    return true;
  }
  terminalShopFilter(value: any, search: any, item: any) {
    if (this.shop) {
      return item.ShopId === this.shop;
    }
    return true;
  }
  async changeIncludeDelete() {
    await this.$store.dispatch(
      "terminal/fetchTerminals",
      this.currentTerminalKind
    );
  }
  selectShop() {
    const shop = this.shopList.find((e: any) => e.Id === this.shop);
    this.company = shop.CompanyId;
  }
  selectCompany() {
    this.shop = null;
  }
  // life-cycle
  async created() {
    this.loading = true;
    await this.initialize();
    this.loading = false;
  }
  // beforeDestroy
  beforeDestroy() {
    this.includeDelete = false;
  }
}
