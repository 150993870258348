import { API } from "aws-amplify";
import { GetTerminalQuery } from "@/API"
import {
  createTerminal,
  updateTerminal,
  updateTerminalGroup,
} from "@/graphql/mutations";
import { nanoid } from "nanoid";
import { TerminalKind, CreateTerminalInput } from "../../API";
import { getAllTerminals } from "@/interface/graphql/terminal/terminal";
import { getAllTerminalGroups } from "@/interface/graphql/group/terminalGroup";
import moment from "moment";

type Terminal = GetTerminalQuery["getTerminal"]

export const terminal = {
  namespaced: true,
  state: {
    terminals: [] as Terminal[],
    // dialog用
    dialog: {
      terminalData: [],
    },
    includeDelete: {
      TopUp: false,
      TicketKiosk: false,
    },
    needRefresh: false,
  },
  mutations: {
    setTerminals(state: any, payload: any): void {
      state.terminals = payload;
    },
    setIncludeDelete(state: any, payload: any): void {
      state.includeDelete[payload.terminalKind] = payload.value;
    },
    setTerminalsForDialog(state: any, payload: any): void {
      state.dialog.terminalData = payload;
    },
    setNeedRefresh(state: any, payload: boolean): void {
      state.needRefresh = payload;
    },
  },
  actions: {
    setIncludeDelete({ commit }: any, payload: any): void {
      commit("setIncludeDelete", payload);
    },
    async fetchTerminals(
      { commit, state, dispatch, rootGetters }: any,
      terminalKind?: TerminalKind
    ): Promise<void> {
      if (!state.needRefresh && state.terminals.length != 0) { 
        // リロード不要
        return;
      }
      try{
        await dispatch('acl/fetchAcl', null, { root: true })
        const acl = rootGetters["acl/acl"];
        // 一個ずつ取るのがあまりにも遅すぎたので一旦全スキャンからのフィルタリングで対応。
        const terminals = (await getAllTerminals())
        .filter(x => acl.Terminals.includes(x.Id))
        .filter(x => !terminalKind || x.Kind === terminalKind)
        .filter(x => state.includeDelete[x.Kind] || x.isDelete !== "true")
        console.log("[getTerminals]", terminals);
        commit("setTerminals", terminals);
        commit('setNeedRefresh', false);
      } catch (error) {
        console.error("error", error);
      }
    },
    async createTerminal(
      { commit }: any,
      { terminalKind, item }: any
    ): Promise<void> {
      // Dynamoへ保存(新規作成)
      try {
        // 7桁でIDを生成
        const generatedId = nanoid(7);
        const shopId = item.ShopId && item.ShopId !== "" ? item.ShopId : "_";
        
        const filter = {
          Id: { beginsWith: `Principal&&${item.CompanyId}&&${shopId}` },
        };    
        const updateTargetGroup: any = (await getAllTerminalGroups({ filter }))[0];

        // 不要なitemを削除
        if (updateTargetGroup.company) delete updateTargetGroup.company;
        delete updateTargetGroup.MemberKind;
        delete updateTargetGroup.terminals;
        delete updateTargetGroup.company;
        delete updateTargetGroup.relUserGroups;
        const terminal: CreateTerminalInput = {
          Id: generatedId,
          CompanyId: item.CompanyId,
          ShopId: shopId,
          GroupId: updateTargetGroup.Id,
          DisplayName: item.DisplayName,
          isDelete: "false",
          Kind: terminalKind,
          AffiliatedGroups: {
            Principal: updateTargetGroup.Id,
            Secondary: null,
          },
          // owner: rootGetters["auth/user"]["username"],
          CreatedAt: moment()
            .utc()
            .unix(),
          UpdatedAt: moment()
            .utc()
            .unix(),
        };
        const result: any = await API.graphql({
          query: createTerminal,
          variables: { input: terminal },
        });
        updateTargetGroup.Members.Terminals.push({
          Id: generatedId,
          Kind: terminalKind,
        });
        await API.graphql({
          query: updateTerminalGroup,
          variables: {
            input: updateTargetGroup,
          },
        });
        commit('setNeedRefresh', true);
        // TODO: stateだけ更新する
      } catch (error) {
        console.log("error", error);
      }
    },
    async updateTerminal({ commit }: any, payload: any): Promise<void> {
      // Dynamoへ保存(更新)
      const terminal = Object.assign(payload);
      terminal.UpdatedAt = moment().utc().unix() // 更新日時は必ず最新化する
      try {
        delete terminal.company;
        delete terminal.shop;
        delete terminal.relTerminalGroup;
        const result: any = await API.graphql({
          query: updateTerminal,
          variables: { input: terminal },
        });
        console.log(result);
        commit('setNeedRefresh', true);
        // TODO: stateだけ更新する
      } catch (error) {
        console.log(error);
      }
    },
    async deleteTerminal({ commit }: any, payload: any): Promise<void> {
      // 論理削除
      const terminal = Object.assign(payload);
      terminal.UpdatedAt = moment().utc().unix() // 更新日時は必ず最新化する
      terminal.isDelete = "true";
      delete terminal.company;
      delete terminal.shop;
      delete terminal.relTerminalGroup;
      try {
        const result: any = await API.graphql({
          query: updateTerminal,
          variables: { input: terminal },
        });
        console.log(result);
        commit('setNeedRefresh', true);
        // TODO: stateだけ更新する
      } catch (error) {
        console.log(error);
      }
    },
    async cancelDeleteTerminal({ commit }: any, payload: any): Promise<void> {
      // 論理削除
      try {
        const terminal = Object.assign({}, payload);
        terminal.UpdatedAt = moment().utc().unix() // 更新日時は必ず最新化する
        terminal.isDelete = "false";
        delete terminal.company;
        delete terminal.shop;
        delete terminal.relTerminalGroup;

        const result: any = await API.graphql({
          query: updateTerminal,
          variables: { input: terminal },
        });
        console.log(result);
        commit('setNeedRefresh', true);
        // TODO: stateだけ更新する
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTerminalsForDialog({ state, commit }: any, payload: any): Promise<void>{
      console.log("[fetchTerminalsForDialog]", payload)
      // FIXME: 論理削除の制御(includeDelete)をこっちでやってるから再読込せざるを得ない
      if (!state.needRefresh && state.dialog.terminalData.length != 0) { 
        return; // 暫定処理。
      }
      // TODO:リゾルバの実装がqueryに複数条件がセットできるようになっていなさそうなので、取得した後にフィルターする。後で修正が必要。
      try {
        const groups = await getAllTerminals({
          filter: {
            CompanyId: {
              eq: payload.CompanyId,
            }
          },
        });
        const result = groups.filter(g => g.isDelete === "false");
        console.log(result);
        commit("setTerminalsForDialog", result);
      } catch (error) {
        console.log(error);
        throw "端末取得時にエラーが発生しました";
      }
    },
  },
  getters: {
    terminals: (state: any) => (!state.terminals ? [] : state.terminals),
    searchableTerminals: (state: any) =>
      !state.terminals ? [] : state.terminals,
    includeDelete: (state: any) => state.includeDelete,
    terminalsForDialog: (state: any) =>
      !state.dialog.terminalData ? [] : state.dialog.terminalData,
  },
};
