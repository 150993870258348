var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "company" },
    [
      _c(
        "v-row",
        { staticClass: "d-flex justify-space-between" },
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-select", {
                attrs: {
                  label: "企業",
                  items: _vm.companyList,
                  "item-text": "Name",
                  "item-value": "Id",
                  dense: "",
                  outlined: "",
                  clearable: ""
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c("v-checkbox", {
                attrs: { label: "削除済みを含めて表示する" },
                on: { change: _vm.changeIncludeDelete },
                model: {
                  value: _vm.includeDelete,
                  callback: function($$v) {
                    _vm.includeDelete = $$v
                  },
                  expression: "includeDelete"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "text-right justify-end", attrs: { cols: "1" } },
            [
              _vm.canCreate
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        color: "primary",
                        fab: "",
                        dark: "",
                        small: "",
                        depressed: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.openEdit(null)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-plus")])],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.isEdit
            ? _c("CompanyEditDialog", {
                attrs: {
                  isEdit: _vm.isEdit,
                  companyInfo: _vm.editItem,
                  companies: _vm.companies
                },
                on: {
                  "update:isEdit": function($event) {
                    _vm.isEdit = $event
                  },
                  "update:is-edit": function($event) {
                    _vm.isEdit = $event
                  }
                }
              })
            : _vm._e(),
          _c("CompanyDeleteDialog", {
            attrs: { isDelete: _vm.isDelete, companyInfo: _vm.deleteItem },
            on: {
              "update:isDelete": function($event) {
                _vm.isDelete = $event
              },
              "update:is-delete": function($event) {
                _vm.isDelete = $event
              }
            }
          }),
          _c("CompanyDetailDialog", {
            attrs: { isDetail: _vm.isDetail, companyInfo: _vm.detailItem },
            on: {
              "update:isDetail": function($event) {
                _vm.isDetail = $event
              },
              "update:is-detail": function($event) {
                _vm.isDetail = $event
              }
            }
          })
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.companies,
          loading: _vm.loading,
          "loading-text": "Loading data... Please wait",
          "item-class": _vm.tableClass,
          "sort-by": "company",
          search: _vm.search,
          customFilter: _vm.companyFilter
        },
        on: { "click:row": _vm.openDetail },
        scopedSlots: _vm._u([
          {
            key: "item.UseStatus.TopUp",
            fn: function(ref) {
              var item = ref.item
              return [
                item.UseStatus.TopUp
                  ? [
                      _c("v-icon", { attrs: { color: "green", dark: "" } }, [
                        _vm._v("mdi-checkbox-blank-circle-outline")
                      ])
                    ]
                  : [
                      _c("v-icon", { attrs: { color: "grey", dark: "" } }, [
                        _vm._v("mdi-minus-circle-outline")
                      ])
                    ]
              ]
            }
          },
          {
            key: "item.UseStatus.TicketKiosk",
            fn: function(ref) {
              var item = ref.item
              return [
                item.UseStatus.TicketKiosk
                  ? [
                      _c("v-icon", { attrs: { color: "green", dark: "" } }, [
                        _vm._v("mdi-checkbox-blank-circle-outline")
                      ])
                    ]
                  : [
                      _c("v-icon", { attrs: { color: "grey", dark: "" } }, [
                        _vm._v("mdi-minus-circle-outline")
                      ])
                    ]
              ]
            }
          },
          {
            key: "item.CreatedAt",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(_vm._s(_vm.unixSecToDateTime(item.CreatedAt)))]
            }
          },
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm.canEdit && item.isDelete === "false"
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "mr-2",
                        attrs: { small: "" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.openEdit(item)
                          }
                        }
                      },
                      [_vm._v("mdi-pencil")]
                    )
                  : _vm._e(),
                _vm.canDelete && item.isDelete === "false"
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: { small: "" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.openDelete(item)
                          }
                        }
                      },
                      [_vm._v("mdi-delete")]
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "no-data",
            fn: function() {
              return [_vm._v("データがありません")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }