import {
  TerminalSummary
} from "@/API";
import {
  getTerminalGroupById,
  updateTerminalGroupById
} from "@/interface/graphql/group/terminalGroup"

export const deleteTerminal = async (groupId: string, terminal: TerminalSummary): Promise<void> => {
  const targetGroup = await getTerminalGroupById({Id: groupId})
  if (!targetGroup?.Members?.Terminals) return;
  if(targetGroup.Members.Terminals.filter(x =>x && x.Id === terminal.Id && x.Kind === terminal.Kind).length  > 0){
    const terminals = targetGroup.Members.Terminals.filter(e=> e && !(e.Id === terminal.Id && e.Kind === terminal.Kind))
    await updateTerminalGroupById({
      input: {
        Id: targetGroup.Id,
        Members:{ 
          Terminals: terminals
        }
      }
    });
  }
}