


















  import { Component, Vue } from 'vue-property-decorator';
import Logo from '@/components/Logo.vue';
    @Component({
    components: {
      Logo,
    },
  })
  export default class ForgotPassword extends Vue {
    email = '';

    async submit() {
      try {
        this.$store.dispatch('auth/recoveryInitiate', this.email);
        this.$router.push('/login/passwordReset');
      } catch (error) {
        console.log(error)
      }
    }
  }
