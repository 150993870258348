






































































































































import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";
import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component
export default class GroupDeviceCreateDialog extends Vue {
  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;
  @Getter("auth/dbUser") dbUser!: any;
  @Getter("terminal/terminalsForDialog") terminalData!: any[];
  @Getter("shop/shopsForDialog") shopList!: any[];
  @Getter("group/userGroupsForDialog") userGroupData!: any[];

  @Prop() groupInfo!: any;
  @Prop() companyList!: Record<string, unknown>[];
  @PropSync("isEdit", { type: Boolean }) isEditValue!: boolean;
  // data
  isValid = true;
  errorMessage: string | null = null;
  panel = null;
  defaultItem = {
    CompanyId: null,
    Name: "",
    Kind: "Secondary",
  };
  /** 編集していないかどうか */
  isInitial = {
    terminal: true,
    userGroup: true,
  };
  terminalItems = [
    { text: "チャージ機", value: "TopUp" },
    { text: "券売機", value: "TicketKiosk" },
  ];
  searchInput = {
    shop: null,
    terminalKind: null,
    terminalName: "",
    terminalGroupName: "",
  };

  /** 保存処理中 */
  isSaving = false;

  selectedTerminal: any[] = [];
  selectedUserGroup: any[] = [];
  userGroupSearch = "";
  terminalHeaders = [
    { text: "ID", value: "Id", sortable: false },
    {
      text: "表示名",
      value: "DisplayName",
      sortable: false,
      filter: this.terminalNameFilter,
    },
    {
      text: "種別",
      value: "type",
      sortable: false,
      filter: this.terminalKindFilter,
    },
    {
      text: "店舗",
      value: "shop",
      sortable: false,
      filter: this.terminalShopFilter,
    },
  ];
  userGroupHeaders = [
    { text: "ターミナルグループ", value: "Name", sortable: false },
  ];
  rules = {
    NameRules: [
      (v: string) => !!v || "グループ名は必須です",
      (v: string) =>
        v.length <= 255 || "グループ名は255文字以下で入力してください",
    ],
    CompnayRules: [(v: string) => !!v || "企業は必須です"],
  };

  // computed
  get isNew(): boolean {
    return !this.groupInfo;
  }
  get formTitle(): string {
    return this.isNew
      ? "ターミナルグループ作成"
      : "ターミナルグループ編集";
  }
  get saveButtonText(): string {
    return this.isNew ? "作成" : "保存";
  }
  get editedItem(): any {
    return this.groupInfo ?? this.defaultItem;
  }

  // method
  changeCompany() {
    //   会社変更時にアカウントとターミナルグループの選択をリセット
    this.selectedTerminal = [];
    this.selectedUserGroup = [];
    this.searchInput = {
      shop: null,
      terminalKind: null,
      terminalName: "",
      terminalGroupName: "",
    };
  }
  async changeTabs(number: number) {
    if (number === 1) {
      // ターミナルタブ選択時
      await this.$store.dispatch("terminal/fetchTerminalsForDialog", {
        CompanyId: this.editedItem.CompanyId,
      });
      await this.$store.dispatch("shop/fetchShopsForDialog", {
        CompanyId: this.editedItem.CompanyId,
      });
      // 編集時にMembersのものをチェック済にする
      if (this.groupInfo && this.isInitial.terminal) {
        this.groupInfo.Members.Terminals.forEach((terminal: any) => {
          const selected = this.terminalData.find(
            (e: any) => e.Id === terminal.Id && e.Kind === terminal.Kind
          );
          if (selected) this.selectedTerminal.push(selected);
        });
        this.isInitial.terminal = false;
      }
    } else if (number === 2) {
      // ユーザーグループ選択時
      await this.$store.dispatch("group/fetchUserGroupsForDialog", {
        CompanyId: this.editedItem.CompanyId,
      });
      // 初回表示に設定済みをチェック済みにする
      if (this.groupInfo && this.isInitial.userGroup) {
        this.groupInfo.RelGroups.forEach((group: string) => {
          const selected = this.userGroupData.find((e: any) => e.Id === group);
          if (selected) this.selectedUserGroup.push(selected);
        });
        this.isInitial.userGroup = false;
      }
    }
  }
  terminalShopFilter(value: any, search: string | null, item: any): boolean {
    return !this.searchInput.shop
      ? true
      : item.ShopId === this.searchInput.shop; // return true
  }
  terminalKindFilter(value: any, search: string | null, item: any): boolean {
    return !this.searchInput.terminalKind
      ? true
      : item.Kind === this.searchInput.terminalKind; // return true
  }
  terminalNameFilter(value: any, search: string | null, item: any): boolean {
    console.log(value);
    return value.match(this.searchInput.terminalName) !== null;
  }
  async save(): Promise<void> {
    this.isSaving = true;
    try {
      if ("Id" in this.editedItem) {
        // アップデート
        // 追加分はそのまま更新
        // ユーザーの削除分: Userテーブルから削除
        console.log("update");
        await this.$store.dispatch("group/updateTerminalGroup", {
          groupInfo: this.editedItem,
          selectedTerminal: this.selectedTerminal.map((e: any) => ({
            Id: e.Id,
            Kind: e.Kind,
          })),
          selectedUserGroup: this.selectedUserGroup.map((e) => e.Id),
          isInitial: this.isInitial,
        });
        // 操作履歴
        await this.loggingOperationHistory({
          user: this.dbUser,
          viewName: "ターミナルグループ編集ダイアログ",
          viewId: "GroupDeviceUpdate",
          operationName: "ターミナルグループ編集",
          operationId: "groupDeviceUpdate",
          detail: {
            groupName: this.editedItem.Name,
            groupId: this.editedItem.Id,
          },
        });
      } else {
        // 新規作成
        await this.$store.dispatch("group/createSecondaryTerminalGroup", {
          groupInfo: this.editedItem,
          selectedTerminal: this.selectedTerminal,
          selectedUserGroup: this.selectedUserGroup.map((e) => e.Id),
        });
        // 操作履歴
        await this.loggingOperationHistory({
          user: this.dbUser,
          viewName: "ターミナルグループ作成ダイアログ",
          viewId: "GroupDeviceCreate",
          operationName: "ターミナルグループ作成",
          operationId: "groupDeviceCreate",
          detail: {
            groupName: this.editedItem.Name,
            groupId: this.editedItem.Id,
          },
        });
      }
      await this.$store.dispatch("group/fetchTerminalGroups");
      this.isSaving = false;
      this.$emit("save");
      this.close();
    } catch (error) {
      console.log(error);
      this.isSaving = false;
    }
  }
  close() {
    this.isEditValue = false;
  }
}
