import { API } from "aws-amplify";
import {
  deleteCognitoUser,
} from "@/graphql/mutations";
import { ROLE } from "@/acl/Acl";
import { ListUsersQueryVariables, UserByDeleteStatusWithCompanyQueryVariables } from "@/API";
import { getAllUserGroups } from "@/interface/graphql/group/userGroup"
import {
  ListUsers,
  getAllUsers,
  getUserById,
  getUserByDeleteStatusWithCompany,
  createCognitoUser,
  updateCognitoUserById
} from '@/interface/graphql/user/user'
import { userGroupService } from '@/service/group/userGroup'
import { AccountDialogInputType } from '@/types'
import { UserState, UserAction } from './type'


export const user = {
  namespaced: true,
  state: {
    users: [],
    dialog: {
      accountData: [],
    },
    includeDelete: false,
    needRefresh: false,
  },
  mutations: {
    setUsers(state: UserState, payload: ListUsers): void {
      state.users = payload;
    },
    setIncludeDelete(state: UserState, payload: boolean): void {
      state.includeDelete = payload;
      state.needRefresh = true; // FIXME: 削除済みを含めるかどうかは画面でやるべき
    },
    setUsersForDialog(state: UserState, payload: ListUsers): void {
      state.dialog.accountData = payload;
    },
    setNeedRefresh(state: any, payload: boolean): void {
      state.needRefresh = payload;
    },
  },
  actions: {
    setIncludeDelete({ commit }: UserAction, payload: boolean): void {
      commit("setIncludeDelete", payload);
    },
    async fetchUsers({ commit, state, rootGetters, dispatch }: UserAction): Promise<void> {
      if (!state.needRefresh && state.users.length != 0) { 
        // リロード不要
        return;
      }
      try {
        await dispatch('auth/authAction', null, { root: true })
        const role = rootGetters["auth/userGroup"];
        const companyId = rootGetters["auth/companyId"]
        const shopId = rootGetters["auth/shopId"]
        const groupId: string = rootGetters["auth/dbUser"].GroupId;
        if (groupId.startsWith("Secondary")) {
          let users = await getAllUsers({
            filter: {
              GroupId: {
                eq: groupId,
              },
            },
          });
          if (!state.includeDelete) {
            users = users.filter(x => x.isDelete == "false")
          }
          commit("setUsers", users);
          commit('setNeedRefresh', false);
          return;
        }
        if (state.includeDelete) {
          let variables: ListUsersQueryVariables | null = null
          if (ROLE.CorpAdmin === role || ROLE.CorpSuperVisor === role) {
            variables = {
              filter: {
                CompanyId: {
                  eq: companyId,
                },
              },
            }
          } else if (ROLE.ShopManager === role || ROLE.ShopUser === role) {
            variables = {
              filter: {
                CompanyId: { eq: companyId },
                ShopId: { eq: shopId },
              },
            }
          }
          const users = await getAllUsers(variables);
          commit("setUsers", users);
          commit('setNeedRefresh', false);
        } else {
          let variables: UserByDeleteStatusWithCompanyQueryVariables | null = null
          if (ROLE.CorpAdmin === role || ROLE.CorpSuperVisor === role) {
            variables = {
              isDelete: "false",
              CompanyId: { eq: companyId },
            }
          } else if (ROLE.ShopManager === role || ROLE.ShopUser === role) {
            variables = {
              isDelete: "false",
              CompanyId: { eq: companyId },
              filter: {
                ShopId: { eq: shopId },
              }
            }
          } else {
            variables = {
              isDelete: "false",
            }
          }
          const users = await getUserByDeleteStatusWithCompany(variables);
          commit("setUsers", users);
          commit('setNeedRefresh', false);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async createUser({ commit }: UserAction, payload: any): Promise<void> {
      try {
        const users = await getUserByDeleteStatusWithCompany({
          "isDelete": "false",
          "filter": {
            "Email": {
              "eq": payload.email
            }
          }
        });
        if (users.length > 0) {
          throw ("EMAIL_EXIST");
        }
        const shopId = payload.ShopId ?? "_";
        const updateTargetGroupResult = await getAllUserGroups({
          filter: {
            Id: { beginsWith: `Principal&&${payload.CompanyId}&&${shopId}&&` },
          }
        });
        if (updateTargetGroupResult.length === 0) throw new Error(`[createUser] Invalid value: CompanyId : ${payload.CompanyId}, ShopId: ${shopId}`)
        const updateTargetGroup = updateTargetGroupResult[0];
        const user = {
          CompanyId: payload.CompanyId,
          ShopId: shopId,
          Email: payload.email,
          password: payload.password,
          CognitoGroup: payload.CognitoGroup,
          GroupId: updateTargetGroup.Id,
          MaintenanceCardId: payload.MaintenanceCardId,
        };
        const createUserId = await createCognitoUser(user);
        if (!createUserId) return
        await userGroupService.addUser(updateTargetGroup.Id, createUserId);
        commit('setNeedRefresh', true);
        // TODO: stateだけ更新する
      } catch (error) {
        console.log(error);
        if (error == 'EMAIL_EXIST')
          throw { code: 'DUPLICATE_EMAIL_NAME' };
        else
          throw { code: 'CREATION_FAILED' };
      }
    },

    async updateUser({ commit }: UserAction, payload: AccountDialogInputType): Promise<void> {
      try {
        const userId = payload.Id;
        if (!userId) throw new Error("[updateUser] Id not found.");
        const oldUser = await getUserById({ Id: userId });
        if (!oldUser) throw new Error("[updateUser] User data not found.");
        const shopId = payload.ShopId || "_";
        const updateTargetGroupResult = await getAllUserGroups({
          filter: {
            Id: { beginsWith: `Principal&&${payload.CompanyId}&&${shopId}&&` },
          },
        });
        if (updateTargetGroupResult.length === 0) throw new Error(`[updateUser] Invalid value: CompanyId : ${payload.CompanyId}, ShopId: ${shopId}`)
        const newGroupId = updateTargetGroupResult[0].Id;
        await updateCognitoUserById({
          input: {
            Id: userId,
            CompanyId: payload.CompanyId,
            ShopId: shopId,
            Email: payload.Email,
            CognitoGroup: payload.CognitoGroup,
            GroupId: newGroupId,
            MaintenanceCardId: payload.MaintenanceCardId,
            isDelete: "false",
            // UpdatedAt: moment().utc().unix(), // 更新日時は必ず最新化する
          }
        });
        if (oldUser.GroupId !== newGroupId) {
          if (oldUser.GroupId) {
            userGroupService.deleteUser(oldUser.GroupId, userId);
          }
          userGroupService.addUser(newGroupId, userId);
        }
        commit('setNeedRefresh', true);
        // TODO: stateだけ更新する
      } catch (error) {
        console.log(error);
        throw "CreationFailed";
      }
    },

    async deleteUser({ commit }: UserAction, payload: { Id: string; GroupId: string }): Promise<void> {
      try {
        await API.graphql({
          query: deleteCognitoUser,
          variables: {
            Id: payload.Id,
          },
        });
        commit('setNeedRefresh', true);
        // TODO: stateだけ更新する
      } catch (error) {
        console.log(error);
      }
    },

    async cancelDeleteUser({ commit }: UserAction, payload: any): Promise<void> {
      try {
        const user = Object.assign({}, payload);
        console.log(user);
        user.isDelete = "false";
        user.company = undefined;
        user.shop = undefined;
        user.affiliation = undefined;
        user.relUserGroup = undefined;
        await updateCognitoUserById({
          input: user,
        });
        commit('setNeedRefresh', true);
        // TODO: stateだけ更新する
      } catch (error) {
        console.log(error);
      }
    },
    async fetchUsersForDialog({ state, commit }: any, payload: any): Promise<void> {
      // FIXME: 論理削除の制御(includeDelete)をこっちでやってるから再読込せざるを得ない
      const froce = payload['force'] ? payload.force as boolean : false;
      if (!froce && !state.needRefresh && state.dialog.accountData.length != 0) { 
        return; // 暫定処理。
      }
      try {
        const users = await getUserByDeleteStatusWithCompany({
          isDelete: "false",
          CompanyId: { eq: payload.CompanyId },
        });
        commit("setUsersForDialog", users);
      } catch (error) {
        console.log(error);
        throw "ユーザー取得時にエラーが発生しました";
      }
    },
  },
  getters: {
    users: (state: any) => state.users,
    searchableUsers: (state: any) => state.users,
    includeDelete: (state: any) => state.includeDelete,
    usersForDialog: (state: any) => state.dialog.accountData,
  },
};
