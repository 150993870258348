





































































































import { Component, Vue } from "vue-property-decorator";
import { ListUsers } from "@/interface/graphql/user/user"
import { can } from "@/acl/Acl";
import { formatEpochSec } from "@/tools/datetime";
import AccountEditDialog from "@/components/account/AccountEditDialog.vue";
import AccountDetailDialog from "@/components/account/AccountDetailDialog.vue";
import AccountDeleteDialog from "@/components/account/AccountDeleteDialog.vue";

interface Role{
  label: string;
  value: string;
}

@Component({
  components: {
    AccountEditDialog,
    AccountDetailDialog,
    AccountDeleteDialog,
  },
})
export default class AccountList extends Vue {
  // data
  search = "";
  company = this.$route.query.company ? this.$route.query.company : "";
  store = this.$route.query.store ? this.$route.query.store : "";
  roleItems = [
    { label: "システム管理者", value: "SystemAdmin" },
    { label: "システムユーザ", value: "SystemUser" },
    { label: "企業管理者", value: "CorpAdmin" },
    { label: "スーパーバイザー", value: "CorpSuperVisor" },
    { label: "店舗管理者", value: "ShopManager" },
    { label: "店舗スタッフ", value: "ShopUser" },
  ];
  isEdit = false;
  isDetail = false;
  isDelete = false;
  headers = [
    { text: "ID", value: "Email", filter: this.accountMailFilter },
    {
      text: "所属",
      value: "affiliation",
      filter: this.accountAffiliationFilter,
      sort: this.accountAffiliationSort,
    },
    { text: "ロール", value: "CognitoGroup" },
    { text: "最終ログイン", value: "LastLoginDate" },
    { text: "メンテナンスカード", value: "MaintenanceCardId" },
    { text: "", value: "actions", align: "right", sortable: false },
  ];
  detailItem = null;
  editItem = null;
  deleteItem = null;
  loading = true;
  searchCompany = this.$route.query.company ? this.$route.query.company : null;
  searchShop = this.$route.query.shop ? this.$route.query.shop : null;
  // includeDelete = false
  // computed
  get roleItemsForUpdate(){
    const getIndex = (v: Role) => v.value === this.currentRole
    const adminCompanyId = process.env.VUE_APP_ADMIN_COMPANY_ID
    const isAdminCompany = adminCompanyId === this.currentCompanyId
    const roleItems = isAdminCompany ? this.roleItems : this.roleItems.slice(2)
    const roleIndex = roleItems.findIndex(getIndex)
    return roleItems.slice(roleIndex !== -1 ? roleIndex : 0);
  }
  get users() {
    const users: ListUsers = this.$store.getters["user/users"];
    const addAffiliation = users.map(e =>
      Object.assign({ affiliation: `${e.CompanyId} && ${e.ShopId ?? "_"}` }, e)
    );
    return addAffiliation;
  }
  get companyList() {
    return this.$store.getters["company/searchableCompanies"];
  }
  get shopList() {
    if (this.searchCompany) {
      const shops = this.$store.getters["shop/searchableShops"];
      return shops.filter((e: any) => e.CompanyId === this.searchCompany);
    }
    return this.$store.getters["shop/searchableShops"];
  }
  get canCreate() {
    return can(this.currentRole, "create", "account");
  }
  get canEdit() {
    return can(this.currentRole, "edit", "account");
  }
  get canDelete() {
    return can(this.currentRole, "delete", "account");
  }
  get currentRole() {
    return this.$store.getters["auth/userGroup"];
  }
  get currentCompanyId() {
    return this.$store.getters["auth/companyId"];
  }
  get includeDelete(): boolean {
    return this.$store.getters["user/includeDelete"];
  }
  set includeDelete(value) {
    this.$store.dispatch("user/setIncludeDelete", value);
  }
  // method
  openDetail(item: any) {
    this.detailItem = Object.assign({}, item);
    this.isDetail = true;
  }
  openDelete(item: any) {
    this.deleteItem = Object.assign({}, item);
    this.isDelete = true;
  }
  openEdit(item: any) {
    if (item) {
      this.editItem = Object.assign({}, item);
    } else {
      this.editItem = null;
    }
    this.isEdit = true;
  }
  async initialize() {
    await this.$store.dispatch("user/fetchUsers");
    await this.$store.dispatch("company/fetchCompanies");
    await this.$store.dispatch("shop/fetchShops");
  }
  getRoleLabel(roleValue: string) {
    if (!roleValue) return;
    const result = this.roleItems.filter((role) => role.value === roleValue);
    return result[0].label;
  }
  accountMailFilter(value: any, search: string | null, item: any): boolean {
    return value.match(this.search) !== null; // return true
  }
  accountAffiliationFilter(value: any, search: string | null, item: any) {
    if (this.searchCompany && this.searchShop) {
      return (
        item.CompanyId === this.searchCompany && item.ShopId === this.searchShop
      );
    } else if (this.searchCompany) {
      return item.CompanyId === this.searchCompany;
    } else if (this.searchShop) {
      return item.ShopId === this.searchShop;
    }
    return true;
  }
  changeIncludeDelete() {
    this.$store.dispatch("user/fetchUsers");
  }
  tableClass(item: any) {
    return item.isDelete === "true" ? "blue-grey lighten-5" : "";
  }
  selectShop() {
    const shop = this.shopList.find((e: any) => e.Id === this.searchShop);
    this.searchCompany = shop.CompanyId;
  }
  selectCompany() {
    this.searchShop = null;
  }
  accountAffiliationSort(a: any, b: any): number {
    if (a > b) {
      return 1;
    }
    return -1;
  }
  /** UTCエポック秒をフォーマットする(時刻あり) */
  unixSecToDateTime(sec: number): string { 
    return formatEpochSec(sec, true);
  }

  // life-cycle
  async mounted() {
    this.loading = true;
    await this.initialize();
    this.loading = false;
  }
}
