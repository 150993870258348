var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-group" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-3",
                        attrs: {
                          "append-icon": "mdi-magnify",
                          "single-line": "",
                          outlined: "",
                          dense: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _c("v-select", {
                        attrs: {
                          label: "企業",
                          items: _vm.companyList,
                          "item-text": "Name",
                          "item-value": "Id",
                          dense: "",
                          outlined: "",
                          "hide-details": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.searchCompany,
                          callback: function($$v) {
                            _vm.searchCompany = $$v
                          },
                          expression: "searchCompany"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.groupHeaders,
                  items: _vm.userGroups,
                  loading: _vm.isLoading,
                  "loading-text": _vm.messages.loading,
                  "sort-by": "Id",
                  "hide-default-header": "",
                  "item-class": _vm.groupRowClasses
                },
                on: { "click:row": _vm.openDetail }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btn-group-edit" },
            [
              _vm.canCreate
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mb-2",
                      attrs: { color: "indigo", fab: "", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.openEdit(null)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-plus")])],
                    1
                  )
                : _vm._e(),
              _vm.currentGroup && _vm.canEdit
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mb-2 ml-2",
                      attrs: { color: "primary", fab: "", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.openEdit(_vm.currentGroup)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-pencil")])],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.isEdit
            ? _c("GroupUserCreateDialog", {
                attrs: {
                  isEdit: _vm.isEdit,
                  companyList: _vm.companyList,
                  groupInfo: _vm.editItem,
                  roleItems: _vm.roleItems
                },
                on: {
                  "update:isEdit": function($event) {
                    _vm.isEdit = $event
                  },
                  "update:is-edit": function($event) {
                    _vm.isEdit = $event
                  },
                  save: _vm.afterSave
                }
              })
            : _vm._e(),
          _vm.currentGroup
            ? [
                _c(
                  "v-col",
                  { attrs: { cols: "8" } },
                  [
                    _c(
                      "v-row",
                      { staticClass: "d-flex justify-space-between" },
                      [
                        _c("v-col", { attrs: { cols: "12" } }, [
                          _c(
                            "div",
                            { staticClass: "mb-2 d-flex align-center" },
                            [
                              _c("h2", { staticClass: "h2" }, [
                                _vm._v(_vm._s(_vm.currentGroup.name))
                              ]),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    color: "indigo",
                                    label: "",
                                    small: "",
                                    dark: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { left: "", small: "" } },
                                    [_vm._v("mdi-office-building-outline")]
                                  ),
                                  _vm._v(_vm._s(_vm.currentGroup.company.Name))
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "pt-0", attrs: { cols: "8" } },
                          [
                            _c(
                              "v-simple-table",
                              {
                                staticClass: "elevation-1",
                                attrs: { "fixed-header": "", height: "540px" }
                              },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [_vm._v("ID")]),
                                    _c("th", [_vm._v("ロール")]),
                                    _c("th", [_vm._v("店舗")])
                                  ])
                                ]),
                                _vm.currentGroup.users
                                  ? _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.currentGroup.users.items,
                                        function(item) {
                                          return _c("tr", { key: item.Email }, [
                                            _c("td", [
                                              _vm._v(_vm._s(item.Email))
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getRoleLabel(
                                                    item.CognitoGroup
                                                  )
                                                )
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  item.shop
                                                    ? item.shop.Name
                                                    : ""
                                                )
                                              )
                                            ])
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pt-0", attrs: { cols: "4" } },
                          [
                            _c(
                              "v-simple-table",
                              {
                                staticClass: "elevation-1",
                                attrs: { "fixed-header": "", height: "540px" }
                              },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [_vm._v("ターミナルグループ")])
                                  ])
                                ]),
                                _vm.currentGroup.relTerminalGroups
                                  ? _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.currentGroup.relTerminalGroups.items.filter(
                                          function(e) {
                                            return e
                                          }
                                        ),
                                        function(item) {
                                          return _c(
                                            "tr",
                                            {
                                              key: item.Id,
                                              on: {
                                                click: function($event) {
                                                  return _vm.$router.push({
                                                    path: "/group/device",
                                                    query: { group: item.Id }
                                                  })
                                                }
                                              }
                                            },
                                            [
                                              _c("td", [
                                                _vm._v(_vm._s(item.Name))
                                              ])
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }