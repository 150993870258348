
import {
  getAllTerminalGroups, 
  ListTerminalGroups,
} from '@/interface/graphql/group/terminalGroup'

export const  getPrincipalTerminalGroup = async (companyId: string, shopId: string): Promise<ListTerminalGroups[number]> => {
  const principalGroupIdPrefix = `Principal&&${companyId}&&${shopId}&&`;
  const groups = await getAllTerminalGroups({filter: {Id: { beginsWith: principalGroupIdPrefix}}})
  if (groups.length === 0){
    throw Error(`[getPrincipalGroup]CompanyId:${companyId}, ShopId:${shopId} Terminal group not found.`)
  }
  return groups[0]
}