
import {
  getTerminalGroupById,
  updateTerminalGroupById,
} from "@/interface/graphql/group/terminalGroup"

/** [端末グループ] 指定のユーザグループの紐づけを解除する */
export const deleteUserGroup = async (groupId: string, userGroupId: string): Promise<void> => {
  const targetGroup = await getTerminalGroupById({Id: groupId});
  if (!targetGroup?.RelGroups) return;
  if (targetGroup.RelGroups.includes(userGroupId)){
    const terminalGroups = targetGroup.RelGroups.filter(
      (x) => {
        return x !== null && (x !== userGroupId);
      }
    );
    console.log("deleteUserGroup", groupId, userGroupId, targetGroup.RelGroups, terminalGroups);
    await updateTerminalGroupById({
      input: {
        Id: targetGroup.Id,
        RelGroups: terminalGroups,
        Members: targetGroup.Members ?? {Terminals: null},
      }
    });
  }
}