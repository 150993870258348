/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($Id: ID!) {
    getUser(Id: $Id) {
      Id
      Email
      CompanyId
      ShopId
      GroupId
      AffiliatedGroups {
        Principal
        Secondary
      }
      isDelete
      CognitoGroup
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      shop {
        Id
        CompanyId
        Name
        DisplayName
        Terminals {
          Id
          Kind
        }
        StreetAddress
        PhoneNo
        isDelete
        CreatedAt
        UpdatedAt
        owner
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
      }
      MaintenanceCardId
      LastLoginDate
      CreatedAt
      UpdatedAt
      relUserGroup {
        Id
        MemberKind
        Name
        CompanyId
        ShopId
        Members {
          Users
        }
        RelGroups
        CreatedAt
        UpdatedAt
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        users {
          items {
            Id
            Email
            CompanyId
            ShopId
            GroupId
            AffiliatedGroups {
              Principal
              Secondary
            }
            isDelete
            CognitoGroup
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            shop {
              Id
              CompanyId
              Name
              DisplayName
              Terminals {
                Id
                Kind
              }
              StreetAddress
              PhoneNo
              isDelete
              CreatedAt
              UpdatedAt
              owner
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
            }
            MaintenanceCardId
            LastLoginDate
            CreatedAt
            UpdatedAt
            relUserGroup {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              Members {
                Users
              }
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              users {
                nextToken
              }
              relTerminalGroups {
                nextToken
              }
            }
          }
          nextToken
        }
        relTerminalGroups {
          items {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Terminals {
                Id
                Kind
              }
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            terminals {
              items {
                Id
                Kind
                CompanyId
                ShopId
                GroupId
                isDelete
                DisplayName
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relUserGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Id
        Email
        CompanyId
        ShopId
        GroupId
        AffiliatedGroups {
          Principal
          Secondary
        }
        isDelete
        CognitoGroup
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        shop {
          Id
          CompanyId
          Name
          DisplayName
          Terminals {
            Id
            Kind
          }
          StreetAddress
          PhoneNo
          isDelete
          CreatedAt
          UpdatedAt
          owner
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
        }
        MaintenanceCardId
        LastLoginDate
        CreatedAt
        UpdatedAt
        relUserGroup {
          Id
          MemberKind
          Name
          CompanyId
          ShopId
          Members {
            Users
          }
          RelGroups
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          users {
            items {
              Id
              Email
              CompanyId
              ShopId
              GroupId
              AffiliatedGroups {
                Principal
                Secondary
              }
              isDelete
              CognitoGroup
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              shop {
                Id
                CompanyId
                Name
                DisplayName
                StreetAddress
                PhoneNo
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              MaintenanceCardId
              LastLoginDate
              CreatedAt
              UpdatedAt
              relUserGroup {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
            }
            nextToken
          }
          relTerminalGroups {
            items {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              terminals {
                nextToken
              }
              relUserGroups {
                nextToken
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($Id: ID!) {
    getCompany(Id: $Id) {
      Id
      Name
      DisplayName
      Shops
      GeneralConfigs {
        TaxCalcMathod
      }
      UseStatus {
        TopUp
        TicketKiosk
      }
      SubSystemConfigs {
        Common {
          Shiagel {
            AuthCode
            Use
          }
        }
        TicketKiosk {
          Fingo {
            Use
          }
          Coupon {
            CalcMethod
          }
        }
      }
      isDelete
      CreatedAt
      UpdatedAt
      owner
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($Id: ID!) {
    getShop(Id: $Id) {
      Id
      CompanyId
      Name
      DisplayName
      Terminals {
        Id
        Kind
      }
      StreetAddress
      PhoneNo
      isDelete
      CreatedAt
      UpdatedAt
      owner
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
    }
  }
`;
export const listShops = /* GraphQL */ `
  query ListShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Id
        CompanyId
        Name
        DisplayName
        Terminals {
          Id
          Kind
        }
        StreetAddress
        PhoneNo
        isDelete
        CreatedAt
        UpdatedAt
        owner
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const getUserGroup = /* GraphQL */ `
  query GetUserGroup($Id: ID!) {
    getUserGroup(Id: $Id) {
      Id
      MemberKind
      Name
      CompanyId
      ShopId
      Members {
        Users
      }
      RelGroups
      CreatedAt
      UpdatedAt
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      users {
        items {
          Id
          Email
          CompanyId
          ShopId
          GroupId
          AffiliatedGroups {
            Principal
            Secondary
          }
          isDelete
          CognitoGroup
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          shop {
            Id
            CompanyId
            Name
            DisplayName
            Terminals {
              Id
              Kind
            }
            StreetAddress
            PhoneNo
            isDelete
            CreatedAt
            UpdatedAt
            owner
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
          }
          MaintenanceCardId
          LastLoginDate
          CreatedAt
          UpdatedAt
          relUserGroup {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Users
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            users {
              items {
                Id
                Email
                CompanyId
                ShopId
                GroupId
                isDelete
                CognitoGroup
                MaintenanceCardId
                LastLoginDate
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relTerminalGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      relTerminalGroups {
        items {
          Id
          MemberKind
          Name
          CompanyId
          ShopId
          Members {
            Terminals {
              Id
              Kind
            }
          }
          RelGroups
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          terminals {
            items {
              Id
              Kind
              CompanyId
              ShopId
              GroupId
              AffiliatedGroups {
                Principal
                Secondary
              }
              isDelete
              DisplayName
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              shop {
                Id
                CompanyId
                Name
                DisplayName
                StreetAddress
                PhoneNo
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              relTerminalGroup {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
            }
            nextToken
          }
          relUserGroups {
            items {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              Members {
                Users
              }
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              users {
                nextToken
              }
              relTerminalGroups {
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listUserGroups = /* GraphQL */ `
  query ListUserGroups(
    $filter: ModelUserGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Id
        MemberKind
        Name
        CompanyId
        ShopId
        Members {
          Users
        }
        RelGroups
        CreatedAt
        UpdatedAt
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        users {
          items {
            Id
            Email
            CompanyId
            ShopId
            GroupId
            AffiliatedGroups {
              Principal
              Secondary
            }
            isDelete
            CognitoGroup
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            shop {
              Id
              CompanyId
              Name
              DisplayName
              Terminals {
                Id
                Kind
              }
              StreetAddress
              PhoneNo
              isDelete
              CreatedAt
              UpdatedAt
              owner
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
            }
            MaintenanceCardId
            LastLoginDate
            CreatedAt
            UpdatedAt
            relUserGroup {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              Members {
                Users
              }
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              users {
                nextToken
              }
              relTerminalGroups {
                nextToken
              }
            }
          }
          nextToken
        }
        relTerminalGroups {
          items {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Terminals {
                Id
                Kind
              }
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            terminals {
              items {
                Id
                Kind
                CompanyId
                ShopId
                GroupId
                isDelete
                DisplayName
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relUserGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTerminalGroup = /* GraphQL */ `
  query GetTerminalGroup($Id: ID!) {
    getTerminalGroup(Id: $Id) {
      Id
      MemberKind
      Name
      CompanyId
      ShopId
      Members {
        Terminals {
          Id
          Kind
        }
      }
      RelGroups
      CreatedAt
      UpdatedAt
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      terminals {
        items {
          Id
          Kind
          CompanyId
          ShopId
          GroupId
          AffiliatedGroups {
            Principal
            Secondary
          }
          isDelete
          DisplayName
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          shop {
            Id
            CompanyId
            Name
            DisplayName
            Terminals {
              Id
              Kind
            }
            StreetAddress
            PhoneNo
            isDelete
            CreatedAt
            UpdatedAt
            owner
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
          }
          relTerminalGroup {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Terminals {
                Id
                Kind
              }
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            terminals {
              items {
                Id
                Kind
                CompanyId
                ShopId
                GroupId
                isDelete
                DisplayName
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relUserGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      relUserGroups {
        items {
          Id
          MemberKind
          Name
          CompanyId
          ShopId
          Members {
            Users
          }
          RelGroups
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          users {
            items {
              Id
              Email
              CompanyId
              ShopId
              GroupId
              AffiliatedGroups {
                Principal
                Secondary
              }
              isDelete
              CognitoGroup
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              shop {
                Id
                CompanyId
                Name
                DisplayName
                StreetAddress
                PhoneNo
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              MaintenanceCardId
              LastLoginDate
              CreatedAt
              UpdatedAt
              relUserGroup {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
            }
            nextToken
          }
          relTerminalGroups {
            items {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              terminals {
                nextToken
              }
              relUserGroups {
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listTerminalGroups = /* GraphQL */ `
  query ListTerminalGroups(
    $filter: ModelTerminalGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerminalGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        Id
        MemberKind
        Name
        CompanyId
        ShopId
        Members {
          Terminals {
            Id
            Kind
          }
        }
        RelGroups
        CreatedAt
        UpdatedAt
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        terminals {
          items {
            Id
            Kind
            CompanyId
            ShopId
            GroupId
            AffiliatedGroups {
              Principal
              Secondary
            }
            isDelete
            DisplayName
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            shop {
              Id
              CompanyId
              Name
              DisplayName
              Terminals {
                Id
                Kind
              }
              StreetAddress
              PhoneNo
              isDelete
              CreatedAt
              UpdatedAt
              owner
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
            }
            relTerminalGroup {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              terminals {
                nextToken
              }
              relUserGroups {
                nextToken
              }
            }
          }
          nextToken
        }
        relUserGroups {
          items {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Users
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            users {
              items {
                Id
                Email
                CompanyId
                ShopId
                GroupId
                isDelete
                CognitoGroup
                MaintenanceCardId
                LastLoginDate
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relTerminalGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTerminal = /* GraphQL */ `
  query GetTerminal($Id: ID!, $Kind: TerminalKind!) {
    getTerminal(Id: $Id, Kind: $Kind) {
      Id
      Kind
      CompanyId
      ShopId
      GroupId
      AffiliatedGroups {
        Principal
        Secondary
      }
      isDelete
      DisplayName
      CreatedAt
      UpdatedAt
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      shop {
        Id
        CompanyId
        Name
        DisplayName
        Terminals {
          Id
          Kind
        }
        StreetAddress
        PhoneNo
        isDelete
        CreatedAt
        UpdatedAt
        owner
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
      }
      relTerminalGroup {
        Id
        MemberKind
        Name
        CompanyId
        ShopId
        Members {
          Terminals {
            Id
            Kind
          }
        }
        RelGroups
        CreatedAt
        UpdatedAt
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        terminals {
          items {
            Id
            Kind
            CompanyId
            ShopId
            GroupId
            AffiliatedGroups {
              Principal
              Secondary
            }
            isDelete
            DisplayName
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            shop {
              Id
              CompanyId
              Name
              DisplayName
              Terminals {
                Id
                Kind
              }
              StreetAddress
              PhoneNo
              isDelete
              CreatedAt
              UpdatedAt
              owner
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
            }
            relTerminalGroup {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              terminals {
                nextToken
              }
              relUserGroups {
                nextToken
              }
            }
          }
          nextToken
        }
        relUserGroups {
          items {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Users
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            users {
              items {
                Id
                Email
                CompanyId
                ShopId
                GroupId
                isDelete
                CognitoGroup
                MaintenanceCardId
                LastLoginDate
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relTerminalGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const listTerminals = /* GraphQL */ `
  query ListTerminals(
    $Id: ID
    $Kind: ModelStringKeyConditionInput
    $filter: ModelTerminalFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTerminals(
      Id: $Id
      Kind: $Kind
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        Id
        Kind
        CompanyId
        ShopId
        GroupId
        AffiliatedGroups {
          Principal
          Secondary
        }
        isDelete
        DisplayName
        CreatedAt
        UpdatedAt
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        shop {
          Id
          CompanyId
          Name
          DisplayName
          Terminals {
            Id
            Kind
          }
          StreetAddress
          PhoneNo
          isDelete
          CreatedAt
          UpdatedAt
          owner
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
        }
        relTerminalGroup {
          Id
          MemberKind
          Name
          CompanyId
          ShopId
          Members {
            Terminals {
              Id
              Kind
            }
          }
          RelGroups
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          terminals {
            items {
              Id
              Kind
              CompanyId
              ShopId
              GroupId
              AffiliatedGroups {
                Principal
                Secondary
              }
              isDelete
              DisplayName
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              shop {
                Id
                CompanyId
                Name
                DisplayName
                StreetAddress
                PhoneNo
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              relTerminalGroup {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
            }
            nextToken
          }
          relUserGroups {
            items {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              Members {
                Users
              }
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              users {
                nextToken
              }
              relTerminalGroups {
                nextToken
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const userByGroup = /* GraphQL */ `
  query UserByGroup(
    $GroupId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByGroup(
      GroupId: $GroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        Email
        CompanyId
        ShopId
        GroupId
        AffiliatedGroups {
          Principal
          Secondary
        }
        isDelete
        CognitoGroup
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        shop {
          Id
          CompanyId
          Name
          DisplayName
          Terminals {
            Id
            Kind
          }
          StreetAddress
          PhoneNo
          isDelete
          CreatedAt
          UpdatedAt
          owner
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
        }
        MaintenanceCardId
        LastLoginDate
        CreatedAt
        UpdatedAt
        relUserGroup {
          Id
          MemberKind
          Name
          CompanyId
          ShopId
          Members {
            Users
          }
          RelGroups
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          users {
            items {
              Id
              Email
              CompanyId
              ShopId
              GroupId
              AffiliatedGroups {
                Principal
                Secondary
              }
              isDelete
              CognitoGroup
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              shop {
                Id
                CompanyId
                Name
                DisplayName
                StreetAddress
                PhoneNo
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              MaintenanceCardId
              LastLoginDate
              CreatedAt
              UpdatedAt
              relUserGroup {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
            }
            nextToken
          }
          relTerminalGroups {
            items {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              terminals {
                nextToken
              }
              relUserGroups {
                nextToken
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const userByGroupWithCompanyAndDeleteStatus = /* GraphQL */ `
  query UserByGroupWithCompanyAndDeleteStatus(
    $GroupId: ID
    $companyIdIsDelete: ModelUserUserByGroupWithCompanyAndDeleteStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByGroupWithCompanyAndDeleteStatus(
      GroupId: $GroupId
      companyIdIsDelete: $companyIdIsDelete
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        Email
        CompanyId
        ShopId
        GroupId
        AffiliatedGroups {
          Principal
          Secondary
        }
        isDelete
        CognitoGroup
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        shop {
          Id
          CompanyId
          Name
          DisplayName
          Terminals {
            Id
            Kind
          }
          StreetAddress
          PhoneNo
          isDelete
          CreatedAt
          UpdatedAt
          owner
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
        }
        MaintenanceCardId
        LastLoginDate
        CreatedAt
        UpdatedAt
        relUserGroup {
          Id
          MemberKind
          Name
          CompanyId
          ShopId
          Members {
            Users
          }
          RelGroups
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          users {
            items {
              Id
              Email
              CompanyId
              ShopId
              GroupId
              AffiliatedGroups {
                Principal
                Secondary
              }
              isDelete
              CognitoGroup
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              shop {
                Id
                CompanyId
                Name
                DisplayName
                StreetAddress
                PhoneNo
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              MaintenanceCardId
              LastLoginDate
              CreatedAt
              UpdatedAt
              relUserGroup {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
            }
            nextToken
          }
          relTerminalGroups {
            items {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              terminals {
                nextToken
              }
              relUserGroups {
                nextToken
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const userByDeleteStatusWithCompany = /* GraphQL */ `
  query UserByDeleteStatusWithCompany(
    $isDelete: String
    $CompanyId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByDeleteStatusWithCompany(
      isDelete: $isDelete
      CompanyId: $CompanyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        Email
        CompanyId
        ShopId
        GroupId
        AffiliatedGroups {
          Principal
          Secondary
        }
        isDelete
        CognitoGroup
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        shop {
          Id
          CompanyId
          Name
          DisplayName
          Terminals {
            Id
            Kind
          }
          StreetAddress
          PhoneNo
          isDelete
          CreatedAt
          UpdatedAt
          owner
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
        }
        MaintenanceCardId
        LastLoginDate
        CreatedAt
        UpdatedAt
        relUserGroup {
          Id
          MemberKind
          Name
          CompanyId
          ShopId
          Members {
            Users
          }
          RelGroups
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          users {
            items {
              Id
              Email
              CompanyId
              ShopId
              GroupId
              AffiliatedGroups {
                Principal
                Secondary
              }
              isDelete
              CognitoGroup
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              shop {
                Id
                CompanyId
                Name
                DisplayName
                StreetAddress
                PhoneNo
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              MaintenanceCardId
              LastLoginDate
              CreatedAt
              UpdatedAt
              relUserGroup {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
            }
            nextToken
          }
          relTerminalGroups {
            items {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              terminals {
                nextToken
              }
              relUserGroups {
                nextToken
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getCompanyByDeleteStatus = /* GraphQL */ `
  query GetCompanyByDeleteStatus(
    $isDelete: String
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCompanyByDeleteStatus(
      isDelete: $isDelete
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getShopByDeleteStatus = /* GraphQL */ `
  query GetShopByDeleteStatus(
    $isDelete: String
    $sortDirection: ModelSortDirection
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getShopByDeleteStatus(
      isDelete: $isDelete
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        CompanyId
        Name
        DisplayName
        Terminals {
          Id
          Kind
        }
        StreetAddress
        PhoneNo
        isDelete
        CreatedAt
        UpdatedAt
        owner
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
      }
      nextToken
    }
  }
`;
export const terminalbyTerminalKind = /* GraphQL */ `
  query TerminalbyTerminalKind(
    $Kind: TerminalKind
    $isDelete: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTerminalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    terminalbyTerminalKind(
      Kind: $Kind
      isDelete: $isDelete
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        Kind
        CompanyId
        ShopId
        GroupId
        AffiliatedGroups {
          Principal
          Secondary
        }
        isDelete
        DisplayName
        CreatedAt
        UpdatedAt
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        shop {
          Id
          CompanyId
          Name
          DisplayName
          Terminals {
            Id
            Kind
          }
          StreetAddress
          PhoneNo
          isDelete
          CreatedAt
          UpdatedAt
          owner
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
        }
        relTerminalGroup {
          Id
          MemberKind
          Name
          CompanyId
          ShopId
          Members {
            Terminals {
              Id
              Kind
            }
          }
          RelGroups
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          terminals {
            items {
              Id
              Kind
              CompanyId
              ShopId
              GroupId
              AffiliatedGroups {
                Principal
                Secondary
              }
              isDelete
              DisplayName
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              shop {
                Id
                CompanyId
                Name
                DisplayName
                StreetAddress
                PhoneNo
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              relTerminalGroup {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
            }
            nextToken
          }
          relUserGroups {
            items {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              Members {
                Users
              }
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              users {
                nextToken
              }
              relTerminalGroups {
                nextToken
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const terminalByGroup = /* GraphQL */ `
  query TerminalByGroup(
    $GroupId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTerminalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    terminalByGroup(
      GroupId: $GroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        Kind
        CompanyId
        ShopId
        GroupId
        AffiliatedGroups {
          Principal
          Secondary
        }
        isDelete
        DisplayName
        CreatedAt
        UpdatedAt
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        shop {
          Id
          CompanyId
          Name
          DisplayName
          Terminals {
            Id
            Kind
          }
          StreetAddress
          PhoneNo
          isDelete
          CreatedAt
          UpdatedAt
          owner
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
        }
        relTerminalGroup {
          Id
          MemberKind
          Name
          CompanyId
          ShopId
          Members {
            Terminals {
              Id
              Kind
            }
          }
          RelGroups
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          terminals {
            items {
              Id
              Kind
              CompanyId
              ShopId
              GroupId
              AffiliatedGroups {
                Principal
                Secondary
              }
              isDelete
              DisplayName
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              shop {
                Id
                CompanyId
                Name
                DisplayName
                StreetAddress
                PhoneNo
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              relTerminalGroup {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
            }
            nextToken
          }
          relUserGroups {
            items {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              Members {
                Users
              }
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              users {
                nextToken
              }
              relTerminalGroups {
                nextToken
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const terminalById = /* GraphQL */ `
  query TerminalById(
    $Id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTerminalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    terminalById(
      Id: $Id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Id
        Kind
        CompanyId
        ShopId
        GroupId
        AffiliatedGroups {
          Principal
          Secondary
        }
        isDelete
        DisplayName
        CreatedAt
        UpdatedAt
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        shop {
          Id
          CompanyId
          Name
          DisplayName
          Terminals {
            Id
            Kind
          }
          StreetAddress
          PhoneNo
          isDelete
          CreatedAt
          UpdatedAt
          owner
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
        }
        relTerminalGroup {
          Id
          MemberKind
          Name
          CompanyId
          ShopId
          Members {
            Terminals {
              Id
              Kind
            }
          }
          RelGroups
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          terminals {
            items {
              Id
              Kind
              CompanyId
              ShopId
              GroupId
              AffiliatedGroups {
                Principal
                Secondary
              }
              isDelete
              DisplayName
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              shop {
                Id
                CompanyId
                Name
                DisplayName
                StreetAddress
                PhoneNo
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              relTerminalGroup {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
            }
            nextToken
          }
          relUserGroups {
            items {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              Members {
                Users
              }
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              users {
                nextToken
              }
              relTerminalGroups {
                nextToken
              }
            }
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listSystemOperationHistories = /* GraphQL */ `
  query ListSystemOperationHistories(
    $filter: SystemOperationHistoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemOperationHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        UserId
        UserMail
        TimestampMs
        ViewName
        ViewId
        OperationName
        OperationId
        Detail
        CreatedAt
      }
      nextToken
    }
  }
`;
export const getSystemPlannedMaintenanceSchedulesById = /* GraphQL */ `
  query GetSystemPlannedMaintenanceSchedulesById($ScheduleId: ID!) {
    getSystemPlannedMaintenanceSchedulesById(ScheduleId: $ScheduleId) {
      ScheduleId
      StartTimestampMs
      EndTimestampMs
      Message
      UserId
      UserName
      CreatedAt
    }
  }
`;
export const listSystemPlannedMaintenanceSchedules = /* GraphQL */ `
  query ListSystemPlannedMaintenanceSchedules(
    $filter: SystemPlannedMaintenanceSchedulesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemPlannedMaintenanceSchedules(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ScheduleId
        StartTimestampMs
        EndTimestampMs
        Message
        UserId
        UserName
        CreatedAt
      }
      nextToken
    }
  }
`;
