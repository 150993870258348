import { deleteUser } from "./deleteUser"
import { addUser } from "./addUser"
import { userService } from '../../user'

// 該当TerminalSummaryと一致するTerminalのグループIDを更新し
export const deleteUserAndUserGroup = async(userId: string): Promise<void> => {
  console.log(`[deleteUserAndUserGroup]Target userId: ${userId}`);
  const deleteGroupResult = await userService.deleteGroup(userId);
  console.log('[deleteUserAndUserGroup]TargetResult ', deleteGroupResult);
  if (!deleteGroupResult) return;
  // 変更前のグループから端末の削除
  await addUser(deleteGroupResult.newGroupId, userId);
  if (
    deleteGroupResult.oldGroupId &&
    deleteGroupResult.oldGroupId !== deleteGroupResult.newGroupId
  ) {
    await deleteUser(deleteGroupResult.oldGroupId, userId);
  }
}