







































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { formatEpochMilliSec } from "@/tools/datetime";

/** システム操作履歴画面 */
@Component
export default class LogsOperation extends Vue {
  @Action("systemOperationHistories/getSystemOperationHistories")
  getSystemOperationHistories!: () => void;
  @Getter("systemOperationHistories/SystemOperationHistories")
  sysOperationHistories!: any;
  search = "";
  headers = [
    { text: "ユーザID", value: "UserMail" },
    { text: "画面", value: "ViewName" },
    { text: "操作", value: "OperationName" },
    { text: "詳細", value: "Detail", width: "20%" },
    { text: "操作日時", value: "CreatedAt" },
  ];

  async created() {
    // this.getSysMalfuncHistories();
    // this.getSystemOperationHistories();
  }

  async mounted() {
    this.getSystemOperationHistories();
  }

  paraseDetails(item: any): string[] {
    if (!item.Detail) {
      return [];
    }
    const detail = JSON.parse(item.Detail);
    const ip = detail.clientIpAddress || "不明";
    switch (item.OperationId) {
      case "login":
        return [`利用者IP: ${ip}`, `メールアドレス: ${detail.email}`];
      case "startFunctionRestriction":
        return [`利用者IP: ${ip}`, `端末ID: ${detail.message}`];
      case "accountCreate":
        return [`利用者IP: ${ip}`, `メールアドレス: ${detail.createdUserId}`];
      case "accountUpdate":
        return [`利用者IP: ${ip}`, `メールアドレス: ${detail.updatedUserId}`];
      case "accountDelete":
        return [`利用者IP: ${ip}`, `メールアドレス: ${detail.deletedUserId}`];
      case "companyCreate":
      case "companyUpdate":
      case "companyDelete":
        return [`利用者IP: ${ip}`, `企業名: ${detail.companyName}`, `企業ID: ${detail.companyId}`];
      case "shopCreate":
      case "shopUpdate":
      case "shopDelete":
        return [`利用者IP: ${ip}`, `店舗名: ${detail.shopName}`, `店舗ID: ${detail.shopId}`];
      case "groupDeviceCreate":
      case "groupDeviceUpdate":
      case "groupUserCreate":
      case "groupUserUpdate":
        return [`利用者IP: ${ip}`, `グループ名: ${detail.groupName}`, `グループID: ${detail.groupId}`];
      case "addTicketTerminal":
      case "editTicketTerminal":
      case "deleteTicketTerminal":
        return [`利用者IP: ${ip}`, `端末名: ${detail.terminalDisplayName}`, `端末ID: ${detail.terminalId}`];
      case "addTopupTerminal":
      case "editTopupTerminal":
      case "deleteTopupTerminal":
        return [`利用者IP: ${ip}`, `端末名: ${detail.terminalDisplayName}`, `端末ID: ${detail.terminalId}`];
      default:
        return [`利用者IP: ${ip}`];
    }
  }

  /** UTCエポックミリ秒をフォーマットする(日付のみ) */
  unixMsToDate(ms: number): string { 
    return formatEpochMilliSec(ms, true); // 時刻あり
  }
}
