var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c(
      "div",
      { attrs: { id: "app" } },
      [
        this.$route.name != "Login" &&
        this.$route.name != "Login-ForgotPassword" &&
        this.$route.name != "Login-PasswordReset" &&
        this.$route.name != "Login-ForcePasswordChange"
          ? _c("Navbar")
          : _vm._e(),
        _c(
          "v-main",
          [_c("v-container", { attrs: { fluid: "" } }, [_c("router-view")], 1)],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }