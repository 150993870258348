










import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { CompanyDeleteDialogINputType } from "@/types";
import { Action, Getter } from "vuex-class";
import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";

@Component
export default class CompanyDeleteDialog extends Vue {
  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;
  @Getter("auth/dbUser") dbUser!: any;

  // props
  @Prop() companyInfo!: CompanyDeleteDialogINputType;
  @PropSync("isDelete", { type: Boolean }) isDeleteValue!: boolean;
  // data
  errorMessage: string | null = null;
  // method
  async deleteCompany() {
    try {
      await this.$store.dispatch("company/deleteCompany", this.companyInfo);
      await this.loggingOperationHistory({
        user: this.dbUser,
        viewName: "企業削除ダイアログ",
        viewId: "CompanyDeleteDialog",
        operationName: "会社削除",
        operationId: "companyDelete",
        detail: {
          companyName: this.companyInfo.Name,
          companyId: this.companyInfo.Id,
        },
      });
      await this.$store.dispatch("company/fetchCompanies");
      this.close();
    } catch (e) {
      this.errorMessage = "削除時にエラーが発生しました";
    }
  }

  close() {
    this.isDeleteValue = false;
  }
}
