



















































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import {CompanyDetailDialogINputType} from '@/types'
@Component
export default class CompanyDetailDialog extends Vue {
    @Prop() companyInfo!: CompanyDetailDialogINputType
    @PropSync('isDetail', {type: Boolean}) isDetailValue!: boolean
    // data
    errorMessage: string | null = null
    taxCalcMathodList = [
        {value: 0, text: '四捨五入'},
        {value: 1, text: '切り上げ'},
        {value: 2, text: '切り捨て'},
    ]
    couponCalcMathodList = [
        {value: 0, text: '四捨五入'},
        {value: 1, text: '切り上げ'},
        {value: 2, text: '切り捨て'},
    ]
    // method
    taxCalcMathodText(value: number) {
      const target = this.taxCalcMathodList.find((e: any)=>(e.value===value))
      return target ? target.text : ''
    }
    couponCalcMathodText(value: number) {
      const target = this.couponCalcMathodList.find((e: any)=>(e.value===value))
      return target ? target.text : ''
    }
    async cancelDeleteCompany() {
      try {
        await this.$store.dispatch('company/cancelDeleteCompany', this.companyInfo)            
        await this.$store.dispatch('company/fetchCompanies')
        this.isDetailValue = false
      }
      catch (e) {
        this.errorMessage = '戻すにエラーが発生しました'
      }
    }
    
}
