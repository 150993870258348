import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';

import { getUserById, NullableUser } from '@/interface/graphql/user/user'
import { AuthState, AuthAction, UserAuth } from './type'


export const getJwt = async () => {
  const authSession = await Auth.currentSession()
  const accessToken = authSession.getIdToken();
  const jwt = accessToken.getJwtToken();
  return jwt;
};

export const auth = {
  namespaced: true,
  state: {
    user: null,
    dbUser: null,
    authedUser: null,
    recoveryTarget: null,
  },
  mutations: {
    setUser(state: AuthState, payload: any) {
      state.user = payload;
    },
    setDbUser(state: AuthState, payload: NullableUser) {
      state.dbUser = payload;
    },
    setAuthedUser(state: AuthState, payload: any) {
      state.authedUser = payload;
    },
    setRecoveryTarget(state: AuthState, payload: any) {
      state.recoveryTarget = payload;
    },
  },
  actions: {
    async logout({ commit }: AuthAction) {
      await commit('setUser', null);
      return await Auth.signOut();
    },
    async login({ commit }: any, { username, password }: UserAuth) {
      try {
        const user = await Auth.signIn({ username, password });
        // 初回ログイン
        if(user.challengeName==='NEW_PASSWORD_REQUIRED') {
          commit('setRecoveryTarget', username);
          throw {code: 'NEW_PASSWORD_REQUIRED'}
        }
        const userInfo = await Auth.currentUserInfo();
        const authedUser = await Auth.currentAuthenticatedUser();
        commit('setUser', userInfo);
        commit('setAuthedUser', authedUser);
        const dbUser = await getUserById({Id: authedUser.username})
        commit('setDbUser', dbUser);
  
        return Promise.resolve('Success');
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
    async authAction({ commit, getters }: AuthAction) {
      const userInfo = await Auth.currentUserInfo();
      const authedUser = await Auth.currentAuthenticatedUser();
      commit('setUser', userInfo);
      commit('setAuthedUser', authedUser)
      const dbUser = await getUserById({Id: getters.id})
      commit('setDbUser', dbUser);
    },
    // パスワードリセットの開始
    // 確認コードをCognitoから送信
    async recoveryInitiate ({commit}: AuthAction, email: any) {
      try {
        await Auth.forgotPassword(email);
        commit('setRecoveryTarget', email);
      } catch (error) {
        console.log(error)
      }
    },
    async changePassword ({rootGetters}: AuthAction, payload: any) {
      try {
        const username = rootGetters["auth/user"]["username"]
        const password = payload.password
        const user = await Auth.signIn(username, password);
        if(!user) {
          throw { "code" : 'AUTH_ERROR' }
        }
        const settings = await Auth.currentCredentials();
        const userPool = await Auth.currentUserPoolUser();
        // set aws credentials
        const auth = {
          region: process.env.VUE_APP_API_AWS_REGION || 'ap-northeast-1'          , 
          accessKeyId: settings.accessKeyId,        
          secretAccessKey: settings.secretAccessKey,
          sessionToken: settings.sessionToken
        };
        const CognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider(auth);
        
        const params: any = {
          UserPoolId: userPool.pool.userPoolId,
          Username: payload.Email,
        };

        await CognitoIdentityServiceProvider.adminResetUserPassword(params).promise();
      } catch (error) {
        console.log(error)
        throw error;
      }
    },
    // パスワードのリセット
    async recoveryAccount ({state}: AuthAction, payload: any) {
      try {
      await Auth.forgotPasswordSubmit(state.recoveryTarget, payload.verificationCode, payload.password)
      } catch (error) {
        console.log(error)
        throw error
      }
    },
    // 初回ログイン時のパスワード変更
    async forcePasswordChange ({commit, state}: AuthAction, payload: any) {
      try {
        const username = state.recoveryTarget
        const oldPassword = payload.oldPassword
        const user = await Auth.signIn(username, oldPassword);
        if(user.challengeName!=='NEW_PASSWORD_REQUIRED') {
          throw 'NOT_RESET'
        }
        await Auth.completeNewPassword(user, payload.password);
        // 新しいパスワードでログイン処理
        await Auth.signIn(username, payload.password);
        const authedUser = await Auth.currentAuthenticatedUser();
        const userInfo = await Auth.currentUserInfo();
        commit('setUser', userInfo);
        commit('setAuthedUser', authedUser);
      }
      catch(e) {
        console.log(e)
        throw new Error('passwordChangeFailed')
      }
    }
  },
  getters: {
    user: (state: AuthState) => state.user,
    dbUser: (state: AuthState) => state.dbUser,
    email: (state: AuthState) => !(state.user) ? '' : state.user.attributes.email,
    userGroup: (state: AuthState) => !(state.authedUser)? '' : state.authedUser.signInUserSession.accessToken.payload['cognito:groups'][0],
    id: (state: AuthState) => !(state.authedUser)? '' : state.authedUser.username,
    companyId: (state: AuthState) => !(state.authedUser)? '' : state.authedUser.signInUserSession.idToken.payload['CompanyId'],
    shopId: (state: AuthState) => !(state.authedUser)? '' : state.authedUser.signInUserSession.idToken.payload['ShopId'],
    recoveryTarget: (state: AuthState) => state.recoveryTarget,
  },
};
