







































import { Component, Vue } from 'vue-property-decorator';
import { formatEpochSec } from "@/tools/datetime";
import CompanyEditDialog from '@/components/company/ComapnyEditDialog.vue'
import CompanyDetailDialog from '@/components/company/ComapnyDetailDialog.vue'
import CompanyDeleteDialog from '@/components/company/ComapnyDeleteDialog.vue'
import { can } from '@/acl/Acl';

@Component({
  components: {
    CompanyEditDialog,
    CompanyDetailDialog,
    CompanyDeleteDialog
  },
})
export default class CompanyList extends Vue {
  // data
  search = this.$route.query.company ? this.$route.query.company : ''
  isEdit = false
  isDetail = false
  isDelete = false
  headers = [
    { text: '企業', value: 'Name' },
    { text: 'チャージ機', value: 'UseStatus.TopUp', align: 'center' },
    { text: '券売機', value: 'UseStatus.TicketKiosk', align: 'center' },
    { text: '作成日', value: 'CreatedAt' },
    { text: '', value: 'actions', align: 'right', sortable: false }
  ]
  detailItem = null
  editItem = null
  deleteItem = null
  loading = true;
  // computed
  get companies () {
    return this.$store.getters['company/companies'];
  }
  get companyList () {
    return this.$store.getters['company/searchableCompanies'];
  }
  get includeDelete (): boolean {
    return this.$store.getters['company/includeDelete'];
  }
  set includeDelete (value) {
    this.$store.dispatch('company/setIncludeDelete', value)
  }
  get canCreate () {
    return can(this.currentRole, 'create', 'company')
  }
  get canDelete () {
    return can(this.currentRole, 'delete', 'company')
  }
  get canEdit () {
    return can(this.currentRole, 'edit', 'company')
  }
  get currentRole () {
    return this.$store.getters['auth/userGroup']
  }
  // method
  companyFilter (value: any, search: string | null, item: any): boolean {
    return item.Id === search
  }
  async initialize () {
    await this.$store.dispatch('company/fetchCompanies');
  }
  openDetail(item: any) {
    this.detailItem = Object.assign({}, item);
    this.isDetail = true;
  }
  openDelete(item: any) {
    this.deleteItem = Object.assign({}, item);
    this.isDelete = true;
  }
  openEdit(item: any) {
    if(item) {
      this.editItem = Object.assign({}, item);
    } else {
      this.editItem = null
    }
    this.isEdit = true;
  }
  changeIncludeDelete() {
    this.$store.dispatch('company/fetchCompanies')
  }
  tableClass (item: any) {
    return item.isDelete==='true' ? 'blue-grey lighten-5' : ''
  }
  /** UTCエポック秒をフォーマットする(時刻あり) */
  unixSecToDateTime(sec: number): string { 
    return formatEpochSec(sec, true);
  }
  // created
  async created () {
    this.loading = true;
    await this.initialize();
    this.loading = false;
  }
  // beforeDestroy
  beforeDestroy () {
    this.includeDelete = false
  }
}
