































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PasswordReset extends Vue {
  resetInput = {
    verificationCode: '',
    password: '',
    passwordConfirm:''
  }
  async submit() {
    try {
      const result = await this.$store.dispatch('auth/recoveryAccount', this.resetInput)
      this.$router.push('/login')
    } catch (error) {
      console.log(error)
    }
  }
  get recoveryTarget () {
    return this.$store.getters['auth/recoveryTarget'];
  }
}
