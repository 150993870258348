import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './auth/auth';
import { company } from './company/company';
import { shop } from './shop/shop';
import { group } from './group/group';
import { user } from "./user/user";
import { terminal } from "./terminal/terminal";
import { acl } from './acl/acl';
import { systemOperationHistories } from './SystemOperationHistories/systemOperationHistories';
import { systemPlannedMaintenanceSchedules } from './SystemPlannedMaintenanceSchedules/systemPlannedMaintenanceSchedules';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { auth, company, shop, group, user, terminal, acl, systemOperationHistories, systemPlannedMaintenanceSchedules },
});