












































































import { Component, Vue, Prop } from "vue-property-decorator";
import { can } from "@/acl/Acl";
import { formatEpochSec } from "@/tools/datetime";
import ShopEditDialog from "@/components/shop/ShopEditDialog.vue";
import ShopDetailDialog from "@/components/shop/ShopDetailDialog.vue";
import ShopDeleteDialog from "@/components/shop/ShopDeleteDialog.vue";

@Component({
  components: {
    ShopEditDialog,
    ShopDetailDialog,
    ShopDeleteDialog,
  },
})
export default class ShopList extends Vue {
  // data
  search = this.$route.query.name ? this.$route.query.name : null;
  searchCompany = this.$route.query.company ? this.$route.query.company : null;
  isEdit = false;
  isDetail = false;
  isDelete = false;
  headers = [
    { text: "店舗", value: "Name", filter: this.shopNameFilter },
    { text: "企業", value: "company.Name", filter: this.shopCompanyFilter },
    { text: "作成日", value: "CreatedAt" },
    { text: "", value: "actions", align: "right", sortable: false },
  ];
  detailItem = null;
  editItem = null;
  deleteItem = null;
  loading = true;
  // computed
  get shops() {
    return this.$store.getters["shop/shops"];
  }
  get companyList() {
    return this.$store.getters["company/searchableCompanies"];
  }
  get includeDelete(): boolean {
    return this.$store.getters["shop/includeDelete"];
  }
  set includeDelete(value) {
    this.$store.dispatch("shop/setIncludeDelete", value);
  }
  get canCreate() {
    return can(this.currentRole, "create", "shop");
  }
  get canDelete() {
    return can(this.currentRole, "delete", "shop");
  }
  get canEdit() {
    return can(this.currentRole, "edit", "shop");
  }
  get currentRole() {
    return this.$store.getters["auth/userGroup"];
  }
  // method
  async initialize() {
    await this.$store.dispatch("company/fetchCompanies");
    await this.$store.dispatch("shop/fetchShops");
  }
  openDetail(item: any) {
    this.detailItem = Object.assign({}, item);
    this.isDetail = true;
  }
  openDelete(item: any) {
    this.deleteItem = Object.assign({}, item);
    this.isDelete = true;
  }
  openEdit(item: any) {
    if (item) {
      this.editItem = Object.assign({}, item);
    } else {
      this.editItem = null;
    }
    this.isEdit = true;
  }
  shopNameFilter(value: any, search: string | null, item: any): boolean {
    if (this.search) {
      return value.match(this.search) !== null;
    }
    return true;
  }
  shopCompanyFilter(value: any, search: any, item: any) {
    if (this.searchCompany) {
      return item.CompanyId === this.searchCompany;
    }
    return true;
  }
  changeIncludeDelete() {
    this.$store.dispatch("shop/fetchShops");
  }
  tableClass(item: any) {
    return item.isDelete === "true" ? "blue-grey lighten-5" : "";
  }
  /** UTCエポック秒をフォーマットする(時刻あり) */
  unixSecToDateTime(sec: number): string { 
    return formatEpochSec(sec, true);
  }

  // life-cycle
  async mounted() {
    this.loading = true;
    await this.initialize();
    this.loading = false;
  }
  beforeDestroy() {
    this.includeDelete = false;
  }
}
