
import {
  getUserGroupById,
  updateUserGroupById,
} from "@/interface/graphql/group/userGroup"

export const addTerminalGroup = async (groupId: string, terminalGroupId: string): Promise<void> => {
  const targetGroup = await getUserGroupById({Id: groupId});
  if (!targetGroup) return;
  const terminalGroups = targetGroup.RelGroups ? [...targetGroup.RelGroups] : [];
  if(
    !targetGroup.RelGroups ||
    !targetGroup.RelGroups.includes(terminalGroupId)
  ){
    terminalGroups.push(terminalGroupId);
    await updateUserGroupById({
      input: {
        Id: targetGroup.Id,
        RelGroups: terminalGroups,
        Members: targetGroup.Members,
      }
    });
  }
}