
import {
  updateCognitoUserById,
  getUserById,
} from '@/interface/graphql/user/user'
import { userGroupService } from '@/service/group/userGroup';

interface DeleteGroupResult{
  newGroupId: string;
  oldGroupId: string | null;
}

export const deleteGroup = async(userId: string): Promise<DeleteGroupResult | null> => {
  const user = await getUserById({
    Id: userId,
  })
  if (!user) return null;
  console.log("getUser", user);
  const targetGroupId = user.GroupId ?? null;
  // 更新前のグループIDを取得。
  const shopId = targetGroupId && targetGroupId.startsWith(`Principal&&${user.CompanyId}&&${user.ShopId}`) ? "_" : user.ShopId
  const principalGroup = await userGroupService.getPrincipalUserGroup(user.CompanyId, shopId);
  if (principalGroup.Id === targetGroupId)return null;
  await updateCognitoUserById({
    input: {
      CompanyId: user.CompanyId,
      ShopId: shopId,  
      Id: user.Id,
      Email: user.Email,
      GroupId: principalGroup.Id,
      CognitoGroup: user.CognitoGroup,
      MaintenanceCardId: user.MaintenanceCardId,
      isDelete: user.isDelete ?? "false",
    },
  });
  return {newGroupId: principalGroup.Id, oldGroupId: targetGroupId};
}