import { API } from "aws-amplify";
import { GetCompanyQuery } from "@/API";
import { getCompany } from "@/graphql/queries";
import { createCompany, updateCompany } from "@/graphql/mutations";
import { nanoid } from "nanoid";
import { CreateCompanyInput } from "@/API";
import { CreateCompanyPayload, CreatePrincipalUserGroupPayload, CreatePrincipalTerminalGroupPayload } from "@/types";
import moment from "moment";

type Company = GetCompanyQuery["getCompany"]
interface GetCompanyResult {
  data: GetCompanyQuery;
}

export const company = {
  namespaced: true,
  state: {
    companies: [] as Company[],
    includeDelete: false,
    needRefresh: false,
  },
  mutations: {
    setCompanies(state: any, payload: any): void {
      state.companies = payload;
    },
    setIncludeDelete(state: any, payload: boolean): void {
      state.includeDelete = payload;
      state.needRefresh = true; // FIXME: 削除済みを含めるかどうかは画面でやるべき
    },
    setNeedRefresh(state: any, payload: boolean): void {
      state.needRefresh = payload;
    },
  },

  actions: {
    setIncludeDelete({ commit }: any, payload: boolean) {
      commit("setIncludeDelete", payload);
    },
    async fetchCompanies({ commit, state, rootGetters, dispatch }: any) {
      if (!state.needRefresh && state.companies.length != 0) { 
        // リロード不要
        return;
      }
      // Dynamoから取得
      try {
        await dispatch('acl/fetchAcl', null, { root: true })
        const acl = rootGetters["acl/acl"];
        const companies = [];
        for (const companyId of acl.Companies){
          const company = await API.graphql({
            query: getCompany, variables:{Id: companyId}}) as GetCompanyResult;
          if (!company.data.getCompany) continue;
          if (!state.includeDelete && company.data.getCompany.isDelete === "true") continue;
          companies.push(company.data.getCompany);
        }
        console.log(companies);
        commit("setCompanies", companies);
        commit('setNeedRefresh', false);
      } catch (error) {
        console.log("error", error);
      }
    },
    async createCompany(
      { dispatch, commit, rootGetters }: any,
      payload: CreateCompanyPayload
    ) {
      // Dynamoへ保存(新規作成)
      try {
        const generatedId = nanoid(7);
        const company: CreateCompanyInput = {
          Id: generatedId,
          Name: payload.Name,
          DisplayName: payload.DisplayName,
          Shops: [],
          UseStatus: {
            TicketKiosk: payload.UseStatus.TicketKiosk,
            TopUp: payload.UseStatus.TopUp,
          },
          GeneralConfigs: {
            TaxCalcMathod: 0,
          },
          SubSystemConfigs: {
            Common: {
              Shiagel: {
                Use: payload.SubSystemConfigs.Common.Shiagel.Use,
                AuthCode: payload.SubSystemConfigs.Common.Shiagel.AuthCode,
              },
            },
            TicketKiosk: {
              Fingo: {
                Use: payload.SubSystemConfigs.TicketKiosk.Fingo.Use,
              },
              Coupon: {
                CalcMethod:
                  payload.SubSystemConfigs.TicketKiosk.Coupon.CalcMethod,
              },
            },
          },
          isDelete: "false",
          owner: rootGetters["auth/user"]["username"],
          CreatedAt: moment()
            .utc()
            .unix(),
          UpdatedAt: moment()
            .utc()
            .unix(),
        };
        const result: any = await API.graphql({
          query: createCompany,
          variables: { input: company },
        });
        // 企業Principalグループ(User, Terminal)の作成
        const groupId = nanoid(7);
        const groupKind = 'Principal'
        const newGroupId = `${groupKind}&&${generatedId}&&_&&${groupId}`

        const principalUserGroup: CreatePrincipalUserGroupPayload = {
          Id: newGroupId,
          CompanyId: generatedId,
          ShopId: "_",
          Name: payload.Name,
          RelGroups: [newGroupId]
        };
        const principalTerminalGroup: CreatePrincipalTerminalGroupPayload = {
          Id: newGroupId,
          CompanyId: generatedId,
          ShopId: "_",
          Name: payload.Name,
          RelGroups: [newGroupId]
        };
        
        await dispatch("group/createPrincipalUserGroup", principalUserGroup, {
          root: true,
        });
        await dispatch("group/createPrincipalTerminalGroup", principalTerminalGroup, {
          root: true,
        });
        commit('setNeedRefresh', true);
        // TODO: stateだけ更新する
      } catch (error) {
        console.error("error", error);
      }
    },
    async updateCompany({ commit }: any, payload: any) {
      // Dynamoへ保存(更新)
      const company = Object.assign({}, payload)
      company.UpdatedAt = moment().utc().unix() // 更新日時は必ず最新化する
      try {
        const result: any = await API.graphql({
          query: updateCompany,
          variables: { input: company },
        });
        console.log(result);
        commit('setNeedRefresh', true);
        // TODO: stateだけ更新する
      } catch (error) {
        console.log(error);
      }
    },
    async deleteCompany({ commit }: any, payload: any) {
      // 論理削除
      try {
        const company = Object.assign({}, payload)
        company.isDelete = 'true'
        company.UpdatedAt = moment().utc().unix() // 更新日時は必ず最新化する
        const result: any = await API.graphql({
          query: updateCompany,
          variables: { input: company },
        });
        console.log(result);
        commit('setNeedRefresh', true);
        // TODO: stateだけ更新する
      } catch (error) {
        console.log(error);
      }
    },
    async cancelDeleteCompany({ commit }: any, payload: any) {
      // 論理削除
      try {
        const company = Object.assign({}, payload)
        company.isDelete = 'false'
        company.UpdatedAt = moment().utc().unix() // 更新日時は必ず最新化する
        const result: any = await API.graphql({
          query: updateCompany,
          variables: { input: company },
        });
        console.log(result);
        commit('setNeedRefresh', true);
        // TODO: stateだけ更新する
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    companies: (state: any) => (!state.companies ? [] : state.companies),
    searchableCompanies: (state: any) =>
      !state.companies ? [] : state.companies,
    includeDelete: (state: any) => state.includeDelete,
  },
};
