/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCognitoUser = /* GraphQL */ `
  mutation CreateCognitoUser(
    $Email: String
    $password: String
    $CompanyId: String
    $ShopId: String
    $CognitoGroup: String
    $GroupId: String
    $MaintenanceCardId: String
  ) {
    createCognitoUser(
      Email: $Email
      password: $password
      CompanyId: $CompanyId
      ShopId: $ShopId
      CognitoGroup: $CognitoGroup
      GroupId: $GroupId
      MaintenanceCardId: $MaintenanceCardId
    )
  }
`;
export const updateCognitoUser = /* GraphQL */ `
  mutation UpdateCognitoUser($input: UpdateUserInput!) {
    updateCognitoUser(input: $input)
  }
`;
export const deleteCognitoUser = /* GraphQL */ `
  mutation DeleteCognitoUser($Id: String) {
    deleteCognitoUser(Id: $Id)
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      Id
      Email
      CompanyId
      ShopId
      GroupId
      AffiliatedGroups {
        Principal
        Secondary
      }
      isDelete
      CognitoGroup
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      shop {
        Id
        CompanyId
        Name
        DisplayName
        Terminals {
          Id
          Kind
        }
        StreetAddress
        PhoneNo
        isDelete
        CreatedAt
        UpdatedAt
        owner
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
      }
      MaintenanceCardId
      LastLoginDate
      CreatedAt
      UpdatedAt
      relUserGroup {
        Id
        MemberKind
        Name
        CompanyId
        ShopId
        Members {
          Users
        }
        RelGroups
        CreatedAt
        UpdatedAt
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        users {
          items {
            Id
            Email
            CompanyId
            ShopId
            GroupId
            AffiliatedGroups {
              Principal
              Secondary
            }
            isDelete
            CognitoGroup
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            shop {
              Id
              CompanyId
              Name
              DisplayName
              Terminals {
                Id
                Kind
              }
              StreetAddress
              PhoneNo
              isDelete
              CreatedAt
              UpdatedAt
              owner
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
            }
            MaintenanceCardId
            LastLoginDate
            CreatedAt
            UpdatedAt
            relUserGroup {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              Members {
                Users
              }
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              users {
                nextToken
              }
              relTerminalGroups {
                nextToken
              }
            }
          }
          nextToken
        }
        relTerminalGroups {
          items {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Terminals {
                Id
                Kind
              }
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            terminals {
              items {
                Id
                Kind
                CompanyId
                ShopId
                GroupId
                isDelete
                DisplayName
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relUserGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      Id
      Email
      CompanyId
      ShopId
      GroupId
      AffiliatedGroups {
        Principal
        Secondary
      }
      isDelete
      CognitoGroup
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      shop {
        Id
        CompanyId
        Name
        DisplayName
        Terminals {
          Id
          Kind
        }
        StreetAddress
        PhoneNo
        isDelete
        CreatedAt
        UpdatedAt
        owner
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
      }
      MaintenanceCardId
      LastLoginDate
      CreatedAt
      UpdatedAt
      relUserGroup {
        Id
        MemberKind
        Name
        CompanyId
        ShopId
        Members {
          Users
        }
        RelGroups
        CreatedAt
        UpdatedAt
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        users {
          items {
            Id
            Email
            CompanyId
            ShopId
            GroupId
            AffiliatedGroups {
              Principal
              Secondary
            }
            isDelete
            CognitoGroup
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            shop {
              Id
              CompanyId
              Name
              DisplayName
              Terminals {
                Id
                Kind
              }
              StreetAddress
              PhoneNo
              isDelete
              CreatedAt
              UpdatedAt
              owner
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
            }
            MaintenanceCardId
            LastLoginDate
            CreatedAt
            UpdatedAt
            relUserGroup {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              Members {
                Users
              }
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              users {
                nextToken
              }
              relTerminalGroups {
                nextToken
              }
            }
          }
          nextToken
        }
        relTerminalGroups {
          items {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Terminals {
                Id
                Kind
              }
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            terminals {
              items {
                Id
                Kind
                CompanyId
                ShopId
                GroupId
                isDelete
                DisplayName
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relUserGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      Id
      Email
      CompanyId
      ShopId
      GroupId
      AffiliatedGroups {
        Principal
        Secondary
      }
      isDelete
      CognitoGroup
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      shop {
        Id
        CompanyId
        Name
        DisplayName
        Terminals {
          Id
          Kind
        }
        StreetAddress
        PhoneNo
        isDelete
        CreatedAt
        UpdatedAt
        owner
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
      }
      MaintenanceCardId
      LastLoginDate
      CreatedAt
      UpdatedAt
      relUserGroup {
        Id
        MemberKind
        Name
        CompanyId
        ShopId
        Members {
          Users
        }
        RelGroups
        CreatedAt
        UpdatedAt
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        users {
          items {
            Id
            Email
            CompanyId
            ShopId
            GroupId
            AffiliatedGroups {
              Principal
              Secondary
            }
            isDelete
            CognitoGroup
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            shop {
              Id
              CompanyId
              Name
              DisplayName
              Terminals {
                Id
                Kind
              }
              StreetAddress
              PhoneNo
              isDelete
              CreatedAt
              UpdatedAt
              owner
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
            }
            MaintenanceCardId
            LastLoginDate
            CreatedAt
            UpdatedAt
            relUserGroup {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              Members {
                Users
              }
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              users {
                nextToken
              }
              relTerminalGroups {
                nextToken
              }
            }
          }
          nextToken
        }
        relTerminalGroups {
          items {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Terminals {
                Id
                Kind
              }
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            terminals {
              items {
                Id
                Kind
                CompanyId
                ShopId
                GroupId
                isDelete
                DisplayName
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relUserGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      Id
      Name
      DisplayName
      Shops
      GeneralConfigs {
        TaxCalcMathod
      }
      UseStatus {
        TopUp
        TicketKiosk
      }
      SubSystemConfigs {
        Common {
          Shiagel {
            AuthCode
            Use
          }
        }
        TicketKiosk {
          Fingo {
            Use
          }
          Coupon {
            CalcMethod
          }
        }
      }
      isDelete
      CreatedAt
      UpdatedAt
      owner
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      Id
      Name
      DisplayName
      Shops
      GeneralConfigs {
        TaxCalcMathod
      }
      UseStatus {
        TopUp
        TicketKiosk
      }
      SubSystemConfigs {
        Common {
          Shiagel {
            AuthCode
            Use
          }
        }
        TicketKiosk {
          Fingo {
            Use
          }
          Coupon {
            CalcMethod
          }
        }
      }
      isDelete
      CreatedAt
      UpdatedAt
      owner
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      Id
      Name
      DisplayName
      Shops
      GeneralConfigs {
        TaxCalcMathod
      }
      UseStatus {
        TopUp
        TicketKiosk
      }
      SubSystemConfigs {
        Common {
          Shiagel {
            AuthCode
            Use
          }
        }
        TicketKiosk {
          Fingo {
            Use
          }
          Coupon {
            CalcMethod
          }
        }
      }
      isDelete
      CreatedAt
      UpdatedAt
      owner
    }
  }
`;
export const createShop = /* GraphQL */ `
  mutation CreateShop(
    $input: CreateShopInput!
    $condition: ModelShopConditionInput
  ) {
    createShop(input: $input, condition: $condition) {
      Id
      CompanyId
      Name
      DisplayName
      Terminals {
        Id
        Kind
      }
      StreetAddress
      PhoneNo
      isDelete
      CreatedAt
      UpdatedAt
      owner
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
    }
  }
`;
export const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      Id
      CompanyId
      Name
      DisplayName
      Terminals {
        Id
        Kind
      }
      StreetAddress
      PhoneNo
      isDelete
      CreatedAt
      UpdatedAt
      owner
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
    }
  }
`;
export const deleteShop = /* GraphQL */ `
  mutation DeleteShop(
    $input: DeleteShopInput!
    $condition: ModelShopConditionInput
  ) {
    deleteShop(input: $input, condition: $condition) {
      Id
      CompanyId
      Name
      DisplayName
      Terminals {
        Id
        Kind
      }
      StreetAddress
      PhoneNo
      isDelete
      CreatedAt
      UpdatedAt
      owner
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
    }
  }
`;
export const createUserGroup = /* GraphQL */ `
  mutation CreateUserGroup(
    $input: CreateUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    createUserGroup(input: $input, condition: $condition) {
      Id
      MemberKind
      Name
      CompanyId
      ShopId
      Members {
        Users
      }
      RelGroups
      CreatedAt
      UpdatedAt
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      users {
        items {
          Id
          Email
          CompanyId
          ShopId
          GroupId
          AffiliatedGroups {
            Principal
            Secondary
          }
          isDelete
          CognitoGroup
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          shop {
            Id
            CompanyId
            Name
            DisplayName
            Terminals {
              Id
              Kind
            }
            StreetAddress
            PhoneNo
            isDelete
            CreatedAt
            UpdatedAt
            owner
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
          }
          MaintenanceCardId
          LastLoginDate
          CreatedAt
          UpdatedAt
          relUserGroup {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Users
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            users {
              items {
                Id
                Email
                CompanyId
                ShopId
                GroupId
                isDelete
                CognitoGroup
                MaintenanceCardId
                LastLoginDate
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relTerminalGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      relTerminalGroups {
        items {
          Id
          MemberKind
          Name
          CompanyId
          ShopId
          Members {
            Terminals {
              Id
              Kind
            }
          }
          RelGroups
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          terminals {
            items {
              Id
              Kind
              CompanyId
              ShopId
              GroupId
              AffiliatedGroups {
                Principal
                Secondary
              }
              isDelete
              DisplayName
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              shop {
                Id
                CompanyId
                Name
                DisplayName
                StreetAddress
                PhoneNo
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              relTerminalGroup {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
            }
            nextToken
          }
          relUserGroups {
            items {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              Members {
                Users
              }
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              users {
                nextToken
              }
              relTerminalGroups {
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateUserGroup = /* GraphQL */ `
  mutation UpdateUserGroup(
    $input: UpdateUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    updateUserGroup(input: $input, condition: $condition) {
      Id
      MemberKind
      Name
      CompanyId
      ShopId
      Members {
        Users
      }
      RelGroups
      CreatedAt
      UpdatedAt
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      users {
        items {
          Id
          Email
          CompanyId
          ShopId
          GroupId
          AffiliatedGroups {
            Principal
            Secondary
          }
          isDelete
          CognitoGroup
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          shop {
            Id
            CompanyId
            Name
            DisplayName
            Terminals {
              Id
              Kind
            }
            StreetAddress
            PhoneNo
            isDelete
            CreatedAt
            UpdatedAt
            owner
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
          }
          MaintenanceCardId
          LastLoginDate
          CreatedAt
          UpdatedAt
          relUserGroup {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Users
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            users {
              items {
                Id
                Email
                CompanyId
                ShopId
                GroupId
                isDelete
                CognitoGroup
                MaintenanceCardId
                LastLoginDate
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relTerminalGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      relTerminalGroups {
        items {
          Id
          MemberKind
          Name
          CompanyId
          ShopId
          Members {
            Terminals {
              Id
              Kind
            }
          }
          RelGroups
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          terminals {
            items {
              Id
              Kind
              CompanyId
              ShopId
              GroupId
              AffiliatedGroups {
                Principal
                Secondary
              }
              isDelete
              DisplayName
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              shop {
                Id
                CompanyId
                Name
                DisplayName
                StreetAddress
                PhoneNo
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              relTerminalGroup {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
            }
            nextToken
          }
          relUserGroups {
            items {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              Members {
                Users
              }
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              users {
                nextToken
              }
              relTerminalGroups {
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteUserGroup = /* GraphQL */ `
  mutation DeleteUserGroup(
    $input: DeleteUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    deleteUserGroup(input: $input, condition: $condition) {
      Id
      MemberKind
      Name
      CompanyId
      ShopId
      Members {
        Users
      }
      RelGroups
      CreatedAt
      UpdatedAt
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      users {
        items {
          Id
          Email
          CompanyId
          ShopId
          GroupId
          AffiliatedGroups {
            Principal
            Secondary
          }
          isDelete
          CognitoGroup
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          shop {
            Id
            CompanyId
            Name
            DisplayName
            Terminals {
              Id
              Kind
            }
            StreetAddress
            PhoneNo
            isDelete
            CreatedAt
            UpdatedAt
            owner
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
          }
          MaintenanceCardId
          LastLoginDate
          CreatedAt
          UpdatedAt
          relUserGroup {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Users
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            users {
              items {
                Id
                Email
                CompanyId
                ShopId
                GroupId
                isDelete
                CognitoGroup
                MaintenanceCardId
                LastLoginDate
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relTerminalGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      relTerminalGroups {
        items {
          Id
          MemberKind
          Name
          CompanyId
          ShopId
          Members {
            Terminals {
              Id
              Kind
            }
          }
          RelGroups
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          terminals {
            items {
              Id
              Kind
              CompanyId
              ShopId
              GroupId
              AffiliatedGroups {
                Principal
                Secondary
              }
              isDelete
              DisplayName
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              shop {
                Id
                CompanyId
                Name
                DisplayName
                StreetAddress
                PhoneNo
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              relTerminalGroup {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
            }
            nextToken
          }
          relUserGroups {
            items {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              Members {
                Users
              }
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              users {
                nextToken
              }
              relTerminalGroups {
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createTerminalGroup = /* GraphQL */ `
  mutation CreateTerminalGroup(
    $input: CreateTerminalGroupInput!
    $condition: ModelTerminalGroupConditionInput
  ) {
    createTerminalGroup(input: $input, condition: $condition) {
      Id
      MemberKind
      Name
      CompanyId
      ShopId
      Members {
        Terminals {
          Id
          Kind
        }
      }
      RelGroups
      CreatedAt
      UpdatedAt
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      terminals {
        items {
          Id
          Kind
          CompanyId
          ShopId
          GroupId
          AffiliatedGroups {
            Principal
            Secondary
          }
          isDelete
          DisplayName
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          shop {
            Id
            CompanyId
            Name
            DisplayName
            Terminals {
              Id
              Kind
            }
            StreetAddress
            PhoneNo
            isDelete
            CreatedAt
            UpdatedAt
            owner
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
          }
          relTerminalGroup {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Terminals {
                Id
                Kind
              }
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            terminals {
              items {
                Id
                Kind
                CompanyId
                ShopId
                GroupId
                isDelete
                DisplayName
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relUserGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      relUserGroups {
        items {
          Id
          MemberKind
          Name
          CompanyId
          ShopId
          Members {
            Users
          }
          RelGroups
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          users {
            items {
              Id
              Email
              CompanyId
              ShopId
              GroupId
              AffiliatedGroups {
                Principal
                Secondary
              }
              isDelete
              CognitoGroup
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              shop {
                Id
                CompanyId
                Name
                DisplayName
                StreetAddress
                PhoneNo
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              MaintenanceCardId
              LastLoginDate
              CreatedAt
              UpdatedAt
              relUserGroup {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
            }
            nextToken
          }
          relTerminalGroups {
            items {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              terminals {
                nextToken
              }
              relUserGroups {
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateTerminalGroup = /* GraphQL */ `
  mutation UpdateTerminalGroup(
    $input: UpdateTerminalGroupInput!
    $condition: ModelTerminalGroupConditionInput
  ) {
    updateTerminalGroup(input: $input, condition: $condition) {
      Id
      MemberKind
      Name
      CompanyId
      ShopId
      Members {
        Terminals {
          Id
          Kind
        }
      }
      RelGroups
      CreatedAt
      UpdatedAt
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      terminals {
        items {
          Id
          Kind
          CompanyId
          ShopId
          GroupId
          AffiliatedGroups {
            Principal
            Secondary
          }
          isDelete
          DisplayName
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          shop {
            Id
            CompanyId
            Name
            DisplayName
            Terminals {
              Id
              Kind
            }
            StreetAddress
            PhoneNo
            isDelete
            CreatedAt
            UpdatedAt
            owner
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
          }
          relTerminalGroup {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Terminals {
                Id
                Kind
              }
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            terminals {
              items {
                Id
                Kind
                CompanyId
                ShopId
                GroupId
                isDelete
                DisplayName
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relUserGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      relUserGroups {
        items {
          Id
          MemberKind
          Name
          CompanyId
          ShopId
          Members {
            Users
          }
          RelGroups
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          users {
            items {
              Id
              Email
              CompanyId
              ShopId
              GroupId
              AffiliatedGroups {
                Principal
                Secondary
              }
              isDelete
              CognitoGroup
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              shop {
                Id
                CompanyId
                Name
                DisplayName
                StreetAddress
                PhoneNo
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              MaintenanceCardId
              LastLoginDate
              CreatedAt
              UpdatedAt
              relUserGroup {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
            }
            nextToken
          }
          relTerminalGroups {
            items {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              terminals {
                nextToken
              }
              relUserGroups {
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteTerminalGroup = /* GraphQL */ `
  mutation DeleteTerminalGroup(
    $input: DeleteTerminalGroupInput!
    $condition: ModelTerminalGroupConditionInput
  ) {
    deleteTerminalGroup(input: $input, condition: $condition) {
      Id
      MemberKind
      Name
      CompanyId
      ShopId
      Members {
        Terminals {
          Id
          Kind
        }
      }
      RelGroups
      CreatedAt
      UpdatedAt
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      terminals {
        items {
          Id
          Kind
          CompanyId
          ShopId
          GroupId
          AffiliatedGroups {
            Principal
            Secondary
          }
          isDelete
          DisplayName
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          shop {
            Id
            CompanyId
            Name
            DisplayName
            Terminals {
              Id
              Kind
            }
            StreetAddress
            PhoneNo
            isDelete
            CreatedAt
            UpdatedAt
            owner
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
          }
          relTerminalGroup {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Terminals {
                Id
                Kind
              }
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            terminals {
              items {
                Id
                Kind
                CompanyId
                ShopId
                GroupId
                isDelete
                DisplayName
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relUserGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      relUserGroups {
        items {
          Id
          MemberKind
          Name
          CompanyId
          ShopId
          Members {
            Users
          }
          RelGroups
          CreatedAt
          UpdatedAt
          company {
            Id
            Name
            DisplayName
            Shops
            GeneralConfigs {
              TaxCalcMathod
            }
            UseStatus {
              TopUp
              TicketKiosk
            }
            isDelete
            CreatedAt
            UpdatedAt
            owner
          }
          users {
            items {
              Id
              Email
              CompanyId
              ShopId
              GroupId
              AffiliatedGroups {
                Principal
                Secondary
              }
              isDelete
              CognitoGroup
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              shop {
                Id
                CompanyId
                Name
                DisplayName
                StreetAddress
                PhoneNo
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              MaintenanceCardId
              LastLoginDate
              CreatedAt
              UpdatedAt
              relUserGroup {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
            }
            nextToken
          }
          relTerminalGroups {
            items {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              terminals {
                nextToken
              }
              relUserGroups {
                nextToken
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createTerminal = /* GraphQL */ `
  mutation CreateTerminal(
    $input: CreateTerminalInput!
    $condition: ModelTerminalConditionInput
  ) {
    createTerminal(input: $input, condition: $condition) {
      Id
      Kind
      CompanyId
      ShopId
      GroupId
      AffiliatedGroups {
        Principal
        Secondary
      }
      isDelete
      DisplayName
      CreatedAt
      UpdatedAt
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      shop {
        Id
        CompanyId
        Name
        DisplayName
        Terminals {
          Id
          Kind
        }
        StreetAddress
        PhoneNo
        isDelete
        CreatedAt
        UpdatedAt
        owner
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
      }
      relTerminalGroup {
        Id
        MemberKind
        Name
        CompanyId
        ShopId
        Members {
          Terminals {
            Id
            Kind
          }
        }
        RelGroups
        CreatedAt
        UpdatedAt
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        terminals {
          items {
            Id
            Kind
            CompanyId
            ShopId
            GroupId
            AffiliatedGroups {
              Principal
              Secondary
            }
            isDelete
            DisplayName
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            shop {
              Id
              CompanyId
              Name
              DisplayName
              Terminals {
                Id
                Kind
              }
              StreetAddress
              PhoneNo
              isDelete
              CreatedAt
              UpdatedAt
              owner
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
            }
            relTerminalGroup {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              terminals {
                nextToken
              }
              relUserGroups {
                nextToken
              }
            }
          }
          nextToken
        }
        relUserGroups {
          items {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Users
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            users {
              items {
                Id
                Email
                CompanyId
                ShopId
                GroupId
                isDelete
                CognitoGroup
                MaintenanceCardId
                LastLoginDate
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relTerminalGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateTerminal = /* GraphQL */ `
  mutation UpdateTerminal(
    $input: UpdateTerminalInput!
    $condition: ModelTerminalConditionInput
  ) {
    updateTerminal(input: $input, condition: $condition) {
      Id
      Kind
      CompanyId
      ShopId
      GroupId
      AffiliatedGroups {
        Principal
        Secondary
      }
      isDelete
      DisplayName
      CreatedAt
      UpdatedAt
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      shop {
        Id
        CompanyId
        Name
        DisplayName
        Terminals {
          Id
          Kind
        }
        StreetAddress
        PhoneNo
        isDelete
        CreatedAt
        UpdatedAt
        owner
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
      }
      relTerminalGroup {
        Id
        MemberKind
        Name
        CompanyId
        ShopId
        Members {
          Terminals {
            Id
            Kind
          }
        }
        RelGroups
        CreatedAt
        UpdatedAt
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        terminals {
          items {
            Id
            Kind
            CompanyId
            ShopId
            GroupId
            AffiliatedGroups {
              Principal
              Secondary
            }
            isDelete
            DisplayName
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            shop {
              Id
              CompanyId
              Name
              DisplayName
              Terminals {
                Id
                Kind
              }
              StreetAddress
              PhoneNo
              isDelete
              CreatedAt
              UpdatedAt
              owner
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
            }
            relTerminalGroup {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              terminals {
                nextToken
              }
              relUserGroups {
                nextToken
              }
            }
          }
          nextToken
        }
        relUserGroups {
          items {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Users
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            users {
              items {
                Id
                Email
                CompanyId
                ShopId
                GroupId
                isDelete
                CognitoGroup
                MaintenanceCardId
                LastLoginDate
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relTerminalGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteTerminal = /* GraphQL */ `
  mutation DeleteTerminal(
    $input: DeleteTerminalInput!
    $condition: ModelTerminalConditionInput
  ) {
    deleteTerminal(input: $input, condition: $condition) {
      Id
      Kind
      CompanyId
      ShopId
      GroupId
      AffiliatedGroups {
        Principal
        Secondary
      }
      isDelete
      DisplayName
      CreatedAt
      UpdatedAt
      company {
        Id
        Name
        DisplayName
        Shops
        GeneralConfigs {
          TaxCalcMathod
        }
        UseStatus {
          TopUp
          TicketKiosk
        }
        SubSystemConfigs {
          Common {
            Shiagel {
              AuthCode
              Use
            }
          }
          TicketKiosk {
            Fingo {
              Use
            }
            Coupon {
              CalcMethod
            }
          }
        }
        isDelete
        CreatedAt
        UpdatedAt
        owner
      }
      shop {
        Id
        CompanyId
        Name
        DisplayName
        Terminals {
          Id
          Kind
        }
        StreetAddress
        PhoneNo
        isDelete
        CreatedAt
        UpdatedAt
        owner
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
      }
      relTerminalGroup {
        Id
        MemberKind
        Name
        CompanyId
        ShopId
        Members {
          Terminals {
            Id
            Kind
          }
        }
        RelGroups
        CreatedAt
        UpdatedAt
        company {
          Id
          Name
          DisplayName
          Shops
          GeneralConfigs {
            TaxCalcMathod
          }
          UseStatus {
            TopUp
            TicketKiosk
          }
          SubSystemConfigs {
            Common {
              Shiagel {
                AuthCode
                Use
              }
            }
            TicketKiosk {
              Fingo {
                Use
              }
              Coupon {
                CalcMethod
              }
            }
          }
          isDelete
          CreatedAt
          UpdatedAt
          owner
        }
        terminals {
          items {
            Id
            Kind
            CompanyId
            ShopId
            GroupId
            AffiliatedGroups {
              Principal
              Secondary
            }
            isDelete
            DisplayName
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            shop {
              Id
              CompanyId
              Name
              DisplayName
              Terminals {
                Id
                Kind
              }
              StreetAddress
              PhoneNo
              isDelete
              CreatedAt
              UpdatedAt
              owner
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
            }
            relTerminalGroup {
              Id
              MemberKind
              Name
              CompanyId
              ShopId
              RelGroups
              CreatedAt
              UpdatedAt
              company {
                Id
                Name
                DisplayName
                Shops
                isDelete
                CreatedAt
                UpdatedAt
                owner
              }
              terminals {
                nextToken
              }
              relUserGroups {
                nextToken
              }
            }
          }
          nextToken
        }
        relUserGroups {
          items {
            Id
            MemberKind
            Name
            CompanyId
            ShopId
            Members {
              Users
            }
            RelGroups
            CreatedAt
            UpdatedAt
            company {
              Id
              Name
              DisplayName
              Shops
              GeneralConfigs {
                TaxCalcMathod
              }
              UseStatus {
                TopUp
                TicketKiosk
              }
              isDelete
              CreatedAt
              UpdatedAt
              owner
            }
            users {
              items {
                Id
                Email
                CompanyId
                ShopId
                GroupId
                isDelete
                CognitoGroup
                MaintenanceCardId
                LastLoginDate
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
            relTerminalGroups {
              items {
                Id
                MemberKind
                Name
                CompanyId
                ShopId
                RelGroups
                CreatedAt
                UpdatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createSystemOperationHistories = /* GraphQL */ `
  mutation CreateSystemOperationHistories(
    $input: CreateSystemOperationHistoriesInput!
  ) {
    createSystemOperationHistories(input: $input) {
      UserId
      UserMail
      TimestampMs
      ViewName
      ViewId
      OperationName
      OperationId
      Detail
      CreatedAt
    }
  }
`;
export const createSystemPlannedMaintenanceSchedules = /* GraphQL */ `
  mutation CreateSystemPlannedMaintenanceSchedules(
    $input: CreateSystemPlannedMaintenanceSchedules!
  ) {
    createSystemPlannedMaintenanceSchedules(input: $input) {
      ScheduleId
      StartTimestampMs
      EndTimestampMs
      Message
      UserId
      UserName
      CreatedAt
    }
  }
`;
export const updateSystemPlannedMaintenanceSchedules = /* GraphQL */ `
  mutation UpdateSystemPlannedMaintenanceSchedules(
    $input: UpdateSystemPlannedMaintenanceSchedules!
  ) {
    updateSystemPlannedMaintenanceSchedules(input: $input) {
      ScheduleId
      StartTimestampMs
      EndTimestampMs
      Message
      UserId
      UserName
      CreatedAt
    }
  }
`;
