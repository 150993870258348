












































import { Component, Vue, Watch } from "vue-property-decorator";
import Logo from "@/components/Logo.vue";
import { Action, Getter } from "vuex-class";
import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";

@Component({
  components: {
    Logo,
  },
})
export default class Login extends Vue {
  email = "";
  password = "";
  passwordShow = false;
  errorMessage = "";
  hasError = false;
  isValid = false;
  rules = {
    emailReuireed: [(v: string) => !!v || "メールアドレスを入力してください"],
    passwordReuireed: [(v: string) => !!v || "パスワードを入力してください"],
  };

  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;

  @Getter("auth/dbUser") dbUser!: any;

  async login() {
    try {
      await this.$store.dispatch("auth/login", {
        username: this.email,
        password: this.password,
      });
      await this.$store.getters['auth/dbUser'];

      await this.loggingOperationHistory({
        user: this.dbUser,
        viewName: "ログイン画面",
        viewId: "Login",
        operationName: "ログイン 実行",
        operationId: "login",
        detail: {
          email: this.email,
          userId: this.dbUser.Id,
        },
      });
      this.$router.push({ name: "Home" });
    } catch (e: any) {
      console.log(e);
      if (
        e.code === "UserNotFoundException" ||
        e.code === "NotAuthorizedException"
      ) {
        this.hasError = true;
        this.errorMessage =
          "メールアドレスとパスワードの組み合わせが間違えています";
      }
      if (e.code === "NEW_PASSWORD_REQUIRED") {
        // this.hasError = true;
        await this.$router.push({ name: "Login-ForcePasswordChange" });
      }
      if (e.code == "PasswordResetRequiredException") {
        await this.$router.push({ name: "Login-ForgotPassword" });
      }
    }
  }
}
