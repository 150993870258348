
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import { API } from "aws-amplify";
import { listSystemOperationHistories } from '@/graphql/queries';
import {
  SystemOperationHistories,
  ListSystemOperationHistoriesQuery,
  ListSystemOperationHistoriesQueryVariables,
} from '@/API';

export const getAllSystemOperationHistories = async (variables: null | ListSystemOperationHistoriesQueryVariables = null): Promise<SystemOperationHistories[]> => {
  // ------
  const queryOption = {
    query: listSystemOperationHistories,
  } as GraphQLOptions
  if (variables) { queryOption.variables = variables }
  const graphqlFunc = async (option: GraphQLOptions) => {
    const response =  await API.graphql(option) as {data: ListSystemOperationHistoriesQuery}
    return response.data.listSystemOperationHistories
  }
  let queryResult = await graphqlFunc(queryOption)
  if (!queryResult) return []
  let result = queryResult.items ? queryResult.items : []
  
  // 次ページ以降がある場合
  const nextVariables: ListSystemOperationHistoriesQueryVariables = variables? {...variables} : {}
  while (queryResult.nextToken){
    nextVariables.nextToken = queryResult.nextToken
    queryResult = await graphqlFunc({query:listSystemOperationHistories, variables: nextVariables})
    if (!queryResult) break
    if (!queryResult.items || queryResult.items.length == 0) continue
    result = result.concat(queryResult.items)
  }
  // return result.filter((v: NullableUsers[number]) => v) as ListUsers
  return result as SystemOperationHistories[];
}
  