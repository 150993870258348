// import GraphQL from '@/interfaces/graphql';
import { createSystemOperationHistories } from '@/graphql/mutations';
import axios from 'axios';
import { AuthUser } from '@/models/user.model';
import { API } from "aws-amplify";
import { CreateSystemOperationHistoriesMutation, ListSystemOperationHistoriesQuery } from '@/API';
import * as _ from 'lodash';
import { getAllSystemOperationHistories } from '@/interface/graphql/systemOperationHistories';


export interface LoggingOperationHistoryParam {
  user: AuthUser;
  viewName: string;
  viewId: string;
  operationName: string;
  operationId: string;
  detail?: any;
}

interface CreateSystemOperationHistoriesResult {
  data: CreateSystemOperationHistoriesMutation;
}

export const systemOperationHistories = {
  namespaced: true,
  state: { error: null, SystemOperationHistories: [] },
  mutations: {
    setSystemOperationHistories(state: any, payload: any) {
      state.SystemOperationHistories = payload;
    },
    appendSystemOperationHistory(state: any, payload: any) {
      state.SystemOperationHistories.push(payload);
    }
  },
  actions: {
    async getSystemOperationHistories({ commit }: any) {
      const items = await getAllSystemOperationHistories();
      // responses.map((v) => { 
      //   v.CreatedAt = new Date(
      //     v.CreatedAt * 1000
      //   ).toLocaleString('ja-JP');
      // })
      const sortedObj = _.orderBy(
        items,
        (a) => new Date(a?.CreatedAt || 0), // NOTE: 先にnullの可能性を除外しているのだが、Linterが解決できないため
        ['desc']);
      commit(
        'setSystemOperationHistories',
        sortedObj
      );
    },
    async createSystemOperationHistories({ commit }: any, params: any) {
      console.log(params);

      // const SystemOperationHistories = await GraphQL.query(createSystemOperationHistories, { input: params });
      const SystemOperationHistories = await API.graphql({ query: createSystemOperationHistories, variables: { input: params } }) as CreateSystemOperationHistoriesResult;
      commit(
        'appendSystemOperationHistory',
        SystemOperationHistories.data.createSystemOperationHistories
      );
    },
    /** システム操作履歴を保存する */
    async loggingOperationHistory(
      { commit }: any,
      value: LoggingOperationHistoryParam
    ) {
      let clientIpAddress: any | null = null;
      try {
        const res = await axios.get('https://api.ipify.org?format=json');
        clientIpAddress = res.data.ip;
      } catch (ex) {
        // console.error(ex);
        //IPアドレスが取得できないことは問題とはしない。
      }
      const detail = {
        clientIpAddress,
        ...value.detail
      };

      const params = {
        UserId: value.user.Id,
        UserMail: value.user.Email,
        TimestampMs: new Date().getTime(),
        ViewName: value.viewName,
        ViewId: value.viewId,
        OperationName: value.operationName,
        OperationId: value.operationId,
        Detail: JSON.stringify(detail),
        CreatedAt: Math.floor(new Date().getTime() / 1000.0),
      };

      const SystemOperationHistories = await API.graphql({ query: createSystemOperationHistories, variables: { input: params } }) as CreateSystemOperationHistoriesResult;
      console.log("SystemOperationHistories");
      console.log(SystemOperationHistories);

      commit(
        'appendSystemOperationHistory',
        SystemOperationHistories.data.createSystemOperationHistories
      );
    },
  },
  getters: {
    SystemOperationHistories: (state: any) => state.SystemOperationHistories
  },
};
