















































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { TicketKioskDialogInputType } from "@/types";
import { Action, Getter } from "vuex-class";
import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";

@Component
export default class TicketKioskEditDialog extends Vue {
  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;
  @Getter("auth/dbUser") dbUser!: any;

  @Prop() terminalInfo!: any;
  @Prop() terminals!: any;
  @Prop() companyList!: any[];
  @Prop() shopList!: any[];
  @Prop() terminalKind!: string;
  @PropSync("isEdit", { type: Boolean }) isEditValue!: boolean;
  // data
  errorMessage: string | null = null;
  defaultItem: TicketKioskDialogInputType = {
    CompanyId: "",
    ShopId: "",
    DisplayName: "",
  };
  editedItem: TicketKioskDialogInputType = {
    CompanyId: "",
    ShopId: "",
    DisplayName: "",
  };
  hasShopIdError = false;
  shopIdErrorMessage: string | null = null;
  isValid = false;
  rules = {
    required: (value: any) => !!value || "必須項目です",
    nameLength: (value: any) => {
      return value.length <= 255 || "255文字以下で入力してください";
    },
  };
  // computed
  get formTitle(): string {
    return !this.terminalInfo ? "券売機作成" : "券売機編集";
  }
  get saveButtonText(): string {
    return !this.terminalInfo ? "作成" : "保存";
  }
  get filteredShopList(): any[] {
    return this.shopList.filter(
      (e: any) => e.CompanyId === this.editedItem.CompanyId
    );
  }
  // method
  async save() {
    try {
      this.isValid = false;
      this.errorMessage = null;
      const checkDuplicateName = this.terminals.filter(
        (e: any) =>
          e.DisplayName === this.editedItem.DisplayName &&
          e.CompanyId === this.editedItem.CompanyId &&
          e.Id != this.editedItem.Id
      );

      if (checkDuplicateName.length > 0) {
        this.isValid = true;
        throw { code: "DUPLICATE_TERMINAL_NAME" };
      }

      if ("Id" in this.editedItem) {
        const updateData = {
          Id: this.editedItem.Id,
          DisplayName: this.editedItem.DisplayName,
        };
        await this.$store.dispatch("terminal/updateTerminal", updateData);
        await this.loggingOperationHistory({
          user: this.dbUser,
          viewName: "端末(券売機)編集ダイアログ",
          viewId: "TicketEditDialog",
          operationName: "端末編集",
          operationId: "editTicketTerminal",
          detail: { 
            terminalId: this.editedItem.Id,
            terminalDisplayName: this.editedItem.DisplayName,
          },
        });
      } else {
        // 新規作成
        await this.$store.dispatch("terminal/createTerminal", {
          terminalKind: this.terminalKind,
          item: this.editedItem,
        });
        await this.loggingOperationHistory({
          user: this.dbUser,
          viewName: "端末(券売機)作成ダイアログ",
          viewId: "TicketEditDialog",
          operationName: "端末作成",
          operationId: "addTicketTerminal",
          detail: { 
            terminalId: this.editedItem.Id,
            terminalDisplayName: this.editedItem.DisplayName,
          },
        });
      }
      await this.$store.dispatch("terminal/fetchTerminals", this.terminalKind);
      this.close();
    } catch (error: any) {
      if (error.code == "DUPLICATE_TERMINAL_NAME")
        this.errorMessage = "既に登録されています";
      else this.errorMessage = "券売機の作成に失敗しました";
    }
  }
  close() {
    this.isValid = false;
    this.errorMessage = null;
    this.isEditValue = false;
  }
  mounted() {
    this.editedItem = this.terminalInfo ?? this.defaultItem;
  }
}
