import { API } from "aws-amplify";
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import {
  getTerminal,
  listTerminals
} from "@/graphql/queries";
import {
  updateTerminal
} from "@/graphql/mutations"
import {
  UpdateTerminalMutation,
  UpdateTerminalMutationVariables,
  GetTerminalQuery,
  GetTerminalQueryVariables,
  ListTerminalGroupsQueryVariables,
  ListTerminalsQuery
} from "@/API";

type NullableTerminals = NonNullable<NonNullable<ListTerminalsQuery["listTerminals"]>["items"]>
type ListTerminals = NonNullable<NullableTerminals[number]>[]


export const getAllTerminals = async (variables: null | ListTerminalGroupsQueryVariables = null): Promise<ListTerminals> => {
  const queryOption = {
    query: listTerminals,
  } as GraphQLOptions
  if (variables) { queryOption.variables = variables }
  const graphqlFunc = async (option: GraphQLOptions) => {
    const response =  await API.graphql(option) as {data: ListTerminalsQuery}
    return response.data.listTerminals
  }
  let queryResult = await graphqlFunc(queryOption)
  if (!queryResult) return []
  let result = queryResult.items? queryResult.items: []
  const nextVariables: ListTerminalGroupsQueryVariables = variables? {...variables} : {}
  while (queryResult.nextToken){
    nextVariables.nextToken = queryResult.nextToken
    queryResult = await graphqlFunc({query:listTerminals, variables: nextVariables})
    if (!queryResult) break
    result = result.concat(queryResult.items? queryResult.items: [])
  }
  return result.filter((v: NullableTerminals[number]) => v) as ListTerminals
}


export type NullableTerminal = GetTerminalQuery['getTerminal']

export const getTerminalByIdAndKind = async (variables: GetTerminalQueryVariables): Promise<NullableTerminal> => {
  const queryOption = {
    query: getTerminal,
    variables: variables,
  } as GraphQLOptions
  const response =  await API.graphql(queryOption) as {data: GetTerminalQuery}
  return  response.data.getTerminal
}

export const updateTerminalByIdAndKind = async (variables: UpdateTerminalMutationVariables): Promise<void> => {
  console.log("[UpdateTerminalRequest]", variables)
  const queryOption = {
    query: updateTerminal,
    variables: variables,
  } as GraphQLOptions
  const response =  await API.graphql(queryOption) as {data: UpdateTerminalMutation}
  console.log("[UpdateTerminalResponse]", response) 
}