






































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';

@Component
export default class AccountDetailDialog extends Vue {
    @Prop() accountInfo!: any
    @Prop() roleItems!: any
    @PropSync('isDetail', {type: Boolean}) isDetailValue!: boolean
    // data
    errorMessage: string | null = null
    // method
    async cancelDeleteAccount() {
      try {
        await this.$store.dispatch('user/cancelDeleteUser', this.accountInfo)            
        await this.$store.dispatch('user/fetchUsers')
        this.isDetailValue = false
      }
      catch (e) {
        this.errorMessage = '削除キャンセル処理でエラーが発生しました'
      }
    }
    getRoleLabel(roleValue: string) {
      if (!roleValue) {
        return ''
      }
      const result = this.roleItems.filter((role: any) => role.value === roleValue);
      return result[0].label;
  }
}
