











import { LoggingOperationHistoryParam } from "@/store/SystemOperationHistories/systemOperationHistories";
import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component
export default class TopupDeleteDialog extends Vue {
  @Action("systemOperationHistories/loggingOperationHistory")
  loggingOperationHistory!: (value: LoggingOperationHistoryParam) => void;
  @Getter("auth/dbUser") dbUser!: any;

  @Prop() terminalInfo!: any;
  @Prop() terminalKind!: string;
  @PropSync("isDelete", { type: Boolean }) isDeleteValue!: boolean;
  // data
  errorMessage: string | null = null;
  // method
  async deleteTerminal() {
    try {
      await this.$store.dispatch("terminal/deleteTerminal", this.terminalInfo);
      await this.loggingOperationHistory({
        user: this.dbUser,
        viewName: "端末(チャージ機)削除ダイアログ",
        viewId: "TopUpDeleteTerminal",
        operationName: "端末削除",
        operationId: "deleteTopupTerminal",
        detail: { 
          terminalId: this.terminalInfo.Id,
          terminalDisplayName: this.terminalInfo.DisplayName,
        },
      });
      await this.$store.dispatch("terminal/fetchTerminals", this.terminalKind);
      this.close();
    } catch (e) {
      this.errorMessage = "削除時にエラーが発生しました";
    }
  }
  close() {
    this.isDeleteValue = false;
  }
}
