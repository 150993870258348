









































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { ShopDialogInputType } from '@/types';
@Component
export default class ShopDetailDialog extends Vue {
    @Prop() shopInfo!: ShopDialogInputType
    @PropSync('isDetail', {type: Boolean}) isDetailValue!: boolean
    // data
    errorMessage: string | null = null
    // method
    async cancelDeleteShop() {
      try {
        await this.$store.dispatch('shop/cancelDeleteShop', this.shopInfo)            
        await this.$store.dispatch('shop/fetchShops')
        this.isDetailValue = false
      }
      catch (e) {
        this.errorMessage = '戻すにエラーが発生しました'
      }
    }
}
