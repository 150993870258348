import { API } from "aws-amplify";
import { nanoid } from "nanoid";
import moment from "moment";
import {} from "@/types"
import { GetShopQuery } from "@/API";
import { 
  getShop,
} from "@/graphql/queries";
import { createShop, updateShop } from "@/graphql/mutations";
import { CreateShopInput } from "@/API";
import {
  ShopDialogInputType,
  CreatePrincipalUserGroupPayload,
  CreatePrincipalTerminalGroupPayload,
} from "@/types";
import { getShopByIsDelete, GetShopByDeleteStatus } from "@/interface/graphql/shop/shop"
import {updateUserGroupIdInGroupMember, updateTerminalGroupIdInGroupMember} from '@/service/shop/shop'
import { Shop, ShopStatus, ShopAction } from './type'

interface GetShopResult {
  data: GetShopQuery;
}

export const shop = {
  namespaced: true,
  state: { 
    shops: [] as Shop[],
    dialog: {
      shopData: [],
    },
    includeDelete: false,
    needRefresh: false,
  },
  mutations: {
    setShops(state: ShopStatus, payload: any): void {
      state.shops = payload;
    },
    setIncludeDelete(state: ShopStatus, payload: boolean): void {
      state.includeDelete = payload;
      state.needRefresh = true; // FIXME: 削除済みを含めるかどうかは画面でやるべき
    },
    setShopsForDialog(state: ShopStatus, payload: GetShopByDeleteStatus): void {
      state.dialog.shopData = payload;
    },
    setNeedRefresh(state: any, payload: boolean): void {
      state.needRefresh = payload;
    },
  },
  actions: {
    setIncludeDelete({ commit }: ShopAction, payload: boolean): void {
      commit("setIncludeDelete", payload);
    },
    async fetchShops({ commit, state, dispatch, rootGetters }: ShopAction): Promise<void> {
      if (!state.needRefresh && state.shops.length != 0) { 
        // リロード不要
        return;
      }
      // Dynamoから取得
      try {
        await dispatch('acl/fetchAcl', null, { root: true })
        const acl = rootGetters["acl/acl"];
        const shops = [];
        for (const shopId of acl.Shops){
          const shop = await API.graphql({
            query: getShop, variables:{Id: shopId}}) as GetShopResult;
          if (!shop.data.getShop) continue;
          if (!state.includeDelete && shop.data.getShop.isDelete === "true") continue;
          shops.push(shop.data.getShop);
        }
        console.log(shops)
        commit("setShops", shops);
        commit('setNeedRefresh', false);
      } catch (error) {
        console.log("error", error);
      }
    },
    async createShop({ commit, dispatch, rootGetters }: ShopAction, payload: any): Promise<void> {
      // Dynamoへ保存(新規作成)
      try {
        // 7桁でIDを生成
        const generatedId = nanoid(7);
        const shop: CreateShopInput = {
          Id: generatedId,
          CompanyId: payload.CompanyId,
          Name: payload.Name,
          DisplayName: payload.DisplayName,
          StreetAddress: payload.StreetAddress,
          PhoneNo: payload.PhoneNo,
          Terminals: [],
          isDelete: "false",
          CreatedAt: moment()
            .utc()
            .unix(),
          UpdatedAt: moment()
            .utc()
            .unix(),
          owner: rootGetters["auth/user"]["username"],
        };
        const result: any = await API.graphql({
          query: createShop,
          variables: { input: shop },
        });
        const groupId = nanoid(7);
        const groupKind = "Principal";
        const newGroupId = `${groupKind}&&${payload.CompanyId}&&${generatedId}&&${groupId}`;

        const principalUserGroup: CreatePrincipalUserGroupPayload = {
          Id: newGroupId,
          CompanyId: payload.CompanyId,
          ShopId: generatedId,
          Name: payload.Name,
          RelGroups: [newGroupId],
        };
        const principalTerminalGroup: CreatePrincipalTerminalGroupPayload = {
          Id: newGroupId,
          CompanyId: payload.CompanyId,
          ShopId: generatedId,
          Name: payload.Name,
          RelGroups: [newGroupId],
        };

        await dispatch("group/createPrincipalUserGroup", principalUserGroup, {
          root: true,
        });
        await dispatch(
          "group/createPrincipalTerminalGroup",
          principalTerminalGroup,
          {
            root: true,
          }
        );
        commit('setNeedRefresh', true);
        // TODO: stateだけ更新する
      } catch (error) {
        console.log("error", error);
      }
    },
    async updateShop({ commit }: ShopAction, payload: any): Promise<void>  {
      // Dynamoへ保存(更新)
      const shop = Object.assign({}, payload);
      delete shop.company;
      shop.UpdatedAt = moment().utc().unix() // 更新日時は必ず最新化する
      try {
        console.log(shop);
        const result: any = await API.graphql({
          query: updateShop,
          variables: { input: shop },
        });
        console.log(result);
        commit('setNeedRefresh', true);
        // TODO: stateだけ更新する
      } catch (error) {
        console.log(error);
      }
    },
    async deleteShop({ commit }: ShopAction, payload: ShopDialogInputType): Promise<void>  {
      // 論理削除
      try {
        const shop: any = Object.assign({}, payload);
        shop.isDelete = "true"
        // 入れていないデータは削除されるので全部入れる。
        shop.company = undefined;
        shop.UpdatedAt = moment().utc().unix() // 更新日時は必ず最新化する
        const result: any = await API.graphql({
          query: updateShop,
          variables: {
            input: shop
          },
        });
        console.log(result);
        await updateUserGroupIdInGroupMember(shop)
        await updateTerminalGroupIdInGroupMember(shop)
        commit('setNeedRefresh', true);
        // TODO: stateだけ更新する
      } catch (error) {
        console.log(error);
      }
    },
    async cancelDeleteShop({ commit }: ShopAction, payload: any): Promise<void>  {
      try {
        const shop = Object.assign({}, payload);
        shop.isDelete = "false";
        // 不要なkeyを削除
        delete shop.company;
        console.log("delete", shop);
        const result: any = await API.graphql({
          query: updateShop,
          variables: { input: shop },
        });
        console.log(result);
        commit('setNeedRefresh', true);
        // TODO: stateだけ更新する
      } catch (error) {
        console.log(error);
      }
    },
    async fetchShopsForDialog({ state, commit }: ShopAction, payload: any): Promise<void>  {
      // FIXME: 論理削除の制御(includeDelete)をこっちでやってるから再読込せざるを得ない
      if (!state.needRefresh && state.dialog.shopData.length != 0) { 
        return; // 暫定処理。
      }
      try {
        const shops = await getShopByIsDelete({
          isDelete: "false",
          filter: {
            CompanyId: { eq: payload.CompanyId },
          },
        });
        console.log(shops);
        commit("setShopsForDialog", shops);
      } catch (error) {
        console.log(error);
        throw "ユーザー取得時にエラーが発生しました";
      }
    },
  },
  getters: {
    shops: (state: any) => (!state.shops ? [] : state.shops),
    searchableShops: (state: any) => (!state.shops ? [] : state.shops),
    includeDelete: (state: any) => state.includeDelete,
    shopsForDialog: (state: any) =>
      !state.dialog.shopData ? [] : state.dialog.shopData,
  },
};
