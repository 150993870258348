


































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from 'vuex-class'
import { can } from "@/acl/Acl";
import GroupUserCreateDialog from "@/components/group/GroupUserCreateDialog.vue";

const Auth = namespace('auth');
const Company = namespace('company');
const User = namespace('user');
const Group = namespace('group');

@Component({
  components: {
    GroupUserCreateDialog,
  },
})
export default class GroupUser extends Vue {

  @Auth.Getter('userGroup') currentRole!: any[];
  @Company.Getter('searchableCompanies') companyList!: any[];
  @Company.Action("fetchCompanies") fetchCompanies!: () => Promise<void>;
  @User.Getter('searchableUsers') accountData!: any[];
  @User.Action("fetchUsers") fetchUsers!: () => Promise<void>;
  @Group.Getter('userGroups') userGroups!: any[];
  @Group.Getter('searchableTerminalGroups') terminalData!: any[];
  @Group.Action("fetchUserGroups") fetchUserGroups!: () => Promise<void>;
  @Group.Action("fetchTerminalGroups") fetchTerminalGroups!: () => Promise<void>;

  // -------------------------
  
  // data
  userSearch = "";
  terminalGroupSearch = "";
  groupHeaders = [
    {
      text: "ユーザーグループ",
      value: "Name",
      sortable: false,
      filter: this.groupNameFilter,
    },
    {
      text: "企業",
      value: "company.Name",
      sortable: false,
      filter: this.groupCompanyFilter,
    },
  ];
  accountHeaders = [
    { text: "メールアドレス", value: "email", sortable: false },
    { text: "ロール", value: "role", sortable: false },
    { text: "店舗", value: "store", sortable: false },
  ];
  terminalHeaders = [
    { text: "ターミナルグループ", value: "Name", sortable: false },
  ];
  roleItems = [
    { label: "店舗スタッフ", value: "ShopUser" },
    { label: "店舗管理者", value: "ShopManager" },
    { label: "スーパーバイザー", value: "CorpSuperVisor" },
    { label: "企業管理者", value: "CorpAdmin" },
    { label: "システムユーザ", value: "SystemUser" },
    { label: "システム管理者", value: "SystemAdmin" },
  ];
  groupData = [];
  currentGroup: any | null = null;
  selectedTerminalGroup = [];
  search = "";
  searchId = this.$route.query.group ?? null;
  createGroup = {};
  groupCreate = false;
  groupEdit = false;
  groupDelete = false;
  groupDetail = false;

  isEdit = false;
  editItem: any = null;

  panel = null;

  searchCompany = "";

  loading = false;

  /** ロード中メッセージ(date table表示用) */
  protected messages = {
      loading: '読み込み中...',
      noData: 'データ無し'
  };

  // -------------------------

  // computed
  get canCreate(): boolean {
    return can(this.currentRole, "create", "group");
  }
  get canDelete(): boolean {
    return can(this.currentRole, "delete", "group");
  }
  get canEdit(): boolean {
    return can(this.currentRole, "edit", "group");
  }

  get isLoading(): boolean {
    // TODO: マスタデータの読込待ち
    return this.loading;
  }

  // -------------------------

  created(): void {
    this.initialize();
  }

  // methods
  async initialize(): Promise<void> {
    this.loading = true;
    // TODO: state側にチェック処理を寄せる
    if (this.accountData.length == 0) await this.fetchUsers();
    if (this.companyList.length == 0) await this.fetchCompanies();
    if (this.userGroups.length == 0 ) await this.fetchUserGroups();
    if (this.terminalData.length == 0) await this.fetchTerminalGroups();
    this.loading = false;
  }

  groupRowClasses(item: any): string {
    if (this.currentGroup && item.Id === this.currentGroup.Id) {
      return "active";
    }
    return "";
  }

  openEdit(item: any) {
    if (item) {
      this.editItem = Object.assign({}, item);
    } else {
      this.editItem = null;
    }
    this.isEdit = true;
  }

  openDetail(item: any) {
    this.currentGroup = item;
  }

  getRoleLabel(roleValue: string) {
    if (!roleValue) return;
    const result = this.roleItems.filter((role) => role.value === roleValue);
    return result[0].label;
  }
  groupNameFilter(value: any, search: any, item: any): boolean {
    if (this.search.length > 0) {
      return item.Name.match(this.search) !== null;
    } else if (this.searchId) {
      return item.Id === this.searchId;
    } else {
      return true;
    }
  }

  groupCompanyFilter(value: any, search: any, item: any): boolean {
    if (this.searchCompany) {
      return item.CompanyId === this.searchCompany;
    } else if (this.searchId) {
      return item.Id === this.searchId;
    }
    return true;
  }

  afterSave(): void {
    if (this.currentGroup){
      const id = this.currentGroup.Id;
      this.currentGroup = this.userGroups.find((e: any) => e.Id === id);
    }
    this.initialize();
  }
}
