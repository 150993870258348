
import { TerminalSummary } from "@/API";
import { ShopDialogInputType } from "@/types"
import {
  getAllUserGroups, 
  updateUserGroupById, 
  deleteUserGroup,
} from '@/interface/graphql/group/userGroup'
import {
  getAllTerminalGroups, 
  updateTerminalGroupById,
  deleteTerminalGroup
} from '@/interface/graphql/group/terminalGroup'
import {getUserById, updateCognitoUserById} from "@/interface/graphql/user/user"
import {getTerminalByIdAndKind, updateTerminalByIdAndKind} from "@/interface/graphql/terminal/terminal"

// TODO: 分解する。

export const updateUserGroupIdInGroupMember = async(shop: ShopDialogInputType): Promise<void>  => {
  const shopId = shop.Id;
  const companyId = shop.CompanyId;
  const companyGroupIdPrefix = `Principal&&${companyId}&&`
  const groups = await getAllUserGroups({filter: {Id: { beginsWith: companyGroupIdPrefix}}})
  const targetShopGroup = groups.filter(x => x.Id.startsWith(`${companyGroupIdPrefix}${shopId}&&`))[0]
  if (!targetShopGroup){
    console.log("Shop user group not found.")
    return;
  }else if(!targetShopGroup.Members.Users){
    console.log("Shop user group members not found.")
    return;
  }
  const targetCompanyGroup = groups.filter(x => x.Id.startsWith(`${companyGroupIdPrefix}_&&`))[0]
  if (!targetCompanyGroup || !targetCompanyGroup.Members.Users){
    console.log("Company User Group not found.")
    return;
  }
  const targetCompanyGroupId = targetCompanyGroup.Id
  const targetUserIds = targetShopGroup.Members.Users.filter(x => x) as string[]
  const targetCompanyGroupUsers = (
    targetCompanyGroup.Members.Users? [...targetCompanyGroup.Members.Users]: []
  ).filter(x => x) as string[]
  for(const userId of targetUserIds){
    const user = await getUserById({Id: userId})
    if (!user) continue;
    await updateCognitoUserById({
      input: {
        CompanyId: user.CompanyId,
        ShopId: "_",  
        Id: user.Id,
        Email: user.Email,
        GroupId: targetCompanyGroupId,
        CognitoGroup: user.CognitoGroup,
        MaintenanceCardId: user.MaintenanceCardId,
        isDelete: user.isDelete ?? "false",
      }
    })
    if (targetCompanyGroupUsers.includes(userId))continue
    targetCompanyGroupUsers.push(userId)
  }
  // await deleteUserGroup({input: {Id: targetShopGroup.Id}})
  // 対応するリゾルバが存在しないため、コメントアウト。出来たら置き換える。
  await updateUserGroupById({input: {Id: targetShopGroup.Id, Members: {Users: []}}})
  await updateUserGroupById({
    input: {
      Id: targetCompanyGroupId,
      Members: {
        Users: targetCompanyGroupUsers
      }
    }
  })
}

export const updateTerminalGroupIdInGroupMember = async (shop: ShopDialogInputType): Promise<void>  => {
  const shopId = shop.Id
  const companyId = shop.CompanyId
  const companyGroupIdPrefix = `Principal&&${companyId}&&`
  const groups = await getAllTerminalGroups({filter: {Id: { beginsWith: companyGroupIdPrefix}}})
  const targetShopGroup = groups.filter(x => x.Id.startsWith(`${companyGroupIdPrefix}${shopId}&&`))[0]
  if (!targetShopGroup){
    console.log("Shop terminal group not found.")
    return;
  }else if(!targetShopGroup.Members?.Terminals){
    console.log("Shop terminal group members not found.")
    return;
  }
  const targetCompanyGroup = groups.filter(x => x.Id.startsWith(`${companyGroupIdPrefix}_&&`))[0]
  if (!targetCompanyGroup || !targetCompanyGroup.Members?.Terminals){
    console.log("Company terminal group not found.")
    return;
  }
  const targetCompanyGroupId = targetCompanyGroup.Id
  const targetTerminals = targetShopGroup.Members.Terminals.filter(x => x) as TerminalSummary[]
  const targetCompanyGroupTerminals = (
    targetCompanyGroup.Members.Terminals? [...targetCompanyGroup.Members.Terminals]: []
  ).filter(x => x) as TerminalSummary[]
  const targetCompanyGroupTerminalsSet = new Set(targetCompanyGroupTerminals.map(x=> `${x.Id}_${x.Kind}`))
  for (const terminalInfo of targetTerminals ){
    const terminal = await getTerminalByIdAndKind( terminalInfo )
    if (!terminal) continue;
    // 入れていない値は削除されるため、全て入れる。
    if(!terminal.AffiliatedGroups.Secondary)terminal.GroupId = targetCompanyGroupId
    terminal.AffiliatedGroups.Principal = targetCompanyGroupId
    terminal.ShopId = "_"
    // 余計なデータがあるとクエリエラーになるので消す。
    terminal.company = undefined
    terminal.shop = undefined
    terminal.relTerminalGroup = undefined
    await updateTerminalByIdAndKind({
      input: terminal
    })
    const terminalText = `${terminal.Id}_${terminal.Kind}`
    if (targetCompanyGroupTerminalsSet.has(terminalText))continue;
    targetCompanyGroupTerminals.push(terminalInfo)
    targetCompanyGroupTerminalsSet.add(terminalText)
  }
  // await deleteTerminalGroup({input: {Id:targetShopGroup.Id}})
  // 対応するリゾルバが存在しないため、コメントアウト。出来たら置き換える。
  await updateTerminalGroupById({input: {Id:targetShopGroup.Id, Members: {Terminals: []}}})
  await updateTerminalGroupById({
    input: {
      Id: targetCompanyGroupId,
      Members: {
        Terminals: targetCompanyGroupTerminals.map(x => { return { Id: x.Id!, Kind: x.Kind! } })
      }
    }
  })
}