
import {
  getTerminalGroupById,
  updateTerminalGroupById,
} from "@/interface/graphql/group/terminalGroup"

export const addUserGroup = async (groupId: string, userGroupId: string): Promise<void> => {
  const targetGroup = await getTerminalGroupById({Id: groupId});
  if (!targetGroup) return;
  const terminalGroups = targetGroup.RelGroups ? [...targetGroup.RelGroups] : [];
  if(
    !targetGroup.RelGroups ||
    !targetGroup.RelGroups.includes(userGroupId)
  ){
    terminalGroups.push(userGroupId);
    await updateTerminalGroupById({
      input: {
        Id: targetGroup.Id,
        RelGroups: terminalGroups,
        Members: targetGroup.Members ?? {Terminals: null},
      }
    });
  }
}