var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        staticClass: "logo_style",
        attrs: {
          alt: "neos",
          src: require("@/assets/images/smoocha_smooder.png")
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }