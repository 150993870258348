var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      (!_vm.isSystemAdmin && _vm.isInRestriction
      ? false
      : true)
        ? _c(
            "div",
            [
              !_vm.mobile
                ? _c(
                    "v-navigation-drawer",
                    {
                      staticClass: "nav-bar",
                      attrs: { fixed: "", app: "", permanent: "" }
                    },
                    [
                      _c("logo"),
                      _c(
                        "v-list",
                        [
                          _vm._l(_vm.items, function(item, i) {
                            return [
                              item.enable
                                ? [
                                    item.childPages
                                      ? _c(
                                          "v-list-group",
                                          {
                                            key: item.title,
                                            attrs: {
                                              "prepend-icon": item.icon,
                                              "no-action": ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              domProps: {
                                                                textContent: _vm._s(
                                                                  item.title
                                                                )
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: item.active,
                                              callback: function($$v) {
                                                _vm.$set(item, "active", $$v)
                                              },
                                              expression: "item.active"
                                            }
                                          },
                                          _vm._l(item.childPages, function(
                                            child,
                                            n
                                          ) {
                                            return _c(
                                              "v-list-item",
                                              {
                                                key: n,
                                                attrs: {
                                                  to: child.to,
                                                  router: "",
                                                  exact: ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          child.title
                                                        )
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        )
                                      : _c(
                                          "v-list-item",
                                          {
                                            key: i,
                                            attrs: {
                                              to: item.to,
                                              router: "",
                                              exact: ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-item-action",
                                              [
                                                _c("v-icon", [
                                                  _vm._v(_vm._s(item.icon))
                                                ])
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.title
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                  ]
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      (!_vm.isSystemAdmin && _vm.isInRestriction
      ? false
      : true)
        ? _c(
            "div",
            [
              _vm.mobile
                ? _c(
                    "v-navigation-drawer",
                    {
                      staticClass: "nav-bar",
                      attrs: { absolute: "", temporary: "" },
                      model: {
                        value: _vm.drawer,
                        callback: function($$v) {
                          _vm.drawer = $$v
                        },
                        expression: "drawer"
                      }
                    },
                    [
                      _c("logo"),
                      _c(
                        "v-list",
                        [
                          _vm._l(_vm.items, function(item, i) {
                            return [
                              item.enable
                                ? [
                                    item.childPages
                                      ? _c(
                                          "v-list-group",
                                          {
                                            key: item.title,
                                            attrs: {
                                              "prepend-icon": item.icon,
                                              "no-action": ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              domProps: {
                                                                textContent: _vm._s(
                                                                  item.title
                                                                )
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: item.active,
                                              callback: function($$v) {
                                                _vm.$set(item, "active", $$v)
                                              },
                                              expression: "item.active"
                                            }
                                          },
                                          _vm._l(item.childPages, function(
                                            child,
                                            n
                                          ) {
                                            return _c(
                                              "v-list-item",
                                              {
                                                key: n,
                                                attrs: {
                                                  to: child.to,
                                                  router: "",
                                                  exact: ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", {
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          child.title
                                                        )
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        )
                                      : _c(
                                          "v-list-item",
                                          {
                                            key: i,
                                            attrs: {
                                              to: item.to,
                                              router: "",
                                              exact: ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-item-action",
                                              [
                                                _c("v-icon", [
                                                  _vm._v(_vm._s(item.icon))
                                                ])
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.title
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                  ]
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-app-bar",
        { attrs: { fixed: "", app: "" } },
        [
          (!_vm.isSystemAdmin && _vm.isInRestriction
          ? false
          : true)
            ? _c(
                "div",
                [
                  _vm.mobile
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "#F4F4F4",
                            depressed: "",
                            dark: "",
                            small: ""
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.drawer = !_vm.drawer
                            }
                          }
                        },
                        [
                          _c("v-app-bar-nav-icon", {
                            attrs: { color: "#363740" }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c("p", { staticClass: "text-h4 font-weight-bold mb-0" }, [
            _vm._v(_vm._s(_vm.currentCompanyName))
          ]),
          _c("v-spacer"),
          _c(
            "div",
            { staticClass: "v-app-bar__user" },
            [
              _c("span", { staticClass: "mr-3" }, [
                _vm._v(_vm._s(_vm.currentUserEmail))
              ]),
              _c(
                "v-menu",
                {
                  attrs: { left: "", bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    color: "primary",
                                    small: "",
                                    fab: "",
                                    depressed: ""
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "span",
                                { staticClass: "white--text headline" },
                                [_vm._v("UN")]
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.logout()
                            }
                          }
                        },
                        [_c("v-list-item-title", [_vm._v("ログアウト")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }