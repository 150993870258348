export const ROLE = {
  SystemAdmin: "SystemAdmin",
  SystemUser: "SystemUser",
  CorpAdmin: "CorpAdmin",
  CorpSuperVisor: "CorpSuperVisor",
  ShopManager: "ShopManager",
  ShopUser: "ShopUser",
} as const;

const acl: any = {
  dashboard: {
    menu: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else if (role === ROLE.ShopManager) return true;
      else return false;
    },
  },
  account: {
    menu: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else if (role === ROLE.ShopManager) return true;
      else return false;
    },
    list: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else if (role === ROLE.ShopManager) return true;
      else return false;
    },
    create: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else if (role === ROLE.ShopManager) return true;
      else return false;
    },
    edit: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else if (role === ROLE.ShopManager) return true;
      else return false;
    },
    delete: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else return false;
    },
  },
  company: {
    menu: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else if (role === ROLE.ShopManager) return true;
      else return false;
    },
    list: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else if (role === ROLE.ShopManager) return true;
      else return false;
    },
    create: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else return false;
    },
    edit: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else return false;
    },
    delete: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else return false;
    },
  },
  shop: {
    menu: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else if (role === ROLE.ShopManager) return true;
      else return false;
    },
    list: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else if (role === ROLE.ShopManager) return true;
      else return false;
    },
    create: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else return false;
    },
    edit: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else return false;
    },
    delete: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else return false;
    },
  },
  group: {
    menu: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else if (role === ROLE.ShopManager) return true;
      else return false;
    },
    list: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else if (role === ROLE.ShopManager) return true;
      else return false;
    },
    create: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else if (role === ROLE.ShopManager) return true;

      else return false;
    },
    edit: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.ShopManager) return true;
      else return false;
    },
    delete: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else return false;
    },
  },
  terminal: {
    menu: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else if (role === ROLE.ShopManager) return true;
      else return false;
    },
    list: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else if (role === ROLE.ShopManager) return true;
      else return false;
    },
    create: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else return false;
    },
    edit: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.SystemUser) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else return false;
    },
    delete: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
      else if (role === ROLE.CorpAdmin) return true;
      else if (role === ROLE.CorpSuperVisor) return true;
      else return false;
    },
  },
  management: {
    menu: (role: string) => {
      if (role === ROLE.SystemAdmin) return true;
    },
  },
};

export const can = (user: any, verb: any, subject: any, ...args: any[]) => {
  return acl[subject][verb](user, ...args);
};

export const selectableRole = (role: string) => {
  const selectable = [];
  if (ROLE.SystemAdmin === role) {
    selectable.push(ROLE.SystemAdmin);
    selectable.push(ROLE.SystemUser);
    selectable.push(ROLE.CorpAdmin);
    selectable.push(ROLE.CorpSuperVisor);
    selectable.push(ROLE.ShopManager);
    selectable.push(ROLE.ShopUser);
  }
  if (ROLE.SystemUser === role) {
    selectable.push(ROLE.SystemUser);
    selectable.push(ROLE.CorpAdmin);
    selectable.push(ROLE.CorpSuperVisor);
    selectable.push(ROLE.ShopManager);
    selectable.push(ROLE.ShopUser);
  }
  if (ROLE.CorpAdmin === role) {
    selectable.push(ROLE.CorpAdmin);
    selectable.push(ROLE.CorpSuperVisor);
    selectable.push(ROLE.ShopManager);
    selectable.push(ROLE.ShopUser);
  }

  if (ROLE.CorpSuperVisor === role) {
    selectable.push(ROLE.CorpSuperVisor);
    selectable.push(ROLE.ShopManager);
    selectable.push(ROLE.ShopUser);
  }
  if (ROLE.ShopManager === role) {
    selectable.push(ROLE.ShopManager);
    selectable.push(ROLE.ShopUser);
  }
  if (ROLE.ShopUser === role) {
    selectable.push(ROLE.ShopUser);
  }
  return selectable;
};
