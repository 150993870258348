import { API } from "aws-amplify";
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import {
  listTerminalGroups,
  getTerminalGroup,
} from "@/graphql/queries";
import {
  createTerminalGroup as _createTerminalGroup,
  updateTerminalGroup,
  deleteTerminalGroup as _deleteTerminalGroup,
} from '@/graphql/mutations'
import {
  UpdateTerminalGroupMutation,
  UpdateTerminalGroupMutationVariables,
  ListTerminalGroupsQueryVariables,
  ListTerminalGroupsQuery,
  GetTerminalGroupQuery,
  GetTerminalGroupQueryVariables,
  CreateTerminalGroupMutationVariables,
  CreateTerminalGroupMutation,
  DeleteTerminalGroupMutation,
  DeleteTerminalGroupMutationVariables
} from "@/API";

type NullableTerminalGroups = NonNullable<NonNullable<ListTerminalGroupsQuery["listTerminalGroups"]>["items"]>
export type ListTerminalGroups = NonNullable<NullableTerminalGroups[number]>[]

export const getAllTerminalGroups = async (variables: null | ListTerminalGroupsQueryVariables = null): Promise<ListTerminalGroups> => {
  const queryOption = {
    query: listTerminalGroups,
  } as GraphQLOptions
  if (variables) { queryOption.variables = variables }
  const graphqlFunc = async (option: GraphQLOptions) => {
    const response =  await API.graphql(option) as {data: ListTerminalGroupsQuery}
    return response.data.listTerminalGroups
  }
  let queryResult = await graphqlFunc(queryOption)
  if (!queryResult) return []
  let result = queryResult.items? queryResult.items: [] 
  const nextVariables: ListTerminalGroupsQueryVariables = variables? {...variables} : {}
  while (queryResult.nextToken){
    nextVariables.nextToken = queryResult.nextToken
    queryResult = await graphqlFunc({query:listTerminalGroups, variables: nextVariables})
    if (!queryResult) break
    result = result.concat(queryResult.items? queryResult.items: [])
  }
  return result.filter((v: NullableTerminalGroups[number]) => v) as ListTerminalGroups
}

export type NullableTerminalGroup = GetTerminalGroupQuery['getTerminalGroup']

export const getTerminalGroupById = async (variables: GetTerminalGroupQueryVariables): Promise<NullableTerminalGroup> => {
  const queryOption = {
    query: getTerminalGroup,
    variables: variables,
  } as GraphQLOptions
  const graphqlFunc = async (option: GraphQLOptions) => {
    const response =  await API.graphql(option) as {data: GetTerminalGroupQuery}
    return response.data.getTerminalGroup
  }
  return  await graphqlFunc(queryOption)
}

export const updateTerminalGroupById = async (variables: UpdateTerminalGroupMutationVariables): Promise<void> => {
  console.info("[Update TerminalGroup Request]", variables)
  const result = await API.graphql({
    query: updateTerminalGroup,
    variables: variables
  }) as UpdateTerminalGroupMutation;
  console.log("[Update TerminalGroup Response]",result);
}


export const createTerminalGroup = async (variables: CreateTerminalGroupMutationVariables): Promise<CreateTerminalGroupMutation> => {
  console.info("[Create Terminal Group Request]", variables)
  const result = await API.graphql({
    query: _createTerminalGroup,
    variables: variables,
  }) as {data: CreateTerminalGroupMutation};
  console.log("[Create Terminal Group Response]",result);
  return result.data;
}


export const deleteTerminalGroup = async (variables: DeleteTerminalGroupMutationVariables): Promise<DeleteTerminalGroupMutation> => {
  console.info("[Delete Terminal Group Request]", variables)
  const result = await API.graphql({
    query: _deleteTerminalGroup,
    variables: variables,
  }) as {data: DeleteTerminalGroupMutation};
  console.log("[Delete Terminal Group Response]",result);
  return result.data;
}

