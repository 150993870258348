import {
  TerminalSummary
} from "@/API";
import { deleteTerminal } from "./deleteTerminal"
import { addTerminal } from "./addTerminal"
import { terminalService } from '../../terminal'

// 該当TerminalSummaryと一致するTerminalのグループIDを更新し
export const deleteTerminalAndTerminalGroup = async(terminal: TerminalSummary): Promise<void> => {
  const deleteGroupResult = await terminalService.deleteGroup(terminal);
  if (!deleteGroupResult) return;
  // 変更前のグループから端末の削除
  await addTerminal(deleteGroupResult.newGroupId, terminal)
  for(const terminalGroupId of deleteGroupResult.oldGroupIds) {
    if (terminalGroupId === deleteGroupResult.newGroupId) continue;
    await deleteTerminal(terminalGroupId, terminal)
  }
}