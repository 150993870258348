























































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { formatEpochSec } from "@/tools/datetime";

@Component
export default class TopupDetailDialog extends Vue {
  @Prop() terminalInfo!: any;
  @PropSync("isDetail", { type: Boolean }) isDetailValue!: boolean;
  // data
  errorMessage: string | null = null;
  // method
  async cancelDeleteTerminal() {
    try {
      await this.$store.dispatch("terminal/cancelDeleteTerminal", this.terminalInfo);
      await this.$store.dispatch("terminal/fetchTerminals");
      this.isDetailValue = false;
    } catch (e) {
      this.errorMessage = "戻すにエラーが発生しました";
    }
  }
  /** UTCエポック秒をフォーマットする(時刻あり) */
  unixSecToDateTime(sec: number): string { 
    return formatEpochSec(sec, true);
  }
}
