/* eslint-disable */
import { API, graphqlOperation } from "aws-amplify";
import {
    updateSystemPlannedMaintenanceSchedules,
    createSystemPlannedMaintenanceSchedules
} from "@/graphql/mutations";
import { listSystemPlannedMaintenanceSchedules } from "@/graphql/queries";
import { SystemPlannedMaintenanceSchedules } from "@/models/systemPlannedMaintenanceSchedules.model";
// @ts-ignore
import * as _ from 'lodash';
interface SystemPlannedMaintenanceSchedulesState {
    systemPlannedMaintenanceSchedules: SystemPlannedMaintenanceSchedules[];
}

const state: SystemPlannedMaintenanceSchedulesState = {
    systemPlannedMaintenanceSchedules: []
};
export const systemPlannedMaintenanceSchedules = {
    namespaced: true,
    state,
    mutations: {
        setSystemPlannedMaintenanceSchedules(state: any, payload: any) {
            state.systemPlannedMaintenanceSchedules = payload;
        }
    },
    actions: {
        async getSystemPlannedMaintenanceSchedules(
            { commit }: any,
            payload: any
        ) {
            try {
                if (payload) {
                    const filter = {
                        filter: {
                            UserId: { eq: payload.id },
                            UserName: { eq: payload.email }
                        },
                        limit: 1000
                    };

                    const schedules: any = await API.graphql(
                        graphqlOperation(
                            listSystemPlannedMaintenanceSchedules,
                            filter
                        )
                    );
                    // @ts-ignore
                    const result = _.orderBy(schedules.data.listSystemPlannedMaintenanceSchedules.items, (a) => new Date(a.CreatedAt), ['desc'])
                    commit(
                        "setSystemPlannedMaintenanceSchedules",
                        result
                    );
                } else {
                    const filter = {
                        limit: 1000
                    };
                    const schedules: any = await API.graphql(
                        graphqlOperation(
                            listSystemPlannedMaintenanceSchedules,
                            filter
                        )
                    );
                    // @ts-ignore
                    const result = _.orderBy(schedules.data.listSystemPlannedMaintenanceSchedules.items, (a) => new Date(a.CreatedAt), ['desc'])
                    commit(
                        "setSystemPlannedMaintenanceSchedules",
                        result
                    );
                }



            } catch (error) {
                console.log(error)
                throw new error(
                    "Error when updating System Planned Maintenance",
                    error
                );
            }
        },

        async createNewSystemPlannedMaintenanceSchedules(
            { commit }: any,
            payload: any
        ) {
            try {
                console.log(JSON.stringify(payload));
                const schedules = await API.graphql(
                    graphqlOperation(createSystemPlannedMaintenanceSchedules, {
                        input: payload
                    })
                );
            } catch (error) {
                console.log(error);
                throw new error(
                    "Error when creating System Planned Maintenance",
                    error
                );
            }
        },

        async updateSystemPlannedMaintenanceSchedules(
            { commit }: any,
            payload: any
        ) {
            try {
                console.log(JSON.stringify(payload));
                const schedules = await API.graphql(
                    graphqlOperation(updateSystemPlannedMaintenanceSchedules, {
                        input: payload
                    })
                );
                console.log(schedules);
            } catch (error) {
                console.log(error);
                throw new error(
                    "Error when updating System Planned Maintenance",
                    error
                );
            }
        }
    }, getters: {
        systemPlannedMaintenanceSchedules: (
            state: SystemPlannedMaintenanceSchedulesState
        ) => state.systemPlannedMaintenanceSchedules
    }
};
