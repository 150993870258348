import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Auth } from 'aws-amplify';
import Dashboard from '../views/dashboard/Dashboard.vue';
import Account from '../views/account/Account.vue';
import Company from '../views/company/Company.vue';
import Store from '../views/store/Store.vue';
import GroupUser from '../views/group/user/GroupUser.vue';
import GroupDevice from '../views/group/device/GroupDevice.vue';
import FunctionRestriction from '../views/management/FunctionRestriction.vue';
import LogsOperation from '../views/logs/LogsOperation.vue';
import Charge from '../views/device/Charge.vue';
import TicketVending from '../views/device/TicketVending.vue';
import Login from '../views/login/Login.vue';
import ForgotPassword from '../views/login/ForgotPassword.vue';
import PasswordReset from '../views/login/PasswordReset.vue';
import ForcePasswordChange from '../views/login/ForcePasswordChange.vue';
import Maintenance from '../views/maintenance/Maintenance.vue';
import Adopt from '../views/management/Adopt.vue';
import { auth } from '@/store/auth/auth';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: { requiresAuth: true }
  },
  {
    path: '/company',
    name: 'Company',
    component: Company,
    meta: { requiresAuth: true }
  },
  {
    path: '/store',
    name: 'Store',
    component: Store,
    meta: { requiresAuth: true }
  },
  {
    path: '/group/user',
    name: 'Group-User',
    component: GroupUser,
    meta: { requiresAuth: true }
  },
  {
    path: '/group/device',
    name: 'Group-Device',
    component: GroupDevice,
    meta: { requiresAuth: true }
  },
  {
    path: '/management/functionRestriction',
    name: 'Function-Restriction',
    component: FunctionRestriction,
    meta: { requiresAuth: true }
  },
  {
    path: '/management/adopt',
    name: 'Adopt',
    component: Adopt,
    meta: { requiresAuth: true }
  },
  {
    path: '/logs/operation',
    name: 'Logs-Operation',
    component: LogsOperation,
    meta: { requiresAuth: true }
  },
  {
    path: '/terminal/charge',
    name: 'Charge',
    component: Charge,
    meta: { requiresAuth: true }
  },
  {
    path: '/terminal/ticketVending',
    name: 'TicketKiosk',
    component: TicketVending,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/login/forgotPassword',
    name: 'Login-ForgotPassword',
    component: ForgotPassword,
    meta: { requiresAuth: false }
  },
  {
    path: '/login/passwordReset',
    name: 'Login-PasswordReset',
    component: PasswordReset,
    meta: { requiresAuth: false }
  },
  {
    path: '/login/forcePasswordChange',
    name: 'Login-ForcePasswordChange',
    component: ForcePasswordChange,
    meta: { requiresAuth: false }
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
    meta: { requiresAuth: true }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = await Auth.currentUserInfo();
  // console.log(to, requiresAuth, isAuthenticated)
  if (requiresAuth && !isAuthenticated) {
    next('/login')
  }
  else if (requiresAuth && isAuthenticated) {
    let mainteSchedules: any = [];
    let isInRestriction = false;
    try {
      // ユーザ情報のチェック
      let dbUser = router.app.$store.getters['auth/dbUser'];
      console.log(dbUser);
      if (!dbUser) {
        await router.app.$store.dispatch('auth/authAction', null);
        dbUser = router.app.$store.getters['auth/dbUser'];
      }
      console.log(dbUser);
      const isSystemAdmin = dbUser['CognitoGroup'] == 'SystemAdmin' ? true : false;

      // 使用制限のチェック
      await router.app.$store.dispatch('systemPlannedMaintenanceSchedules/getSystemPlannedMaintenanceSchedules', null)
        .then(() => {
          mainteSchedules = router.app.$store.getters['systemPlannedMaintenanceSchedules/systemPlannedMaintenanceSchedules'];
          if (mainteSchedules[0]?.EndTimestampMs) {
            if (
              mainteSchedules[0]?.StartTimestampMs <
              Date.now() &&
              mainteSchedules[0]?.EndTimestampMs >
              Date.now()
            ) {
              isInRestriction = true;
            } else {
              isInRestriction = false;
            }
          } else {
            isInRestriction = false;
          }
        });
      if (isInRestriction && !isSystemAdmin && to.name !== 'Function-Restriction') {
        next('/management/functionRestriction');
        return;
      }
      if (to.name == 'Function-Restriction' && !isInRestriction && !isSystemAdmin) {
        next('/'); // 使用制限の解除後はトップに戻す
        return;
      }

      // 問題無ければ当該画面へ遷移
      next();
    } catch (e) {
      console.error(e);
      next('/login'); // 問題がある場合はログイン画面へ遷移させる
    }
  } else { // 認証不要な画面
    next();
  }
});

export default router;
